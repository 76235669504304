import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 140px;
  bottom: 10%;
`;

export default function Sunsetted() {
  const { t: homeT } = useTranslation('home');

  return (
    <Content>
      {homeT('sunsettedLeads')}
    </Content>
  );
}

