import styled from 'styled-components';

const Header = styled.header`
  background-color: ${ ({ theme }) => theme.colors.bgLight };
  padding: 15px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  .center {
    display: flex;
    align-items: center;
  }
  &.impersonate {
    top: 59px;
    @media (max-width: 768px) {
      top: 45px; 
    }
    @media (max-width: 384px) {
      top: 43px; 
    }
  }
  @media (max-width: 768px) {
    box-shadow: 0px 2px 12px 0px #00000021;
  }
`;

export default Header;
