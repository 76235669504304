import { useTranslation } from 'react-i18next';
import { NoData } from 'base/styled';

export default function NoStatystic() {
  const { t: homeT } = useTranslation('home');

  return (
    <NoData>
      <p className='top'>{homeT('ops')}</p>
      <p>{homeT('noDataSt')}</p>
    </NoData>
  );
}

