import styled from 'styled-components';

const TextAccentSmall = styled.p`
  font-size: .75rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0rem;
  color: ${props => props.theme.colors.accentRed};
  &.absolute {
    position: absolute;
    bottom: 60px;
    left: 10px;
    line-height:1.5;
  }
  &.relative {
    position: relative;
    line-heigth:1.5;
  }
`;

export default TextAccentSmall;
