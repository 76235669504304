import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { any, array, bool, func } from 'prop-types';
import { makeAsOptions } from 'base/utils';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import {
  BlockMargin,
  FilledButton,
  FlexWrapperStart,
  FlexWrapperStartMiddle,
  OutlinedButton,
  StyledSelect,
  StyledSelectSmall,
  TextAccentSmall,
  TextAccentThin
} from 'base/styled';
import FormTextField from 'base/components/FormTextField';
import { ReactComponent as Plus } from 'assets/images/plus.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { postUF, putUF } from 'store/slices/userFilters';
import CascadQueue from '../NewQueue/CascadQueue';
import { fetchUsers } from 'store/slices/users';
import { debounce } from 'lodash';

const FlexWrapperStartMiddlePadding = styled(FlexWrapperStartMiddle)`
  padding-top: 40px;
  button {
    margin-right: 15px
  }
`;

const Wrapper = styled(FlexWrapperStartMiddle)`
  height: 55px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    margin: 10px 0;
    .bsfINW {
      width: 100%;
      min-width: auto;
    }
  }
`;

const Button = styled.div`
  position: absolute;
  right: -25px;
  bottom: 0;
  cursor: pointer;
  width: 30px;
  height: 28px;
`;

const AddButton = styled.div`
 display: flex;
 padding-top: 30px;
 cursor: pointer;
 svg {
  margin: 7px 10px 0 0;
 }
`;

const CascadWrapper = styled.div`
  position: relative;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9
  })
};

const FlexWrap = styled(FlexWrapperStart)`
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Form = styled.form`
@media (max-width: 768px) {
  width: 100%;
  .equal {
    margin: 10px 0
  }
  .value {
    margin-bottom: 10px;
  }
}
#destroy {
  display: none;
}
.cascad:nth-child(2) .operator,
.cascad:nth-child(2) .delete {
  display: none;
}
.high {
  padding-top: 40px;
  max-width: 470px;
}
`;

const BlockMarginSelect = styled(BlockMargin)`
  padding-top: 30px;
`;

function generateEmptyCriteria() {
  return {
    key: '',
    operator: '',
    value: '',
    join_operator: 'AND',
    multiple_filter: {
      path_value: '',
      time_value: '',
      count_value: '',
      path_operator: '',
      time_operator: '',
      count_operator: '',
      value_to: '',
      value_from: ''
    },
    _id: uuidv4()
  };
}

export default function UserFilterForm({ handle,
  filterUserEdit,
  edit,
  gic,
  naics,
  setAddFilter,
  addFilter,
  // users,
  setResult
}) {
  const { t: homeT } = useTranslation('home');
  
  const [filterName, setFilterName] = useState(
    filterUserEdit ? filterUserEdit.name : ''
  );
  const [criteria, setCriteria] = useState(
    edit ? filterUserEdit?.lead_filters_attributes : [generateEmptyCriteria()]
  );
  const [isSelectValue, setIsSelectValue] = useState(false);
  const [newc, setNew] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [filterType, setFilterType] = useState([]);
  const [filterEqual, setFilterEqual] = useState('');
  const [newVisitor, setNewVisitor] = useState(false);
  const [error, setError] = useState('');
   
  const { selectedId, ids } = useSelector((state) => state.gaViewIdReducer);

  const dispatch = useDispatch();
  
  const websitesOptions = makeAsOptions(ids, 'id', 'name');

  const [selectedWebsite, setSelectedWebsite] = useState(
    filterUserEdit
      ? websitesOptions.find((el) => el.value === filterUserEdit?.ga_view?.id)
      : selectedId
  );
  const [defaultUsers, setDefaultUsers] = useState(filterUserEdit ? filterUserEdit?.users?.map(u => {
    return {
      label: u.full_name,
      value: u.id
    };
  }) : null);
  
  const { user, errors } = useSelector((state) => state.userReducer);
  const { users } = useSelector((state) => state.usersReducer);
  const [selectUser, setSelectUser] = useState(false);
  const [usersOptions, setUsersOptions] = useState(makeAsOptions(users, 'id', 'full_name'));
  const [filterUser, setFilterUser] = useState(defaultUsers ? defaultUsers.map(u => u.id) : '');

  useEffect(() => {
    
    if(filterUserEdit) {
      setDefaultUsers(filterUserEdit?.users?.map(u => {
        return {
          label: u.full_name,
          value: u.id
        };
      }));
    } else {
      setDefaultUsers(users?.map(u => {
        return {
          label: u.full_name,
          value: u.id
        };
      }));
    }
    setUsersOptions(makeAsOptions(users, 'id', 'full_name'));
  /* eslint-disable */
  }, [dispatch, users]);
  /* eslint-enable */

  useEffect(() => {
    if(filterUserEdit) {
      setFilterName(filterUserEdit.name);
      setSelectedWebsite(websitesOptions.find((el) => el.value === filterUserEdit?.ga_view?.id));
      setFilterUser(filterUserEdit.users?.map(u => {
        return {
          label: u.full_name,
          value: u.id
        };
      }));
      setCriteria(filterUserEdit?.lead_filters_attributes);
    } else {
      setCriteria([generateEmptyCriteria()]);
    }
    /* eslint-disable */
  },[filterUserEdit]);
    /* eslint-enable */

  const operatorOptions = [
    {value: 'OR', label: 'or'},
    {value: 'AND', label: 'and'},
  ];
  const [high, setHigh] = useState('');
  function handleWebsite(item) {
    setSelectedWebsite(item);
  }

  function filterNameHandler(e) {
    setFilterName(e.target.value);
  }

  function filterUserHandler(e) {
    setFilterUser(Array.isArray(e) ? e.map(x => x.value) : []);
  }

  function filterValueHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    const str = /\bany\b/.test((Array.isArray(e) ? e.map(x => x.value).join(' ') : '')) ? 'any' 
      : (Array.isArray(e) ? e.map(x => x.value).join(',') : '');
    if(isSelectValue) {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: str
      };
      setCriteria(newCriteria);
      setFilterValue(str);

    } else {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: e.target.value
      };
      setCriteria(newCriteria);
      setFilterValue(e.target.value);
    }
  }

  function filterValueNaicsHandler(arr, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      value: arr
    };
    setCriteria(newCriteria);
    setFilterValue(arr);
    console.log(arr);
  }
 
  function filterTypeHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      key: e.value
    };
    setCriteria(newCriteria);
    setFilterType(e.value);
  }

  function filterEqualHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: e.value
    };
    setCriteria(newCriteria);
    setFilterEqual(e.value);
  }

  function filterOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      join_operator: e.value
    };
    setCriteria(newCriteria);
  }

  function filterValueSelectHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      value: !selectUser ? (Array.isArray(e) ? e.map(x => x.value) : []) : e.map(x => x.value).join() 
    };
    setCriteria(newCriteria);
    setFilterValue(Array.isArray(e) ? e.map(x => x.value) : []);
  }

  function filterEqualNewVisitorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: e.value,
      value: e.value
    };
    setCriteria(newCriteria);
    setFilterEqual(e.value);
    console.log(newVisitor);
  }

  const [path, setPath] = useState();
  const [timeValue, setTimeValue] = useState();
  const [count, setCount] = useState();
  const [between, setBetween] = useState(false);
  const [notAssigned, setNotAssigned] = useState(false);
  const [criteriaErrors, setCriteriaErrors] = useState({});

  function MultiplePathHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        path_value: e.target.value
      },  
    };
    setCriteria(newCriteria);
    setPath(e.target.value);
  }

  function MultiplePathOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: '=',
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        path_operator: e.value
      },  
    };
    setCriteria(newCriteria);
    setFilterEqual('=');
    setFilterValue('string');
  }

  function MultipleTimeHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: 
        {...newCriteria[currentItemIndex].multiple_filter,
          time_value: e.target.value
        },
    };
    setCriteria(newCriteria);
    setTimeValue(e.target.value);
    setFilterEqual('=');
  }

  function MultipleTimeOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: '=',
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        time_operator: e.value
      },  
    };
    setCriteria(newCriteria);
    //setPath(e.target.value);
    setFilterEqual('=');
  }

  function MultipleCountHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: '=',
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        count_value: e.target.value
      },
    };
    setCriteria(newCriteria);
    setCount(e.target.value);
    setFilterEqual('=');
  }

  function MultipleCountOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: '=',
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        count_operator: e.value
      },  
    };
    setCriteria(newCriteria);
    setFilterEqual('=');
  }

  function ValueFromHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        value_from: e.target.value
      },  
    };
    setCriteria(newCriteria);
  }
  
  function ValueToHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        value_to: e.target.value
      },  
    };
    setCriteria(newCriteria);
    setBetween(true);
  }

  function validateForm() {
    let isValid = true;
    const pattern = /(http:\/\/|https:\/\/|http:|https:|www\.)/g;
    if (filterName.trim() === '') {
      setError('Please enter a name');
      isValid = false;
    } else if (!selectedId.value) {
      setError('Please choose a website');
      isValid = false;
    } else if (filterUser === '') {
      setError('Please choose users');
      isValid = false;
    } 

    const criteriaValidationErrors = criteria.map((item) => {
      const errors = {};
      
      if (item.key == "page_path" && pattern.test(item.value)) {
        setHigh('high');
        errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
        isValid = false;
      }

      if (item.key.trim() === '') {
        errors.key = 'Please choose a filter type';
        isValid = false;
      }

      if (item.operator.trim() === '') {
        errors.operator = 'Please choose an operator';
        isValid = false;
      }

      if ( (item.key != "time_on_specific_page" && item.key != "views_count_of_specific_page") &&
        item.value.trim() === '' &&
        !['is null', 'is not null'].includes(item.operator.trim().toLowerCase())
      ) {
        errors.value = 'Please enter a value';
        isValid = false;
      }
   
      if (item.key == "time_on_specific_page") {
        if (item.multiple_filter.time_value === '' ) {
          errors.value = 'Please enter a time value';
          isValid = false;
        }
          
        if (item.multiple_filter.time_operator === '') {
          errors.value = 'Please enter a time operator';
          isValid = false;
        }
        if (item.multiple_filter.path_value === '' ) {
          errors.value = 'Please enter a path value';
          isValid = false;
        }
        
        if (pattern.test(item.multiple_filter.path_value)) {
          setHigh('high');
          errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
          isValid = false;
        }
          
        if (item.multiple_filter.path_operator === '') {
          errors.value = 'Please enter an operator';
          isValid = false;
        }
      }

      if (item.key == "views_count_of_specific_page") {
        if (item.multiple_filter.count_value === '' ) {
          errors.value = 'Please enter a count value';
          isValid = false;
        }
          
        if (item.multiple_filter.count_operator === '') {
          errors.value = 'Please enter a count operator';
          isValid = false;
        }
        if (item.multiple_filter.path_value === '' ) {
          errors.value = 'Please enter a path value';
          isValid = false;
        }
          
        if (item.multiple_filter.path_operator === '') {
          errors.value = 'Please enter an operator';
          isValid = false;
        }
          
        if (pattern.test(item.multiple_filter.path_value)) {
          setHigh('high');
          errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
          isValid = false;
        }
      } 
    
      return errors;
    });

    setCriteriaErrors(criteriaValidationErrors);

    return isValid;
  }

  function filterSubmit(e) {
    validateForm();
    e.preventDefault();
  
    switch (true) {
    case filterName.length == 0:
      setError('Please enter name');
      break;
    case !selectedId.value:
      setError('Please choose website');
      break;
    case filterUser === '':
      setError('Please choose users');
      break;
    case !filterType:
      setError('Please choose filter type');
      break;
    case !filterEqual:
      setError('');
      break;
    case !between && filterValue.length <= 0 :
      setError('Please enter value');
      break;
    case between && criteria[0].multiple_filter.value_from == '' && criteria[0].multiple_filter.value_to == '':
      setError('Please choose values');
      break;
    default: 
      setError('');
    }
   
    if (!edit && validateForm()) { 

      const filterUserData = {
        name: filterName,
        google_analytics_view_id_id: selectedWebsite.value,
        user_ids: filterUser,
        lead_filters_attributes: criteria
      };
      dispatch(postUF({...filterUserData}));
      setAddFilter(!addFilter);
      handle();
      setResult('New filter was added!');
    }
    if (edit && validateForm()) {
      const filterUserData = {
        name: filterName,
        google_analytics_view_id_id: selectedWebsite.value,
        user_ids: filterUser.map(e => e.value || e),
        lead_filters_attributes: criteria
      };
      const Id = filterUserEdit.id;
      dispatch(putUF({id: Id,  body:filterUserData}));
      setAddFilter(!addFilter);
      setResult('New filter was added!');
      handle();
    }

  }
  
  const handleDelete = (item) => () => {
    const updatedCriteria = criteria.map(e =>
      e.id === item.id ? { ...e, _destroy: 'true' } : e
    );
    setCriteria(edit ? updatedCriteria : criteria.filter(e => e._id !== item._id));
  };

  function addFilterCriteria(e) {
    e.preventDefault();
    setCriteria(criteria.concat(generateEmptyCriteria()));
    setNew(true);
  }
  /* eslint-disable */
  const delayedQuery = useCallback(
    debounce((inputValue) => {
      if (inputValue.length > 2) {
        dispatch(fetchUsers({ query: inputValue, per_page: 50 }));
      }
    }, 300), []
  );
    /* eslint-enable */

  function handleInputChange(inputValue) {
    delayedQuery(inputValue);
  }

  useEffect(() => {
    dispatch(fetchUsers({}));
  }, [dispatch]); 

  return (
    <Form onSubmit={filterSubmit}>
      <FlexWrap>
        <BlockMargin className='form-field'>
          <FormTextField
            type="text"
            onChange={filterNameHandler}
            value={filterName}
            label={homeT('filterName')}
          />
        </BlockMargin>
        <BlockMarginSelect className='form-field'>
          {
            <StyledSelect
              placeholder={homeT('website')}
              value={selectedWebsite}
              options={websitesOptions}
              onChange={handleWebsite}
              styles={customStyles}
              width="300px"
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
          }
        </BlockMarginSelect>
        <BlockMarginSelect className='form-field'>
          {
            <StyledSelect
              isMulti
              placeholder={homeT('users')}
              defaultValue={ defaultUsers ? defaultUsers?.map(ele => ele) : ''} 
              options={user?.role === 'manager' ? [{value: user.id, label: user.full_name}] : usersOptions}
              onChange={filterUserHandler}
              onInputChange={handleInputChange}
              styles={customStyles}
              menuPlacement="bottom"
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
          }
        </BlockMarginSelect>
      </FlexWrap>
      {criteria?.filter(e => !e._destroy)?.map((item, index) => (
        <CascadWrapper key={index} className="cascad">
          <Wrapper className="operator">
            {
              <StyledSelectSmall
                placeholder={homeT('or')}
                options={operatorOptions}
                defaultValue={ operatorOptions.find((el) => el.value === item.join_operator) }
                onChange={(e) => filterOperatorHandler(e, item)}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
              />
            }
            
          </Wrapper>
          <CascadQueue
            handlerType={filterTypeHandler}
            handlerEqual={filterEqualHandler}
            handlerValue={filterValueHandler}
            handlerEqualNewVisitor={filterEqualNewVisitorHandler}
            filterValue={filterValue}
            styles={customStyles}
            index={index}
            item={item}
            edit={edit}
            usersOptions={usersOptions}
            defaultUsers={defaultUsers}
            users={users}
            setIsSelectValue={setIsSelectValue}
            naicsHandler={filterValueNaicsHandler}
            gic={gic}
            naics={naics}
            newc={newc}
            PathHandler={MultiplePathHandler}
            pathOperator={MultiplePathOperatorHandler}
            timeOperator={MultipleTimeOperatorHandler}
            countOperator={MultipleCountOperatorHandler}
            path={path}
            TimeHandler={MultipleTimeHandler}
            timeValue={timeValue}
            CountHandler={MultipleCountHandler}
            count={count}
            ValueFromHandler={ValueFromHandler}
            ValueToHandler={ValueToHandler}
            setError={setError}
            setNotAssigned={setNotAssigned}
            notAssigned={notAssigned}
            setSelectUser={setSelectUser}
            filterValueSelectHandler={filterValueSelectHandler}
            setNewVisitor={setNewVisitor}
          />
          <Button onClick={handleDelete(item)} 
            className={(criteria.length >1 && edit) ? 'active' : 'delete'}>
            <Delete />
          </Button>
        </CascadWrapper>
      ))}
      <TextAccentSmall className={errors ? 'relative' : 'relative'}>{error}</TextAccentSmall>
      {/* Criteria Validation Errors */}
      {criteriaErrors?.length > 0 && criteriaErrors?.map((errors, index) => (
        <div key={index} className={high}>
          {errors.key && <TextAccentSmall className={errors ? 'relative' : 'relative'}>{errors.key}</TextAccentSmall>}
          {errors.operator && (
            <TextAccentSmall className={errors ? 'relative' : 'relative'}>{errors.operator}</TextAccentSmall>
          )}
          {errors.value && <TextAccentSmall className={errors ? 'relative' : 'relative'}>
            {errors.value}</TextAccentSmall>}
        </div>
      ))}
      <FlexWrapperStart>
     
        <AddButton onClick={addFilterCriteria}>
          <Plus />
          <TextAccentThin>{homeT('addFilterCriteria')}</TextAccentThin>
        </AddButton>
      </FlexWrapperStart>
      <FlexWrapperStartMiddlePadding>
        <FilledButton type="submit">{homeT('saveFilter')}</FilledButton>
        <OutlinedButton type="reset" onClick={handle}>
          {homeT('cancel')}
        </OutlinedButton>
      </FlexWrapperStartMiddlePadding>
    </Form>
  );
}

UserFilterForm.propTypes = {
  filterUserEdit: any,
  edit: bool,
  handle: func,
  gic: array,
  naics: array,
  setAddFilter: func,
  addFilter: bool,
  users: any,
  setResult: func
};
