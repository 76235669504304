import styled from 'styled-components';
import { array, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import img from 'assets/images/keyword.svg';
import { TextLightExtraSmall, TextBlackExtraSmall, FilledLink, Block } from 'base/styled';
import VisitDetailsAccordion from './VisitDetailsAccordion';
import { useContext } from 'react';
import Responsive from 'context/responsive';

const AccordionWrap = styled.div`
  padding: 10px 0;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 2fr 1fr;
  text-align: center;
  .text-left {
    padding-left: 15px;
  }
`;

const Keyword = styled(TextLightExtraSmall)`
  background: #FFD69B;
  border-radius: 5px;
  padding: 2px;
  width: fit-content;
  margin-left: 5px;
`;

const Wrap = styled.div`
  background: url(${img}) no-repeat 2% center;
  display: flex;
  padding: 0 25px;
`;

const Wrapper = styled(Block)`
  margin: 30px;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  text-align: center;
  a {
    font-size: 14px;
    width: 140px;
    margin: 16px auto 0;
  }
`;

export default function VisitDetails({visitsInfo, active}) {
  const { t: homeT } = useTranslation('home');
 
  const ctx = useContext(Responsive);

  function acc(item, idx) {
    return (
      <VisitDetailsAccordion
        key={idx}
        source={item.source}
        allactive={active}
        count={item.count}
        date={item.date}
        total_time={item.total_time}
      >
        {item.page_views.map((view, ind) => (
          <Content key={ind}>
            <TextLightExtraSmall className='text-left'> {view.accessed_at?.split(/, /)[1]} </TextLightExtraSmall>
            <TextLightExtraSmall>
              {' '}
              {view.path}({view.count})
            </TextLightExtraSmall>
            <TextBlackExtraSmall> {Math.floor(parseFloat(view?.time_spent)/60) > 0 &&
             (Math.floor(parseFloat(view?.time_spent)/60) + 'min ')}
            {parseFloat(view?.time_spent)% 60 + 's'}</TextBlackExtraSmall>
          </Content>
        ))}
        {item.keyword && (
          <Wrap>
            <TextBlackExtraSmall>keyword used:</TextBlackExtraSmall>
            <Keyword>{item.keyword}</Keyword>
          </Wrap>
        )}
        {item.content}
      </VisitDetailsAccordion>
    );
  }

  return (
    <>
      {ctx.isLiteFree ? (
        <Wrapper>
          {homeT('upgradeVisits')}
          <FilledLink href='/company'>{homeT('upgradeNow')}</FilledLink>
        </Wrapper>
      ) : (
        <AccordionWrap>
          {visitsInfo && visitsInfo.map(acc)}
        </AccordionWrap>
      )}
    </>
  );
}

VisitDetails.propTypes = {
  visitsInfo: array,
  active: bool
};
