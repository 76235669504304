import styled from 'styled-components';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWebsites } from 'store/slices/websites';
import { Modal, Overflow } from "base/styled";
import Slider from './Slider';
import { useEffect } from 'react';

const Block = styled.div`
  margin: 0 auto;
  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export default function Onboarding({handleOnboardOff}) {
  const dispatch = useDispatch();
  const { websites } = useSelector(
    (state) => state.websitesReducer
  );

  useEffect(() => {
    dispatch(fetchWebsites({ websites }));
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  return (
    <Modal>
      <Overflow></Overflow>
      <Block>
        <Slider handleOnboardOff={handleOnboardOff} websites={websites}/>
      </Block>
    </Modal>
   
  );
}

Onboarding.propTypes = {
  handleOnboardOff: func,
};
