import { useTranslation } from 'react-i18next';
import { Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {
  TextBlack,
  TextBlackSmallThin
} from 'base/styled';
import { ReactComponent as Image } from 'assets/images/bg.svg';

const Content = styled.div`
  padding: 150px 0;
  text-align: center;
  margin: 0 auto;
  width: 70%;
 `;

const Detected = styled.div`
  width: max-content; 
  position: relative;
  margin: 0 auto;
  margin-top: 110px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 23px;
    height: 23px;
    background: #6EDC5D;
    border-radius: 50%;
    top: -5px;
    left: -35px;
  }
`;

export default function NoLeads() {
  const { t: homeT } = useTranslation('home');
  
  return (
    <Content>
      <Row>
        <Col xs="12">
          <Image />
          <TextBlackSmallThin>{homeT('noLeadsYet')}</TextBlackSmallThin>
          <Detected>
            <TextBlack>{homeT('codeDetected')}</TextBlack>
          </Detected>       
        </Col>
      </Row>
    </Content>
  );
}