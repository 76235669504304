import styled from "styled-components";

const TitleThree = styled.h3`
  font-size: 1.06rem;
  text-align: center;
  font-weight: 500;
  color: #000;
`;

export default TitleThree;
