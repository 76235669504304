import { useEffect, useState } from "react";
import { Block, Flex, TextBlack, TextBlackSmall, 
  TextBlackThin, TitleBlack, TextLightSmall, TitleTwo, FlexWrapper, 
  TextLightExtraSmall, TextBlackExtraSmall} from "base/styled";
import { fetchBenchmark } from "store/slices/benchmark";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as BounceCard } from "assets/images/bounce_card.svg";
import { ReactComponent as TimeIcon } from "assets/images/timeIcon.svg";
import { ReactComponent as PagesIcon } from "assets/images/pages.svg";
import { ReactComponent as Same } from "assets/images/union.svg";
import { ReactComponent as SameGreen } from "assets/images/union-gr.svg";
import { ReactComponent as SameRed } from "assets/images/union-red.svg";
import { ReactComponent as GrowSmile } from "assets/images/grow_smile.svg";
import { ReactComponent as SameSmile } from "assets/images/same_smile.svg";
import { ReactComponent as LowSmile } from "assets/images/low_smile.svg";
import { ReactComponent as Arrows } from "assets/images/arrows.svg";
import img from 'assets/images/alert_black.svg';
import imgGrow from 'assets/images/img_grow.svg';
import imgLow from 'assets/images/img_low.svg';
import TooltipDark from "base/components/TooltipDark";
import { Calendar } from "base/components/Leads";

const Wrapper = styled.div`
  
  width: 100%
  @media (min-width: 769px) {
    width: 750px;
    padding: 30px 0 30px 70px;
  
  }
  @media (min-width: 1119px) {
    width: 950px
  }
  h1 {
    text-align: left;
  }
  .subtitle {
    max-width:460px;
    line-height: 1.2rem
  }
   @media (max-width: 768px) {
    h1 {
      margin: 50px 0 20px;
    }
   }
  .pages {

   }
`;

const Total = styled.div`
  display: flex;
  width: 100%;
  margin: 50px 0;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CardTitle = styled(Flex)`
  p {
    margin: 0 10px 0 8px
  }
  .mt {
    margin-top: 2px;
  }
  .mtt {
    margin-top: -2px;
  }
`;

const Tooltip = styled(TooltipDark)`
  top: auto;
  left: auto;
  span {
    top: auto;
    bottom: -4px;
    left: 8px;
  }
`;

const Card = styled(Block)`
box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.13);
  &.bounce {
    background: linear-gradient(109.4deg, #F3FFED 7.03%, #FFFFFF 75.21%);
  }
  &.pages {
    background: linear-gradient(109.4deg, #EDFFFF 7.03%, #FFFFFF 75.21%);
  }
  &.time {
    background: linear-gradient(109.4deg, #FCF4FF 7.03%, #FFFFFF 75.21%);
  }
  margin: 10px 15px 0 0;
  flex-direction: column;
  padding: 10px;

  @media (max-width: 768px) {
    width: 285px; 
    margin: 10px auto
  }
`; 

const CardContent = styled.div`
  display: flex;
  background: #98FF871A;
  padding: 4px;
  border-radius: 15px;
  margin-top: 20px;
  min-height: 64px;
  &.pages {
    background: #71DDFF1A
  }
  &.time {
    background: #D7CDFF1A;
  }
`;

const Data = styled.div`
  text-align: center;
  padding: 0 10px;
 
  p {
    white-space: nowrap
  }
`;

const TextNumbers = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #FF3B2F;
  margin: 5px 10px 0;
  position: relative;
  height: 21px;
  span {
    display: none;
    &.growth {
      bottom: -4px;
      font-size: 6px;
      position: absolute;
      left: 0;
      display: block;
    }
  }
  &.medium {
    font-size: 14px;
    color: #F08421;
    line-height: 23px;
  }
  &.success {
    color: #3BD144;
    font-size: 14px;
    line-height: 23px;
   
  }
`;

const Text = styled(TextBlackSmall)`
  margin: 10px 0 3px;
`;

const TextLight = styled(TextLightSmall)`
margin: 10px 0 3px;
`;

const Rate = styled.div`
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
background: #FFE0E0;
border-radius: 4px;
cursor: pointer;
position: relative;
transition: .3s;
min-width: 55px;
span{
  position: absolute;
  top: -43px;
  left: 15px;
  z-index: 3;
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
  background: rgba(50, 49, 49, 0.7);
  padding: 7px;
  border-radius: 11.5px;
  width: max-content;
  max-width: 200px;
  display: none;
 }
 &:hover {
   span {
     display: block;
   }
 }
p {
  margin-bottom: 0
}
svg {
  min-height: 23px;
}
&.success{
  background: #D3FFBE;
  padding-top: 4px;
}
&.medium {
  background: #FFE9CE;
}
`;

const TrafficRate = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  background: #FFE0E0 url(${imgLow}) no-repeat 60px 13px;
  border-radius: 8px;
  padding: 2px 10px;
  .numbers {
    font-size: 20px;
    margin: 10px 0 0 0;
    height: auto;
  }
  .name {
    margin: 14px 0 5px
  }
  &.low, &.grow {
    transform: scale(.86);
  }
  &.success{
    background: #D3FFBE url(${imgGrow}) no-repeat 60px 13px;
    &.low, &.grow{
      background: #E4FFD8 url(${imgGrow}) no-repeat 60px 13px;
      transform: scale(.86);
    }
    .numbers {
      color: #20B037;
       span.growth {
        display: block!important;
      }
      span.plus {
        display: inline!important;
        font-size: inherit;
        position: static;
      }
    }

  }
`;

const Traffic = styled.div`
padding: 20px 0;
  h2 {
    font-size: 26px;
    margin-top: 0px;
    @media (max-width: 768px) {
      text-align: left;
      font-size: 20px;

    }
  }
.title {
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%
  }
}
`;

const Boxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Box = styled(Block)`
  background: linear-gradient(109.4deg, #FFFAEE 7.03%, #FFFFFF 75.21%);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.13);
  width: 430px;
  margin: 20px 20px 0 0;
  padding-left: 30px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px
  }
  &:nth-child(even) {
    margin-right: 0;
  }
`;

const BoxContent = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
`;

const BoxContentRates = styled(BoxContent)`
  padding: 0;
  width: 75%;
  .low:nth-child(3) {
    transform: inherit;
    background: #FFE0E0 url(${imgLow}) no-repeat 60px 13px;
    &.success {
      background: #D3FFBE url(${imgGrow}) no-repeat 60px 13px;
    }
  }
  .grow:nth-child(1) {
    transform: inherit;
    background: #FFE0E0 url(${imgLow}) no-repeat 60px 13px;
    &.success {
      background: #D3FFBE url(${imgGrow}) no-repeat 60px 13px;
    }
  }
`;

const TextSmall = styled(TextLightExtraSmall)`
  color: #C79E6B;
  padding-left: 20px
  &.low {
    color: #C76B6B;
  }
  &.grow {
    color: #319D2F;
  }
  &.same {
    color: #C79E6B;
  }
`;

const Nodata = styled.div`
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10pxж
  background: url(${img}) no-repeat 50% 20%;
  padding-top: 30px;
`;

const TextRemind = styled.div`
  margin: 0 auto;
  width: auto;
  @media (min-width: 1200px) {
    width: 530px;
  }
  p {
    color: #7C7C7C;
    text-align: center;
  }
`;

const NoData = styled(Nodata)`
  height: 85px;
  background: none;
  p {
    font-size: .425rem; 
    min-width: 256px;
  }
`;

const Empty = styled.div`
  background: #E6E6E6 url(${img}) no-repeat 50% 20%;
  border-radius: 15px;
  margin: 10% auto;
  max-width: 425px;
  padding: 70px 25px 25px;
  text-align: center;
  p {
    line-height:23px
  }
`;

const Notraffic = styled.div`
background: #E6E6E6 url(${img}) no-repeat 50%;
width: 100px;
height: 70px;
border-radius: 8px
`;

export default function Index() {
  const { t: benchmarkT } = useTranslation('benchmark');
  const dispatch = useDispatch();
 
  const [startDay, setStartDay] = useState(new Date().setDate(new Date().getDate() - 8));
  const [endDay, setEndDay] = useState(new Date().setDate(new Date().getDate() - 2));
  const [changeDate, setChangeDate] = useState(false);
  const gaViewId = useSelector(
    (state) => state.gaViewIdReducer.selectedId?.value
  );
  const {
    statistic
  } = useSelector((state) => state.benchmarkReducer);

  useEffect(() => {
    if (gaViewId) {
      dispatch(fetchBenchmark({ 
        ga_view_id: gaViewId,
        min_date: new Date(startDay).toISOString().split('T')[0],
        max_date: new Date(endDay).toISOString().split('T')[0],
      }));
    }

  }, [startDay, endDay, gaViewId, dispatch]);
 
  const [dateRange, setDateRange] = useState();

  useEffect(() => {
    if(statistic?.start && (statistic?.bounce || statistic?.avg_time || statistic?.avg_pages)) {
      const date = 
      new Date(statistic?.previous_start * 1000).toLocaleDateString('en-US', 
        { month: 'short', day: 'numeric', year: 'numeric' }) +
      ' - ' +
      new Date(statistic?.start * 1000).toLocaleDateString('en-US', 
        { month: 'short', day: 'numeric', year: 'numeric' });
      setDateRange(date);
    } else if (statistic?.start && !statistic?.bounce) {
      setDateRange('');
    }
  }, [statistic]);

  function getDeltaStatus(thisDeltaPercent, allDeltaPercent) {
    if (thisDeltaPercent > allDeltaPercent) return 'grow';
    if (thisDeltaPercent < allDeltaPercent) return 'low';
    if (thisDeltaPercent == allDeltaPercent) return 'same';
    if (!thisDeltaPercent) return 'thisNo';
    if (!allDeltaPercent) return 'allNo';
    return null;
  }
  
  const allTraffic = getDeltaStatus(statistic?.traffic?.all_traffic?.this_delta_percent, 
    statistic?.traffic?.all_traffic?.all_delta_percent);
  const organic = getDeltaStatus(statistic?.traffic?.organic?.this_delta_percent, 
    statistic?.traffic?.organic?.all_delta_percent);
  const direct = getDeltaStatus(statistic?.traffic?.direct?.this_delta_percent, 
    statistic?.traffic?.direct?.all_delta_percent);
  const referral = getDeltaStatus(statistic?.traffic?.referral?.this_delta_percent, 
    statistic?.traffic?.referral?.all_delta_percent);
  const email = getDeltaStatus(statistic?.traffic?.email?.this_delta_percent, 
    statistic?.traffic?.email?.all_delta_percent);
  const cpc = getDeltaStatus(statistic?.traffic?.cpc?.this_delta_percent, 
    statistic?.traffic?.cpc?.all_delta_percent);
  const other = getDeltaStatus(statistic?.traffic?.other?.this_delta_percent, 
    statistic?.traffic?.other?.all_delta_percent);

  const allTrafficDiff = Math.abs(statistic?.traffic?.all_traffic?.all_delta_percent -
  statistic?.traffic?.all_traffic?.this_delta_percent);

  const organicDiff = Math.abs(statistic?.traffic?.organic?.all_delta_percent -
  statistic?.traffic?.organic?.this_delta_percent);

  const directDiff = Math.abs(statistic?.traffic?.direct?.all_delta_percent -
  statistic?.traffic?.direct?.this_delta_percent);

  const referralDiff = Math.abs(statistic?.traffic?.referral?.all_delta_percent -
  statistic?.traffic?.referral?.this_delta_percent);

  const emailDiff = Math.abs(statistic?.traffic?.email?.this_delta_percent -
  statistic?.traffic?.email?.all_delta_percent);

  const cpcDiff = Math.abs(statistic?.traffic?.cpc?.all_delta_percent -
  statistic?.traffic?.cpc?.this_delta_percent);

  const otherDiff = Math.abs(statistic?.traffic?.other?.all_delta_percent -
  statistic?.traffic?.other?.this_delta_percent);

  function NoDataContent(){
    console.log(changeDate);
    return(
      <Nodata>
        <TextBlackExtraSmall>{benchmarkT("noData")}</TextBlackExtraSmall>
      </Nodata>
    );
  }

  function generateGrowthText(category, diff, isGrow, dateRange, t) {

    if (isGrow) {
      return `${t(`your${category}Growth`)} 
      ${Math.abs(diff) < 1 ? diff.toFixed(2) : diff.toFixed() }% higher than the average website over ${dateRange}`;
    } else if (!isGrow && diff !== 0) {
      return `${t(`your${category}Growth`)} 
      ${Math.abs(diff) < 1 ? diff.toFixed(2) : diff.toFixed() }% lower than the average website over ${dateRange}`;
    } else {
      return `${t(`your${category}Growth`)}` + t('theSame') + dateRange;
    }
    
  }

  const organicIsGrow = organic === 'grow';
  const allTrafficIsGrow = allTraffic === 'grow';
  const directIsGrow = direct === 'grow';
  const referralIsGrow = referral === 'grow';
  const emailIsGrow = email === 'grow';
  const cpcIsGrow = cpc === 'grow';
  const otherIsGrow = other === 'grow';
  
  const allTrafficText = generateGrowthText(
    'Alltraffic',
    allTrafficDiff,
    allTrafficIsGrow,
    dateRange,
    benchmarkT
  );

  const organicText = generateGrowthText(
    'Organic',
    organicDiff,
    organicIsGrow,
    dateRange,
    benchmarkT
  );

  const directText = generateGrowthText(
    'Direct',
    directDiff,
    directIsGrow,
    dateRange,
    benchmarkT
  );

  const referralText = generateGrowthText(
    'Referral',
    referralDiff,
    referralIsGrow,
    dateRange,
    benchmarkT
  );

  const cpcText = generateGrowthText(
    'Cpc',
    cpcDiff,
    cpcIsGrow,
    dateRange,
    benchmarkT
  );

  const emailText = generateGrowthText(
    'Email',
    emailDiff,
    emailIsGrow,
    dateRange,
    benchmarkT
  );

  const otherText = generateGrowthText(
    'Other',
    otherDiff,
    otherIsGrow,
    dateRange,
    benchmarkT
  );

  function NoPageContent(){
    return(
      <Empty>
        <TextBlackThin>{benchmarkT("noDataPage")}</TextBlackThin>
        <Calendar 
          setStartDay={setStartDay}
          setEndDay={setEndDay}
          minDay={startDay }
          maxDay={endDay}
          setChangeDate={setChangeDate}
          bench={true}
        />
      </Empty>
    );
  }

  function NoTraffic() {
    return (
      <Notraffic>
      </Notraffic>
    );
  } 
  
  function renderCard(name, category, your, all) {
    return (
      <Card className={name}>
        <CardTitle>
          {name == 'bounce' && <BounceCard /> ||
           name == 'pages' && <PagesIcon /> ||
           name == 'time' && <TimeIcon className="mtt"/> }
          <TextBlack>{benchmarkT(`${category}`)}</TextBlack>
          <Tooltip text={benchmarkT(`${(`${category}Tooltip`)}`)} />
        </CardTitle>
        {typeof your == 'number' ? <CardContent className={name}>
          <Data>
            <TextNumbers className={name == 'bounce' ? ((your > all) ? '' : 'success') 
              : ((your > all) ? 'success' : '')}>
              {
                name == 'bounce' && (your * 100).toFixed() + '%' ||
                name == 'pages' && your ||
                name == 'time' && 
                `${Math.floor(your)}m ${your.toString().split('.')[1] || '00'}s`}
            </TextNumbers>
            <Text>{benchmarkT("yourWebsite")}</Text>
          </Data>      
          {your == all && (
            <Rate className="medium">
              <span>{benchmarkT(`${(`${category}SameTooltip`)}`)}</span>
              <Same />
              <TextNumbers className="medium">
                {Math.floor(your/all*100-100)}%
              </TextNumbers>
            </Rate>)}
          {your > all && (
            <Rate className={name == 'bounce' ? '' :  'success'}>
              <span>{benchmarkT(`${(`${category}CardTooltip`)}`) + benchmarkT("highTooltip")}</span>
              {name == 'bounce' ? <SameRed /> :  <SameGreen />}
              <TextNumbers className={name == 'bounce' ? '' :  'success'}> {Math.floor(your/all*100-100)}%</TextNumbers>
            </Rate>)}
          {your < all && (
            <Rate className={name == 'bounce' ? 'success' :  ''}>
              <span>{benchmarkT(`${(`${category}CardTooltip`)}`) + benchmarkT("lowTooltip")}</span>
              {name != 'bounce' ? <SameRed /> :  <SameGreen />}
              <TextNumbers className={name == 'bounce' ? 'success' :  ''}> {Math.floor(your/all*100-100)}%</TextNumbers>
            </Rate>)}
          <Data>
            <TextNumbers className={ name == 'bounce' ? ((your > all) ? '' : 'success') 
              : ((your > all) ? 'success' : '')}>
              {
                name == 'bounce' && (all * 100).toFixed() + '%' ||
                name == 'pages' && all ||
                name == 'time' && 
                `${Math.floor(all)}m ${all.toString().split('.')[1] || '00'}s`}
            </TextNumbers>
            <TextLight>{benchmarkT("avgWebsite")}</TextLight>
          </Data>
        </CardContent> :
          <NoData>
            <TextBlackExtraSmall>{benchmarkT("noDataCard")}</TextBlackExtraSmall>
          </NoData>
        }
      </Card>
    );
  }

  function renderBox(name, trafficType, trafic, thisDelta, allDelta, trafficText) {
    return (
      <Box>
        <CardTitle>
          <TextBlack>{benchmarkT(`${name}`)}</TextBlack>
          <Tooltip text={benchmarkT(`${(`${name}Tooltip`)}`)}/>
        </CardTitle>
        {trafficType ? <> <BoxContent>
          {trafic == 'grow' ? <GrowSmile /> : trafic === 'low' ? <LowSmile /> : <SameSmile />}
          <BoxContentRates>
            {thisDelta ? <TrafficRate className={thisDelta > 0 ? 
              trafic + ' success' : trafic}>
              <TextNumbers className="numbers">
                <span className="plus">+</span>
                {thisDelta?.toFixed()}%
                <span className="growth">{benchmarkT("growth")}</span>
              </TextNumbers>
              <TextBlack className="name">{benchmarkT("yourWebsite")}</TextBlack>
            </TrafficRate> : NoTraffic()}
            <Arrows />
            {allDelta ? <TrafficRate className={allDelta > 0 ? 
              trafic + ' success' : trafic}>
              <TextNumbers className="numbers">
                <span className="plus">+</span>
                {allDelta?.toFixed()}%
                <span className="growth">{benchmarkT("growth")}</span>
              </TextNumbers>
              <TextBlack className="name">{benchmarkT("avgWebsite")}</TextBlack>
            </TrafficRate> : NoTraffic()}
          </BoxContentRates>
        
        </BoxContent>
        <TextSmall className={trafic}>
          {trafficText}
        </TextSmall>
        </> : NoDataContent()}
      </Box>
    );
  }

  return (
    <Wrapper>
      <TitleBlack>{benchmarkT('title')}</TitleBlack>
      <TextBlackThin className="subtitle">{benchmarkT('description')}</TextBlackThin>
      { (statistic?.bounce || statistic?.avg_time || statistic?.avg_pages) ? <><Total>
        {renderCard('bounce', 'bounceRate', statistic?.bounce?.this, statistic?.bounce?.all )}
        {renderCard('pages', 'pages', statistic?.avg_pages?.this, statistic?.avg_pages?.all )}
        {renderCard('time', 'time', statistic?.avg_time?.this, statistic?.avg_time?.all )}
        
      </Total>
      <Traffic>
        <FlexWrapper className="title">
          <TitleTwo>{benchmarkT('titleTraffic')}</TitleTwo>
          <Calendar 
            setStartDay={setStartDay}
            setEndDay={setEndDay}
            minDay={typeof startDay =='number' && startDay}
            maxDay={endDay}
            setChangeDate={setChangeDate}
            bench={true}
          />
        </FlexWrapper>
        <Boxes>
          {renderBox('allTraffic', statistic?.traffic?.all_traffic, allTraffic, 
            statistic?.traffic?.all_traffic?.this_delta_percent, 
            statistic?.traffic?.all_traffic?.all_delta_percent, allTrafficText )}
          {renderBox('organic', statistic?.traffic?.organic, organic, statistic?.traffic?.organic?.this_delta_percent,
            statistic?.traffic?.organic?.all_delta_percent, organicText )}
          {renderBox('direct', statistic?.traffic?.direct, direct, statistic?.traffic?.direct?.this_delta_percent, 
            statistic?.traffic?.direct?.all_delta_percent, directText )}
          {renderBox('referral', statistic?.traffic?.referral, referral, 
            statistic?.traffic?.referral?.this_delta_percent, 
            statistic?.traffic?.referral?.all_delta_percent, referralText )}
          {renderBox('cpc', statistic?.traffic?.cpc, cpc, statistic?.traffic?.cpc?.this_delta_percent, 
            statistic?.traffic?.cpc?.all_delta_percent, cpcText )}
          {renderBox('email', statistic?.traffic?.email, email, statistic?.traffic?.email?.this_delta_percent, 
            statistic?.traffic?.email?.all_delta_percent, emailText )}
          {renderBox('other', statistic?.traffic?.other, other, statistic?.traffic?.other?.this_delta_percent, 
            statistic?.traffic?.other?.all_delta_percent, otherText )}
        </Boxes>
      </Traffic>
      <TextRemind>
        <TextBlackExtraSmall>{benchmarkT("reminder")}</TextBlackExtraSmall>
      </TextRemind></> : <NoPageContent /> }
    </Wrapper>
  );
}
