import styled from 'styled-components';

const TextBlackExtraSmall = styled.p`
  font-size: .625rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0rem;
  color: ${props => props.theme.colors.black};
  &.text-left {
    text-align: left;
  }
`;

export default TextBlackExtraSmall;
