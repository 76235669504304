import React, { useState, useContext } from 'react';
import { deleteAssignRuleById } from 'store/slices/assignRules';
import { useDispatch } from 'react-redux';
import {
  Block,
  Manage,
  ManageBox,
  TableTitle,
  TextBlack,
  TextBlackdark,
  Modal,
  Confirm,
  Overflow,
  OutlinedButton,
  FilledButton,
  FlexEnd,
  Overlay,
  Table,
  Result
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { array } from 'prop-types';
import { ReactComponent as Nav } from 'assets/images/navigation.svg';
import { ReactComponent as Edit } from 'assets/images/edit.svg';
import { ReactComponent as Delete } from 'assets/images/trash.svg';
import styled from 'styled-components';
import Responsive from 'context/responsive';
import AutoAssignForm from './AutoAssignForm';

const ManageBoxRight = styled(ManageBox)`
  right: 18px;
`;

const Text = styled.span`
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0rem;
  text-align: left;
  color: ${(props) => props.theme.colors.white};
  background: rgba(50, 49, 49, 0.7);
  border-radius: 11.5px;
  padding: 3px 8px;
  margin-top: 5px !important;
  width: fit-content;
`;

const Box = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const BoxBordered = styled.div`
  border-radius: 10px;
  padding: 18px;
  width: 100%;
  margin: 10px 0;
  background: #fff;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    font-size: 0.75rem;
  }
  .title {
    p {
      width: 120px;
      color: #85929B;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .value {
    width: 100%;
  }
`;

const Tr = styled.tr`
  height: 70px;
`;

const Form = styled(Block)`
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 95vw;
  }
`;

const Span = styled.span`
  font-size: .75rem;
  font-style: normal;
  font-weight: 600;
  -webkit-letter-spacing: 0rem;
  -moz-letter-spacing: 0rem;
  -ms-letter-spacing: 0rem;
  letter-spacing: 0rem;
  color: #2C2C2C;
`;

const OverManage = styled(Overlay)`
z-index: 19;
background: inherit;
`;

const TableRules = styled(Table)`
  
  .name {
    width: 20%;
  }
  tr {
    &:after {
      display: none;
    }
    &:nth-child(even) {
      background: #F9F9F9;
      position: static;
    }
    &:hover {
      background: inherit!important;
    }
  }
`;

export default function AutoAssignTable({ list, users, gic, naics }) {
  const { t: homeT } = useTranslation('home');
  const { t: ruleT } = useTranslation('autoassign');
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [edit] = useState(true);

  const dispatch = useDispatch();

  function handleDelete(id) {
    setSuccess('Rule was deleted');
    dispatch(deleteAssignRuleById(id));
  }

  const handleShow = (idx) => () => {
    setShow((state) => ({
      ...state,
      [idx]: !show[idx],
    }));
  };

  const handleShowModal = (idx) => () => {
    //dispatch(fetchHidingRuleById(129));
    setShowModal((state) => ({
      ...state,
      [idx]: true
    }));
  };

  const handleShowDelete = (idx) => () => {
    setShowModal((state) => ({
      ...state,
      [idx]: true
    }));
    setConfirm(true);
  };

  function handleShowOff() {
    setShowModal(false);
    setShow(false);
    setSuccess(false);
    setConfirm(false);
  }

  const ctx = useContext(Responsive);

  return (
    <>
      {ctx.isMobile ? (
        <>
          <Box>
            {list &&
              list?.map((lead, idx) => (
                <BoxBordered key={idx}>
                  <Row>
                    <div className="title">
                      <TextBlack>{homeT('name')}</TextBlack>
                    </div>
                    <div className="value">
                      <TextBlackdark>{lead.name}</TextBlackdark>
                    </div>
                    <Manage onClick={handleShow(idx)}>
                      <Nav />
                      {show[idx] && (
                        <ManageBoxRight>
                          <a onClick={handleShowModal(idx)}>
                            <Edit />
                            <span>{homeT('edit')}</span>
                          </a>
                          <a onClick={handleShowDelete(idx)}>
                            <Delete />
                            <span>{homeT('delete')}</span>
                          </a>
                        </ManageBoxRight>
                      )}
                    </Manage>
                  </Row>
                  <Row>
                    <div className="title">
                      <TextBlack>{homeT('appliedTo')}</TextBlack>
                    </div>
                    <div className="value">
                      <TextBlackdark>
                        {' '}
                        {lead.ga_view.website_name}
                      </TextBlackdark>
                    </div>
                  </Row>
                  <Row>
                    <div className="title">
                      <TextBlack>{homeT('filters')}</TextBlack>
                    </div>
                    <div className="value">
                      <TextBlackdark>
                        {lead.lead_filters_attributes.map((filter, idx) => (
                          <React.Fragment key={idx}>
                            <TextBlackdark>
                              {(filter.key === 'gic_industry' && 'GIC') ||
                                (filter.key === 'industry' && 'NAICS') ||
                                (filter.key === 'company_name' &&
                                  'Company name') ||
                                (filter.key === 'full_contact_city' &&
                                  'Headquarters City') ||
                                (filter.key === 'full_contact_region' &&
                                  'Headquarters Region') ||
                                (filter.key === 'full_contact_country' &&
                                  'Headquarters Country') ||
                                (filter.key === 'page_path' && 'Page Path') ||
                                (filter.key === 'time_on_page' &&
                                  'Time on Website') ||
                                (filter.key === 'country' &&
                                  'Visitor Location Country') ||
                                (filter.key === 'region' &&
                                  'Visitor Location State/Province') ||
                                (filter.key === 'city' &&
                                  'Visitor Location City') ||
                                (filter.key === 'tag' && 'Tag') ||
                                (filter.key === 'domain' && 'Domain') ||
                                filter.key}
                              {': '} { filter.operator == "=" && 'equals' ||
                          filter.operator == "!=" && 'not equal to' ||
                          filter.operator}{' '}
                              {filter.key === 'gic_industry' ||
                              filter.key === 'industry' ? (
                                  filter.key === 'gic_industry' ? (
                                    gic
                                      .filter(({ value }) =>
                                        filter.value.split(',').includes(value)
                                      )
                                      .map((filter, idx) => (
                                        <div key={idx}>
                                          <Span>{filter.label}</Span>
                                        </div>
                                      ))
                                  ) : (
                                    []
                                      .concat(
                                        ...naics.map(
                                          ({ options }) => options || []
                                        )
                                      )
                                      .filter(({ value }) =>
                                        filter.value.split(',').includes(value)
                                      )
                                      .map((filter, idx) => (
                                        <div key={idx}>
                                          <Span>{filter.label}</Span>
                                        </div>
                                      ))
                                  )
                                ) : filter.operator == 'between' ? (
                                  <Span>{filter?.multiple_filter?.value_from + '-' + 
                                  filter?.multiple_filter?.value_to} </Span>
                                ) : (
                                  <Span>{filter.value} </Span>
                                )}
                          
                            </TextBlackdark>
                          </React.Fragment>
                        ))}
                      </TextBlackdark>
                    </div>
                  </Row>
                  <Row>
                    <div className="title">
                      <TextBlack>{homeT('assignTo')}</TextBlack>
                    </div>
                    <div className="value">
                      <TextBlackdark>
                        {' '}
                        {lead.user.first_name} {lead.user.last_name}{' '}
                      </TextBlackdark>
                    </div>
                  </Row>
                  {showModal[idx] && (
                    <>
                      <Modal>
                        <Overflow onClick={handleShowOff}></Overflow>
                        {!success && !confirm && (
                          <>
                            <Form>
                              <AutoAssignForm
                                name={lead.name}
                                website={lead.ga_view.website_name}
                                user={lead.user.full_name}
                                edit={edit}
                                setShowOff={handleShowOff}
                                setSuccess={setSuccess}
                                users={users}
                                filters={lead.lead_filters_attributes}
                                id={lead.id}
                                lead={lead}
                              />
                            </Form>
                          </>
                        )}
                        {success && <Result>saved</Result>}
                        {confirm && (
                          <Confirm>
                            <TextBlack>{ruleT('delete')}</TextBlack>
                            <FlexEnd>
                              <OutlinedButton onClick={handleShowOff}>
                                {homeT('cancel')}
                              </OutlinedButton>
                              <FilledButton
                                /* eslint-disable */
                                onClick={() => handleDelete(lead.id)}
                                /* eslint-enable */
                              >
                                {homeT('delete')}
                              </FilledButton>
                            </FlexEnd>
                          </Confirm>
                        )}
                      </Modal>
                    </>
                  )}
                </BoxBordered>
              ))}
          </Box>
        </>
      ) : (
        <TableRules>
          <tbody>
            <tr>
              <th>
                <TableTitle>{homeT('name')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('appliedTo')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('filters')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('assignTo')}</TableTitle>
              </th>
              <th>
                <TableTitle></TableTitle>
              </th>
            </tr>

            {list &&
              list?.map((lead, idx) => (
                <Tr key={idx}>
                  <td className='name'>
                    <TextBlackdark> {lead.name}</TextBlackdark>
                  </td>
                  <td>
                    <TextBlackdark>
                      {lead.ga_view.website_name}
                      {lead.ga_view.sunsetted && (
                        <Text>{homeT('sunsetted')}</Text>
                      )}
                    </TextBlackdark>
                  </td>
                  <td>
                    {lead.lead_filters_attributes?.map((filter, idx) => (
                      <React.Fragment key={idx}>
                        <TextBlackdark>
                          {(filter.key === 'gic_industry' && 'GIC') ||
                            (filter.key === 'industry' && 'NAICS') ||
                            (filter.key === 'company_name' && 'Company name') ||
                            (filter.key === 'full_contact_city' &&
                              'Headquarters City') ||
                            (filter.key === 'full_contact_region' &&
                              'Headquarters Region') ||
                            (filter.key === 'full_contact_country' &&
                              'Headquarters Country') ||
                            (filter.key === 'page_path' && 'Page Path') ||
                            (filter.key === 'time_on_page' &&
                              'Time on Website') ||
                            (filter.key === 'country' &&
                              'Visitor Location Country') ||
                            (filter.key === 'region' &&
                              'Visitor Location State/Province') ||
                            (filter.key === 'city' &&
                              'Visitor Location City') ||
                            (filter.key === 'tag' && 'Tag') ||
                            (filter.key === 'domain' && 'Domain') ||
                            filter.key}
                          {' '} { filter.operator == "=" && 'equals' ||
                          filter.operator == "!=" && 'not equal to' ||
                          filter.operator}{': '}
                          {filter.key === 'gic_industry' ||
                          filter.key === 'industry' ? (
                              filter.key === 'gic_industry' ? (
                                gic
                                  .filter(({ value }) =>
                                    filter.value.split(',').includes(value)
                                  )
                                  .map((filter, idx) => (
                                    <div key={idx}>
                                      <Span>{filter.label}</Span>
                                    </div>
                                  ))
                              ) : (
                                []
                                  .concat(
                                    ...naics.map(({ options }) => options || [])
                                  )
                                  .filter(({ value }) =>
                                    filter.value.split(',').includes(value)
                                  )
                                  .map((filter, idx) => (
                                    <div key={idx}>
                                      <Span>{filter.label}</Span>
                                    </div>
                                  ))
                              )
                            ) : filter.operator == 'between' ? (
                              <Span>{filter?.multiple_filter?.value_from + '-' + 
                              filter?.multiple_filter?.value_to} </Span>
                            ) : (
                              <Span>{filter.value} </Span>
                            )}
                        </TextBlackdark>
                      </React.Fragment>
                    ))}
                  </td>
                  <td>
                    <TextBlackdark>
                      {' '}
                      {lead?.user?.first_name} {lead?.user?.last_name}{' '}
                    </TextBlackdark>
                  </td>
                  <td>
                    <Manage onClick={handleShow(idx)}>
                      <Nav />
                      {show[idx] && (
                        <>
                          <OverManage onClick={handleShowOff}></OverManage>
                          <ManageBoxRight>
                            <a onClick={handleShowModal(idx)}>
                              <Edit />
                              <span>{homeT('edit')}</span>
                            </a>
                            <a onClick={handleShowDelete(idx)}>
                              <Delete />
                              <span>{homeT('delete')}</span>
                            </a>
                          </ManageBoxRight>
                        </>
                      )}
                    </Manage>
                  </td>
                  {showModal[idx] && (
                    <>
                      <Modal>
                        <Overflow onClick={handleShowOff}></Overflow>
                        {!success && !confirm && (
                          <>
                            <Form>
                              <AutoAssignForm
                                name={lead.name}
                                website={lead.ga_view.website_name}
                                user={lead.user.full_name}
                                edit={edit}
                                setShowOff={handleShowOff}
                                setSuccess={setSuccess}
                                users={users}
                                filters={lead.lead_filters_attributes}
                                id={lead.id}
                                lead={lead}
                              />
                            </Form>
                          </>
                        )}
                       
                        {confirm && (
                          <Confirm>
                            <TextBlack>{ruleT('delete')}</TextBlack>
                            <FlexEnd>
                              <OutlinedButton onClick={handleShowOff}>
                                {homeT('cancel')}
                              </OutlinedButton>
                              <FilledButton
                                /* eslint-disable */
                                onClick={() => handleDelete(lead.id)}
                                /* eslint-disable */
                              >
                                {homeT('delete')}
                              </FilledButton>
                            </FlexEnd>
                          </Confirm>
                        )}
                      </Modal>
                    </>
                  )}
                  
                </Tr>
              ))}
            {success && <Result>{success}</Result>}
          </tbody>
        </TableRules>
      )}
    </>
  );
}

AutoAssignTable.propTypes = {
  list: array,
  users: array,
  naics: array,
  gic: array
};
