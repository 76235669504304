import styled from 'styled-components';

const FilledLink = styled.a`
  font-size: .85em;
  font-weight: 500;
  padding: 10px 16px;
  height: 37px;
  border-radius: 8px;
  background-color: #F96652;
  border: none;
  color: #FEFDFD;
  cursor: pointer;
  text-decoration: none;
`;

export default FilledLink;