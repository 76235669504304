import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPersonalizationById,
  getAllPersonalizations,
  getStatistic,
  postPersonalizations,
  deletePersonalizations,
  putPersonalizations,
  putPersonalizationStatus
} from 'base/network/personalizations';
import { LOADING, SUCCEED, FAILURE } from 'store/CONSTANTS';

export const initialState = {
  status: LOADING,
  statusNew: null,
  page: 1,
  personalizations: [],
  personalization: null,
  summary: [],
  filters: {},
  errors: {},
  id: null
};

export const fetchPersonalizationById = createAsyncThunk(
  'personalizationSlice/fetchPersonalizationById',
  async (id) => {
    const response = await getPersonalizationById(id);
    return response.data;
  }
);

export const fetchAllPersonalizations = createAsyncThunk(
  'personalizationSlice/fetchAllPersonalizations',
  async ({ q,
    conversion_campaign_id,
    min_date,
    max_date,
    per_page,
    ...rest }) => {
    const response = await getAllPersonalizations({ 
      page: 1,
      q,
      conversion_campaign_id,
      min_date,
      max_date,
      per_page,
      ...rest });
    return response.data;
  }
);

export const fetchStatistic = createAsyncThunk(
  'statisticSlice/fetchStatistic',
  async ({ 
    google_analytics_view_id,
    min_date,
    max_date,
    per_page,
    ...rest }) => {
    const response = await getStatistic({ 
      page: 1,
      google_analytics_view_id,
      min_date,
      max_date,
      per_page,
      ...rest });
    return response.data;
  }
);

export const fetchPersonalizationsPerPage = createAsyncThunk(
  'personalizationSlice/fetchPersonalizationsPerPage',
  async ({ page, ...rest }) => {
    const response = await getAllPersonalizations({ page, ...rest });
    return {
      data: response.data,
      page,
    };
  }
);

export const postPersonalization = createAsyncThunk(
  'personalizationSlice/postPersonalization',
  async (params, { rejectWithValue }) => {
    try {
      const response = await postPersonalizations(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data, error);
    }
  }
);

export const putPersonalization = createAsyncThunk(
  'personalizationSlice/putPersonalization',
  async ({id, body}, {rejectWithValue}) => {
    try {
      const response = await putPersonalizations(id, body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data, error);    }
  }
);

export const putPersonalizationRun = createAsyncThunk(
  'personalizationSlice/putPersonalizationRun',
  async ({id, body}, {rejectWithValue}) => {
    try {
      const response = await putPersonalizationStatus(id, body);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data, error);    }
  }
);

export const deletePersonalization = createAsyncThunk(
  'personalizationSlice/deletePersonalization',
  async (id) => {
    try {
      const response = await deletePersonalizations(id);
      return response.data;
    } catch (error) {
      //return rejectWithValue(error.response?.data, error);
      return error;
    }
  }
);

export const personalizationSlice = createSlice({
  name: 'personalizationSlice',
  initialState,
  reducers: {
    nextPage(state) {
      state.page += 1;
    },
    setFiltersData(state, payload) {
      state.personalizations = payload;
    },
  },
  extraReducers: {
    [putPersonalization.pending]: (state) => {
      state.status = LOADING;
    },
    [putPersonalization.fulfilled]: (state, action) => {
      state.status = SUCCEED;
      state.personalizations = state.personalizations.map(rule => {
        if(rule.id === action.payload?.results?.id) {
          return action.payload?.results;
        }
        return rule;
      });
    },
    [putPersonalization.rejected]: (state, { payload, meta }) => {
      state.personalization = Object.entries(meta.arg)
        .reduce((acc, [ key, value ]) => ({ ...acc, [key]: value }), { ...state.user });
      state.status = FAILURE;
      state.errors = payload?.message;
    },

    [putPersonalizationRun.pending]: (state) => {
      state.status = LOADING;
    },
    [putPersonalizationRun.fulfilled]: (state) => {
      state.status = SUCCEED;
      state.personalization = action.payload?.results;
      state.personalizations = state.personalizations.concat(action.payload?.results);
    },
    [putPersonalizationRun.rejected]: (state, { payload, meta }) => {
      state.personalization = Object.entries(meta.arg)
        .reduce((acc, [ key, value ]) => ({ ...acc, [key]: value }), { ...state.user });
      state.status = FAILURE;
      state.errors = payload?.message;
    },

    [fetchPersonalizationById.pending]: (state) => {
      state.status = LOADING;
    },
    [fetchPersonalizationById.fulfilled]: (state, action) => {
      state.personalization = action.payload;
      state.status = SUCCEED;
    },
    [fetchPersonalizationById.rejected]: (state) => {
      state.status = FAILURE;
    },

    [fetchAllPersonalizations.pending]: (state) => {
      state.status = LOADING;
    },
    [fetchAllPersonalizations.fulfilled]: (state, action) => {
      state.personalizations = action.payload?.results;
      state.page = 1;
      state.status = SUCCEED;
    },
    [fetchAllPersonalizations.rejected]: (state) => {
      state.status = FAILURE;
    },

    [fetchStatistic.pending]: (state) => {
      state.status = LOADING;
    },
    [fetchStatistic.fulfilled]: (state, action) => {
      state.summary = action.payload?.results;
      state.page = 1;
      state.status = SUCCEED;
    },
    [fetchStatistic.rejected]: (state) => {
      state.status = FAILURE;
    },


    [postPersonalization.pending]: (state) => {
      state.status = LOADING;
      state.statusNew = LOADING;
    },
    [postPersonalization.fulfilled]: (state, action) => {
      state.status = SUCCEED;
      state.statusNew = SUCCEED;
      state.personalization = action.payload?.results;
      state.id = action.payload?.results?.uuid;
      state.personalizations = state.personalizations.concat(action.payload?.results);
    },
    [postPersonalization.rejected]: (state, { payload, meta }) => {
      state.assignRules = Object.entries(meta.arg)
        .reduce((acc, [ key, value ]) => ({ ...acc, [key]: value }), { ...state.assignRules });
      state.status = FAILURE;
      state.errors = payload?.message;
    },

    [fetchPersonalizationsPerPage.pending]: (state) => {
      state.status = LOADING;
    },
    [fetchPersonalizationsPerPage.fulfilled]: (state, { payload }) => {
      const { data, page } = payload;
      state.page = page;
      state.personalizations = state.personalizations.concat(
        data.personalizations
      );
      state.status = SUCCEED;
    },
    [fetchPersonalizationsPerPage.rejected]: (state) => {
      state.status = FAILURE;
    },

    [deletePersonalization.pending]: (state) => {
      state.status = LOADING;
    },
    [deletePersonalization.fulfilled]: (state, action) => {
      state.status = SUCCEED;
      state.personalization = action.payload?.results;
      state.personalizations = state.personalizations.filter((x) => {
        return x.id != action.payload?.results.id;
      });
    },
    [deletePersonalization.rejected]: (state, { payload, meta }) => {
      state.personalizations = Object.entries(meta.arg).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        { ...state.assignRules }
      );
      state.status = FAILURE;
      state.errors = payload?.message;
    },
  },
});

export const { nextPage, setFiltersData } = personalizationSlice.actions;

export default personalizationSlice.reducer;
