import styled from 'styled-components';

const AccentLink = styled.a`
  font-family: inherit;
  padding: 6px 14px;
  font-size: .875rem;
  font-weight: 500;
  height: 37px;
  color: ${props => props.theme.colors.accentRed};
  cursor: pointer;
  white-space: nowrap;
  &.disabled {
    cursor: auto!important;
  }
  svg {
    margin-right: 8px;
  }
  @media (max-width: 830px) {
    svg {
      margin-right: 4px;
    }
  }
`;

export default AccentLink;
