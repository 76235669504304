import { useState, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { func, number, string } from "prop-types";
import {
  ButtonsWrap,
  GradientButton,
  OutlinedButton,
  TextBlackThin,
  TextBlackBig,
  Flex,
  AccentButton,
  SecondaryBtnHigh,
  TextAccent
} from "base/styled";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from 'assets/images/close.svg';
import network from "base/network";

const Wrapper = styled.div`
padding: 15px;

  @media (max-width: 768px) {
    p {
      width: auto;
      max-width: fit-content !important;
    }
  }
`;

const Message = styled.div`
  margin-top: 20px;
`;

const Content = styled.div`
  padding: 20px 10px 10px 20px;
  &.info {
    padding: 0
  }
`;

const Textarea = styled.textarea`
  border: 1px solid ${props => props.theme.colors.lightGrey};
  font-family: 'Montserrat', sans-serif;
  font-size: .75rem;
  color: ${props => props.theme.colors.lightGrey};
  border-radius: 8px;
  height: 80px;
  width: 100%;
  padding: 10px;
  margin: 0;
  width: 400px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled(Flex)`
  justify-content: space-between;
  p {
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    max-width: 220px;
    line-height: 25px;
  }
  svg {
    margin-top: 0px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;

  button{
    padding: 0 15px;
    height: 37px;
    margin: 10px
  } 
`;

export default function Suggest({handleShowModalOff, id, setSuccess, suggest}) {
  const { t: rootT } = useTranslation("home");
  const { t: homeT } = useTranslation('home');
  const [message, setMessage] = useState('');
  const [error, setError] = useState();

  const postSuggest = async () => {
    await network
      .post(
        `/api/user_suggestions/${id}`,
        {
          lead_id: id,
          text: message,
          kind: suggest,
        },
      )
      .then(() => {
        handleShowModalOff();
        setSuccess(true);
      })
      .catch((error) => console.log(error));
  };

  function handleSubmit() {
    if (!message || message.length == 0) {
      setError('Enter your message');
    } else {
      postSuggest();
    } 
  }

  const captchaRef = useRef(null);

  function handleMessage(e) {
    setMessage(e.target.value);
  }

  return (
    <Wrapper>
      <>{suggest =='info' ? (
        <Title>
          <TextBlackBig>
            {homeT('describeSuggest')}
          </TextBlackBig>
          <Close onClick={handleShowModalOff}/>
        </Title>
      ) :
        <TextBlackThin style={{ textAlign: "center" }}>
          {rootT("suggest")}
        </TextBlackThin>
      }
      <Content className={suggest =='info' ? 'info' : ''}>
        <Message>
          <Textarea value={message} onChange={handleMessage} label="label" />
        </Message>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          ref={captchaRef}
        />
        <TextAccent>{error}</TextAccent>
        { suggest == 'info' ? (
          <Buttons>
            <SecondaryBtnHigh onClick={handleShowModalOff}>
              {homeT('cancel')}
            </SecondaryBtnHigh>
            <AccentButton onClick={() => handleSubmit()}>
              {homeT('submit')}
            </AccentButton>
          </Buttons>
        ) : (
          <ButtonsWrap>
            <OutlinedButton onClick={handleShowModalOff}>
              {rootT("cancel")}
            </OutlinedButton>
            <GradientButton onClick={handleSubmit}>
              {rootT("submit")}
            </GradientButton>
          </ButtonsWrap>
        ) }
      </Content>
      </>
    </Wrapper>
  );
}

Suggest.propTypes = {
  handleShowModalOff: func, 
  id: number, 
  setSuccess: func,
  suggest: string
};
