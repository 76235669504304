import { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { func, any } from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import BillingDetailsFields from './prebuilt/BillingDetailsFields';
import CheckoutError from './prebuilt/CheckoutError';
import { FilledButton, SecondaryBtnHigh } from 'base/styled';
import network from 'base/network';

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin: 30px 0;

  & .StripeElement {
    width: 100%;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.85em;
    font-weight: 100;
    margin: 20px 10px;
    padding: 8px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    outline: 0;
    border: 1px solid #c6d5dd;
    background: white;
    border-radius: 8px;
    height: 33px;
    width: 100%;
    color: #c6d5dd;
    transition: all 0.2s;
    &:focus-visible {
      outline: none;
    }
  }
`;

const Form = styled.form`
  padding: 10px;
  input,
  select {
    height: 31px;
    margin: 8px 10px 0;
  }
  .light {
    background: #c6d5dd;
    color: transparent;
    height: 1px;
    border: none;
    width: 100%;
  }
`;

const Buttons = styled.div`
  display: flex;
  padding: 0 10px;
  margin-left: auto;
  justify-content: end;
  button {
    margin-left: 10px;
  }
`;

const CheckoutForm = ({
  handleSubscribeOff,
  setSuccess,
  details,
}) => {
  const { t: homeT } = useTranslation('home');
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [accountName, setAccountName] = useState(details?.name);

  const stripe = useStripe();
  const elements = useElements();

  function handleRegion(val) {
    setRegion(val);
    console.log(val);
  }

  function handleCountry(val) {
    setCountry(val);
    console.log(val);
  }

  function handleName(val) {
    setAccountName(val);
    console.log(val);
  }

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      address: {
        line1: ev.target.address.value,
        city: ev.target.city.value,
        country: country,
        region: region,
      },
    };

    setProcessingTo(true);

    const cardElement = elements.getElement('card');
    console.log(billingDetails);
    stripe.createToken(cardElement).then(function (result) {
      const token = result.token.id;
      network.put('api/account/payment_info', {stripe_token: token});
      setSuccess(true);
    });
  };

  const iframeStyles = {
    base: {
      color: '#85929B',
      fontSize: '14px',
      // border: "1px solid #C6D5DD",
      iconColor: '#85929b38',
      '::placeholder': {
        color: '#85929B',
        fontSize: '14px',
        fontWeight: '400',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
    complete: {
      iconColor: '#cbf4c9',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <div>
        <BillingDetailsFields
          country={country}
          region={region}
          handleCountry={handleCountry}
          handleRegion={handleRegion}
          name={accountName}
          handleName={handleName}
        />
      </div>
      <div>
        <CardElementContainer>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </CardElementContainer>
      </div>

      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}

      <Buttons>
        <SecondaryBtnHigh type="reset" onClick={handleSubscribeOff}>
          {homeT('cancel')}
        </SecondaryBtnHigh>
        <FilledButton disabled={isProcessing || !stripe}>
          {homeT('confirm')}
        </FilledButton>
      </Buttons>
    </Form>
  );
};

export default CheckoutForm;

CheckoutForm.propTypes = {
  handleSubscribeOff: func,
  setSuccess: func,
  details: any
};
