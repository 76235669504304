import { useState, useRef, useEffect } from "react";
import { any, bool, number, string } from 'prop-types';
import styled from 'styled-components';
import { TextBlackExtraSmall, TextBlackThin } from 'base/styled';
import { ReactComponent as CollapseMain } from 'assets/images/triangle.svg';
import { ReactComponent as Date } from 'assets/images/date.svg';
import { ReactComponent as View } from 'assets/images/view.svg';
import { ReactComponent as Time } from 'assets/images/time.svg';
import { ReactComponent as Source } from 'assets/images/source.svg';

const AccordionTitle = styled.div`
  padding: 8px 0 6px;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 2fr 1fr;
  text-align: center;
  cursor: pointer;

  &.closed {
    padding: 0 0 6px;
    height: 39px;
      &:hover {
        background: #FFF9F1;
      }
  }
  .view-icon {
    margin: 0 6px 0 0;
  }
  .text-left {
    width: 87px;
    padding-left: 15px;
    span {
      display: block;
      color: #85929B;
    }
  }
  .d-flex {
    display: flex;
    justify-content: center;
  }
  &.is-open {
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 31px;
      left: 17px;
      width: 91%;
      height: 1px;
      background: #E2E2E2;
    }
  }
`;

const AccordionBlock = styled.div`
  background: white;
  border-radius: 18px;
  box-shadow: 0px 0px 6px 0px #B6B4B354;
  overflow: hidden;
  margin: 5px;
  &.open {
    box-shadow: 0px 0px 6px 0px #E0892354;
  }
  svg {
    transition: transform 280ms cubic-bezier(0, 1, 0, 1);
  }
  .rotate {
    transform: rotate(90deg);
  }
  
  .accordion-content {
    overflow: hidden;
    transition: max-height 0.6s ease;
    height: fit-content;
  }
`;

const TextBlackExtraSmallBold = styled(TextBlackExtraSmall)`
  font-weight: 500
`;

const VisitDetailsAccordion = ({ allactive, children, collapse, date, count, total_time, source }) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    let height = active
      ? `${contentRef.current.scrollHeight + 800}px`
      : "0px";
    contentRef.current.style.maxHeight = height;
  }, [contentRef, active]);
  
  useEffect(() => {
    setActive(allactive);
  }, [allactive]);

  function toogleActive() {
    setActive(!active);
  }

  const regex = /, /;
  
  return (
    <AccordionBlock className={active ? 'open' : ''}>
      <AccordionTitle className={active ? 'is-open accordion-title' : 'closed accordion-title'} onClick={toogleActive}>
        {active && (<>
          <div>
            <Date />
          </div>
          <div>
            <View />
          </div>
          <div>
            <Time />
          </div>
          <div>
            <Source />
          </div>
          <div className={active ? "accordion-icon rotate" : "accordion-icon"}>
            {collapse}
            <CollapseMain />
          </div>
        </>)}
        <>
          <TextBlackExtraSmall className="text-left">{date?.split(regex)[0]}
            <span>{date?.split(regex)[1]}</span></TextBlackExtraSmall> 
          <TextBlackExtraSmallBold className="d-flex">
            {!active && <View className="view-icon"/> }<span>{count}</span>
          </TextBlackExtraSmallBold> 
          <TextBlackExtraSmallBold>{Math.floor(parseFloat(total_time)/60) > 0 && 
            (Math.floor(parseFloat(total_time)/60) + 'min ')}
          {parseFloat(total_time)% 60 + 's'}</TextBlackExtraSmallBold> 
          <TextBlackExtraSmall>{source}</TextBlackExtraSmall> 
          <TextBlackThin className={active ? "d-none" : "accordion-icon"}>
            {collapse}
            <CollapseMain />
          </TextBlackThin>
        </>
      </AccordionTitle>

      <div ref={contentRef} className="accordion-content">
        {children}
      </div>
    </AccordionBlock>
  );
};

export default VisitDetailsAccordion;

VisitDetailsAccordion.propTypes = {
  allactive: bool,
  date: string,
  time: string,
  source: string,
  children: any,
  views: any,
  collapse: any,
  count: any,
  total_time: number
};
