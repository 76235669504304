import { TextBlackBig, TitlePage } from 'base/styled';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  width: 781px;
  height: 393px;
  background: #fff;
  margin: 85px auto;
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    margin: auto;
  }
  h2 {
    font-size: 14px;
  }
  p {
    font-size: 14px
  }
  a {
    padding: 10px 0 50px;
    img {
      min-width: 160px;
    }
  }
`;

export default function Error() {

  return (
    <Flex>
      <a>
        <img src="https://assets.visitorqueue.com/images/blog/short_logo.svg" />
      </a>
      <TitlePage>Oops, something went wrong</TitlePage>
      <TextBlackBig>Our team is notified and working on it.</TextBlackBig>
    </Flex>
  );
}
