import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { string, func, number, bool } from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import { AccentButton, Block, SecondaryBtnHigh, TextBlackSmall, TextBlackSmallThin, TextLabel } from 'base/styled';
import network from 'base/network';

const Popup = styled(Block)`
  margin: 0 auto;
  width: 400px;
  button {
    height: 37px;
    margin-right: 5px;
  }
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  form {
    width: 100%;
    text-align: left;
    .center {
      text-align: center;
      font-size: .85rem;
      line-height: 1.3rem;
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 0;
    }
    p {
      padding: 20px 0;
    }
  }
`;

const Textarea = styled.textarea`
  border: 1px solid#C6D5DD!important;
  font-family: 'Montserrat', sans-serif;
  font-size: .75rem;
  &::placeholder {
    color: #626262
  }
  color: #2c2c2c;
  border-radius: 8px;
  height: 80px;
  width: 100%;
  padding: 10px;
  margin: 0;
  background: white!important;
`;

const Buttons = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Message = styled.div`
  margin-top: 20px;
`;

export default function FormEmail({handleFormEmailOff, id, setSuccess, integration}) {
  const { t: homeT } = useTranslation('home');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  function handleMessage(e) {
    setMessage(e.target.value);
  }

  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email.trim());
  };

  const postEmail = async (token) => {
   
    let res = await network
      .post(`/api/websites/${id}/send_script`, { id: id, response: token,
        emails: email, 
        message: integration ? message :
          message || 'Please add this tracking script to the <head> section of every page on our website'})
      .then(({ data }) => {
        console.log(data);
        handleFormEmailOff();
        setSuccess(data.results);
        //history.push('/');
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  const captchaRef = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    const emailArray = email.split(',').map(e => e.trim());

    // Validate each email
    const invalidEmails = emailArray.filter(e => !validateEmail(e));
    if (invalidEmails.length > 0) {
      setEmailError(`Invalid email(s): ${invalidEmails.join(', ')}`);
    } else {
      postEmail(token);
    }
  }

  return (
    <Popup>
      <form onSubmit={handleSubmit}>
        <TextBlackSmall className="center">
          {homeT('emailTitle')}
        </TextBlackSmall>
        <TextBlackSmallThin>{homeT('emailText')}</TextBlackSmallThin>
        <FormTextField
          label="Email"
          required
          value={email}
          onChange={handleEmail}
          placeholder="Enter email"
        />
        <TextLabel>{homeT('useCommasEmail')}</TextLabel>
        {emailError && <TextBlackSmallThin style={{ color: 'red' }}>{emailError}</TextBlackSmallThin>}
        <Message>
          <TextLabel>{homeT('message')}</TextLabel>
          <Textarea placeholder={homeT('emailTextarea')} value={message} onChange={handleMessage} label="label" />
        </Message>
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} />
        <Buttons>
          <SecondaryBtnHigh onClick={handleFormEmailOff}>
            {homeT('cancel')}
          </SecondaryBtnHigh>
          <AccentButton type='submit'>{homeT('send')}</AccentButton>
        </Buttons>
      </form>
    </Popup>
  );
}

FormEmail.propTypes = {
  handleFormEmailOff: func,
  code: string,
  setSuccess: func,
  id: number,
  integration: bool
};
