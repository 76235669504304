import styled from 'styled-components';

const SearchForm = styled.form`
  min-width: 165px;
  height: 37px;
  position: relative;
  svg {
    position: absolute;
    top: 12px;
    left: 16px;
    background: inherit;
    border: none;
    cursor: pointer;
    font-family: 'Montserrat';
  }
  
  input {
    width: 100%;
    height: 100%;
    padding: 0 14px 0 35px;
    background: #EBF4FA;
    color: #2c2c2c;
    font-size: 14px;
    border: none;
    box-sizing: border-box;
    border-radius: 28px;
    font-family: 'Montserrat';
    &:focus-visible {
      outline: none;
    }
  }
  input.light {
    background: #fff;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export default SearchForm;