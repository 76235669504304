import { useState} from 'react';
import styled from 'styled-components';
import { string, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ThemeButton,
  Block,
  Overflow
} from 'base/styled';
import { ReactComponent as Hubspot } from 'assets/images/hubspot_btn.svg';
import Company from './Company';
import Deal from './Deal';
import Task from './Task';

const Wrap = styled.div`
  position: relative;
`;

const PopupBtn = styled(Block)`
  margin: -2% auto 0;
  padding: 10px 0;
  width: 190px;
  box-shadow: 0px 2px 12px 0px #00000021;
`;

const ModalBtn = styled.div`
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 25;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  a {
    font-size: .75rem;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 15px;
  }
`;

const Over = styled(Overflow)`
  height: 180%;
`;

const ModalBox = styled(Modal)`
  overflow-x: hidden;
  overflow-y: auto;
`;

export default function Index({ id, leadName }) {
  const { t: homeT } = useTranslation('home');
  const [openHubspot, setOpenHubspot] = useState(false);
  const [modal, setModal] = useState();
  const [company, setCompany] = useState();
  const [deal, setDeal] = useState();
  const [task, setTask] = useState();
  
  function handleCompany() {
    setModal(true);
    setCompany(true);
    setOpenHubspot(false);
  }

  function handleCompanyOff() {
    setModal(false);
    setCompany(false);
  }

  function handleDeal() {
    setModal(true);
    setDeal(true);
    setOpenHubspot(false);
  }

  function handleDealOff() {
    setModal(false);
    setDeal(false);
  }

  function handleTask() {
    setModal(true);
    setTask(true);
    setOpenHubspot(false);
  }

  function handleTaskOff() {
    setModal(false);
    setTask(false);
  }

  return (
    <Wrap>
      <ThemeButton onClick={() => setOpenHubspot(true)}>
        <Hubspot />
        {homeT('sendHubspot')}
      </ThemeButton>
      {openHubspot && (
        <>
          <Modal onClick={() => setOpenHubspot(false)}></Modal>
          <ModalBtn>
            <PopupBtn>
              <Links>
                <a onClick={handleCompany}>{homeT('createCompany')}</a>
                <a onClick={handleDeal}>{homeT('createDeal')}</a>
                <a onClick={handleTask}>{homeT('createTask')}</a>
              </Links>
            </PopupBtn>
          </ModalBtn>
        </>
      )}
      {modal && company && (
        <Modal>
          <Overflow onClick={handleCompanyOff}></Overflow>
          <Company handleFormOff={handleCompanyOff} leadName={leadName} id={id} />
        </Modal>
      )}
      {modal && deal && (
        <ModalBox>
          <Over onClick={handleDealOff}></Over>
          <Deal handleFormOff={handleDealOff} leadName={leadName} id={id} /> 
        </ModalBox>
      )}
      {modal && task && (
        <ModalBox>
          <Over onClick={handleTaskOff}></Over>
          <Task handleFormOff={handleTaskOff} leadName={leadName} id={id} />
        </ModalBox>
      )}
    </Wrap>
  );
}

Index.propTypes = {
  id: number,
  leadName: string,
  leadLocation: string
};
