import { BlockMargin, Check } from "base/styled";
import { func } from "prop-types";
import styled from "styled-components";

const FilterModal = styled.div`
  background: #fff;
  position: absolute;
  padding: 15px;
  border-radius: 8px;
  top: 92px;
  left: 3%;
  z-index: 23;
  box-shadow: 0px 2px 12px 0px #00000021;

  .relative {
    position: relative
  }
`;

const BlockMarginRelative = styled(BlockMargin)`
  position: relative;
  @media (max-width: 768px) {
    margin: 8px 0;
  }
`;

export default function ContactFilter({setSelectedFilters}) {

  const options = [
    { name: "name", label: "Name" },
    { name: "position", label: "Position" },
    { name: "phone_number", label: "Phone Number" },
    { name: "linkedin", label: "Linkedin" },
    { name: "twitter", label: "Twitter" },
  ];
 
  const onChange = (event) => {
    const { value, checked } = event.target;
  
    if (checked) {
      setSelectedFilters((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedFilters((prevSelected) =>
        prevSelected.filter((item) => item !== value)
      );
    }
  };

  return (
    <FilterModal>
      {options.map((value) => (
        <BlockMarginRelative key={value.name} className="relative">
          <Check
            name="fruit"
            type="checkbox"
            value={value.name}
            onChange={onChange}
          />{" "}

          <span></span>
          <label>Has {value.label}</label>
        </BlockMarginRelative>
      ))}
    </FilterModal>
  );
}

ContactFilter.propTypes = {
  setSelectedFilters: func
};
