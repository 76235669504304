import styled from 'styled-components';

const ModalContent = styled.div`
min-width: 210px;
margin: 0 auto;
  .integrate {
    width: 420px;
  }
  .content {
    padding: 15px 35px;
    align-items: center;
    p {
      margin: 0 0 0 15px;
      max-width: 280px;
    }
    svg {
      cursor: pointer;
      position: absolute;
      top: 30px;
      left: 33px
    }
  }
  .title {
    width: 75%;
    margin-left: auto;
    align-items: center;
  }
  .info {
    align-items: start;
  }
  form {
    padding: 20px 35px 0;
    width: auto;
    input {
      width: 320px;
    }
    p {
      margin: 0 0 5px 0;
    }
  }
  button {
    padding: 10px 16px;
    height: auto;
    margin-left: 10px;
  }

  .success {
    text-align: center;
    &_icon {
      width: 55%;
      margin-left: auto;
      padding: 0 15px 0 0;
    }
    &_text {
      max-width: 190px;
      text-align: center;
      margin: 0 40px;
    }
    &_btn {
      margin: 20px 0 0 0;
      text-align: center;
      button {
        margin: 0;
      }
    }
  }
`;

export default ModalContent;