import { useEffect, useState } from 'react';
import { bool, func, node, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { postWebsite } from 'store/slices/websites';
import { fetchGAViewIds } from 'store/slices/gaViewId';
import styled from 'styled-components';
import {
  AccentButton,
  FlexWrapper,
  FlexWrapperStart,
  OutlinedButton,
  TextField,
  TextLight,
  TextLightExtraSmallBold,
  TitleSmallBlack,
  ModalContent,
  TextAccentSmall,
  TextArea,
  Result,
  Block,
  SecondaryBtnHigh,
  InlineLinkBold,
  TextBlack,
  TextBlackSmallThin,
  TitleBlack
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Warn } from 'assets/images/warn.svg';
import { ReactComponent as Arrow } from 'assets/images/arrow_left.svg';
import { ReactComponent as BackIcon } from 'assets/images/angle.svg';
import front from 'assets/images/fronL.png';
import ng from 'assets/images/ngL.png';
import Integration from 'pages/Websites/Integration';


const BtnWrap = styled.div`
  padding: 40px 0 0;
  display: flex;
  justify-content: space-between;
`;

const Text = styled(TextArea)`
  border: 1px solid #73CCFE;
  background: #FFF9F9;
  color: #85929B;
  font-weight: 400;
  padding: 9px 20px;
  height: 58px;
  font-size: 12px;
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  padding: 20px 0;
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  &.full {
    @media (min-width: 800px) {
      min-width: 760px
    }
  }
`;

const Step = styled.div`
  position: relative;
  margin: auto;
  margin-top: 80px;
  padding-bottom: 100px;
  height: 100%;
  overflow-y: auto;
  &.edit {
    margin-top: 0;
    padding-bottom: 0;
  }
  p.steps {
    text-align: center;
    margin-bottom: 40px
  }
`;

const Imgs = styled.div`
  display: flex;
  justify-content: center;
  div.type{
    border-radius: 20px;
    width: 123px;
    height: 123px;
    margin: 10px;
    &.front {
      background: url(${front}) no-repeat 10% center
    }
    &.ng {
      background: url(${ng}) no-repeat 10% center
    }
  }
`;

const Type = styled.div`
  padding: 10px;
  text-align: center;
  cursor: pointer;
  p {
    font-weight: 400;
    text-align: center;
  }
  .sm {
    font-size: 10px;
    max-width: 100px;
    margin: 0 auto;
  }
`;

export default function AddWebsite({ handleOpenAddOff, edit, item, handleUpdate,  header }) {
  const { t: website } = useTranslation('website');
  const { site } = useSelector(
    (state) => state.websitesReducer
  );
  const [siteName, setSiteName] = useState(edit ? item.name : site?.name);
  const [stepOne, setStepOne] = useState(true);
  const [copied, setCopied] = useState(false);
  const [success, setSuccess] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepTwoNew, setStepTwoNew] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [typeIntegrations, setTypeIntegrations] = useState();

  const [error, setError] = useState('');
  const dispatch = useDispatch();

  function handlerSiteName(e) {
    setSiteName(e.target.value);
  }

  function handleStepOne() {
    setStepTwo();
    setStepTwoNew();
    setStepOne(true);
    setSuccess();
  }

  function handleStepTwo() {
    if (siteName) {
      const data = {
        name: siteName,
      };
      setStepOne(false);
      setStepTwoNew(true);
      setStepThree(false);

      dispatch(postWebsite(data));
    } else {
      setError('You have to enter a name for the website');
      return;
    }
  }

  function handleStepThree(type) {
    setTypeIntegrations(type);
    dispatch(fetchGAViewIds());
    if (siteName) {
      setStepOne(false);
      setStepTwoNew(false);
      setStepThree(true);

    } else {
      setError('You have to enter a name for the website');
      return;
    }
  }

  function handlesuccess() {
    setStepTwo();
    setStepOne();
    setSuccess(true);
  }

  let text = site?.tracking_script;

  function copyToClipboard(text) {
    const ta = document.createElement('textarea');
    ta.innerText = text;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand('copy');
    ta.remove();
    handlesuccess();
  }

  function handleLater() {
    handlesuccess();
    setCopied(false);
  }

  function Next() {
    copyToClipboard(text);
    setCopied(true);
  }

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
        handleOpenAddOff();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [success, handleOpenAddOff]);

  const types = [
    {name: 'Tracking Script',
      details: 'Easiest to Setup',
      img: 'type front'},
    {name: 'Nginx',
      details: '+10% More Companies Tracked',
      img: 'type ng'},
  ];
  
  return (
    <>
      {stepOne && (
        <Step className={edit ? 'edit' : ''}>
          {!edit && (
            <TextLightExtraSmallBold className='steps'>
              {website('stepOne')}
            </TextLightExtraSmallBold>
          )}
          <Popup>
            <ModalContent>    
              <FlexWrapper className="content title">
                <TitleSmallBlack>{edit ? website('editWebsite') : website('newWebsite')}</TitleSmallBlack>    
              </FlexWrapper>
              <FlexWrapperStart className="content">
                <Warn />
                <TextLight>{website('siteName')}</TextLight>
              </FlexWrapperStart>
              <form>
                <label>
                  <TextLight>{website('name')}</TextLight>
                </label>
                <TextField defaultValue={siteName} onChange={handlerSiteName} />
                <TextAccentSmall>{error}</TextAccentSmall>
                <BtnWrap> 
                  <InlineLinkBold to={header ? '/' : '/websites'} onClick={handleOpenAddOff}>
                    {website('cancel')}
                  </InlineLinkBold>
                  {edit ? (
                    <AccentButton onClick={handleUpdate(item.id, siteName)}>
                      {website('save')}
                    </AccentButton>
                  ) : (
                    <AccentButton onClick={handleStepTwo}>
                      {website('next')}
                    </AccentButton>
                  )}
                </BtnWrap>
              </form>
            </ModalContent>
          </Popup>
        </Step>
      )}
      {stepTwo && !edit && (
        <Step>
          <TextLightExtraSmallBold className='steps'>
            {website('stepTwo')}
          </TextLightExtraSmallBold>
          <Popup>
            <ModalContent>
              <FlexWrapper className="content">
                <Arrow onClick={handleStepOne} />
                <TitleSmallBlack>{website('newWebsite')}</TitleSmallBlack>
              </FlexWrapper>
              <FlexWrapperStart className="content info">
                <Warn />
                <TextLight>{website('newWebsite2')}</TextLight>
              </FlexWrapperStart>
              <form>
                <Text defaultValue={text} />
                <BtnWrap>
                  {edit ? (
                    <OutlinedButton onClick={handleOpenAddOff}>
                      {website('cancel')}
                    </OutlinedButton>
                  ) : (
                    <SecondaryBtnHigh onClick={handleLater}>
                      {website('later')}
                    </SecondaryBtnHigh>
                  )}
                  
                  <AccentButton onClick={Next}>{website('copy')}</AccentButton>
                </BtnWrap>
              </form>
            </ModalContent>
          </Popup>
        </Step>
      )}
      {stepTwoNew && !edit && (
        <Step>
          <TextLightExtraSmallBold className='steps'>
            {website('stepTwo')}
          </TextLightExtraSmallBold>
          <Popup className='full'>
            <ModalContent>
              <FlexWrapper className="content">
                <BackIcon onClick={handleStepOne} />
                <TitleBlack className='integrate'>{website('howIntegrate')}</TitleBlack>
              </FlexWrapper>
              <Imgs>
                {types.map((type, idx) => (
                  <Type key={idx} onClick={() => handleStepThree(type) }>
                    <div className={type.img}></div>
                    <TextBlack>{type.name}</TextBlack>
                    <TextBlackSmallThin className='sm'>{type.details}</TextBlackSmallThin>
                  </Type>
                ))}            
              </Imgs>
            </ModalContent>
          </Popup>
        </Step>  
      )}
      {stepThree && !edit && (
        <Step>
          <Integration 
            handleShowOff={handleOpenAddOff} 
            websites={true} 
            header={header}
            website_id={site?.id} 
            typeIntegrations={typeIntegrations}/>
        </Step>
      
      )}
      {success && (
        <Result>
          {website('success')}
          {copied ?
            (' ' + website('copied')) : null }     
        </Result>
      )}
    </>
  );
}

AddWebsite.propTypes = {
  handleOpenAddOff: func,
  edit: bool, 
  item: node, 
  handleUpdate: func, 
  site: object,
  header: bool
};
