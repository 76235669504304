import { number, object, string, array, any, bool, func } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VisitIcon } from 'assets/images/visit.svg';
import { ReactComponent as CompanyIcon } from 'assets/images/company_detail.svg';
import { ReactComponent as ContactsIcon } from 'assets/images/contact_details.svg';
import { ReactComponent as NotesIcon } from 'assets/images/notes.svg';
import Accordion from './Accordion';
import VisitDetails from './VisitDetails/VisitDetails';
import Company from './CompanyInfo/Company';
import Contacts from './Contacts/Contacts';
import Notes from './Notes/Notes';
import { Loading } from 'base/styled';
import { isStatusSucceed } from 'base/utils';
import { useState } from 'react';

const AccordionWrap = styled.div`
  padding: 10px 0px;
  .mjUKK:nth-child(3){
    position: relative;
    overflow: visible;
  }
  .d-none {
    display: none
  }
`;

const tableLoading = {
  height: '100px'
};

export default function DetailAccordion({

  leadTime,
  leadCount,
  date,
  leadSource,
  companyInfo,
  visitsInfo,
  contactsInfo,
  notesInfo,
  leadToShow,
  detailsStatus,
  integrations,
  contactDisabled,
  FetchContacts,
  enabled
}) {
  const { t: homeT } = useTranslation('home');
  const [active, setActive] = useState(false);

  function activeAll(e) {
    e.stopPropagation();
    setActive(!active);
  }

  const details = [
    {
      title: homeT('visitDeatils'),
      icon: <VisitIcon />,
      active: true,
      className: 'visites',
      content: isStatusSucceed(detailsStatus) ? (
        <VisitDetails
          date={date}
          leadCount={leadCount}
          leadTime={leadTime}
          leadSource={leadSource}
          visitsInfo={visitsInfo}
          active={active}
          setActive={setActive}
          activeAll={activeAll}
        />
      ) : (
        <Loading style={tableLoading} />
      ),
    },
    {
      title: homeT('companyInfo'),
      active: false,
      className: 'contacts company',
      icon: <CompanyIcon />,
      content: companyInfo && (
        <Company
          companyInfo={companyInfo}
          leadToShow={leadToShow}
        />
      ),
    },
    {
      title: homeT('contacts'),
      icon: <ContactsIcon />,
      className: contactDisabled == '1' ? 'd-none' : 'contacts',
      active: false,
      content: (
        <Contacts
          id={leadToShow?.id}
          contactsInfo={contactsInfo}
          companyInfo={companyInfo}
          integrations={integrations}
          FetchContacts={FetchContacts}
          enabled={enabled}
        />
      )
    },
    {
      title: homeT('notes'),
      icon: <NotesIcon />,
      active: false,
      content: <Notes notesInfo={notesInfo} leadToShow={leadToShow} />,
    },
  ];

  function renderDetals(item, idx) {
    return (
      <Accordion
        key={idx}
        title={item.title}
        allactive={item.active}
        icon={item.icon}
        className={item.className}
        activeItem={active}   
        activeAll={activeAll}
      >
        { item.content }
      </Accordion>

    );
  }

  return <AccordionWrap>{details.map(renderDetals)}</AccordionWrap>;
}

DetailAccordion.propTypes = {
  leadName: string,
  leadLocation: string,
  leadLogo: string,
  leadTime: string,
  leadCount: number,
  date: string,
  leadSource: string,
  companyInfo: object,
  visitsInfo: array,
  contactsInfo: any,
  notesInfo: any,
  leadToShow: any,
  active: bool,
  detailsStatus: string,
  integrations: any,
  contactDisabled: any,
  FetchContacts: func,
  enabled: any
};
