import {
  Block,
  TitleTwo,
  BlockMargin,
  FilledButton,
  OutlinedButton,
  TextBlackThin,
  TextBlackBig,
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import img from 'assets/images/discount.svg';
import styled from 'styled-components';

const Buttons = styled.div`
  display: flex;
  padding: 20px;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
`;

const Box = styled(Block)`
  margin: 0 auto;
  flex-direction: column;
  padding: 100px 30px 20px;
  background:#fff url(${img}) 49% 11% no-repeat;
  h2 {
    max-width: 360px;
  }
  p span {
    font-weight: 400;
    font-size: 14px;
  }
  button {
    font-weight: 500;
  }
`;

const Button = styled(OutlinedButton)`
  border: none;
  margin-top: 10px
`;

const Content = styled.div`
  p {
    max-width: 360px;
    line-height: 23px;
    text-align: center;
  }
`;

export default function Discount({ PostDeactivate, coupon, handleDiscount }) {
  const { t: companyT } = useTranslation('company');

  return (
    <Box>
      <TitleTwo>{companyT('wait')}</TitleTwo>
      <Content>
        <TextBlackThin>{coupon ? companyT('discountContentCoupon') : companyT('discountContent')}</TextBlackThin>
      </Content>
      <Buttons>
        <BlockMargin>
          {coupon ? <TextBlackBig><span>{companyT('use_code')}</span>{companyT('plain')}</TextBlackBig> :
            <FilledButton onClick={handleDiscount}>{companyT('accept')}</FilledButton>
          }
        </BlockMargin>
        <BlockMargin>
          <Button type="reset" onClick={PostDeactivate}>
            Deactivate anyway
          </Button>
        </BlockMargin>
      </Buttons>
    </Box>
  );
}

Discount.propTypes = {
  PostDeactivate: func,
  coupon: bool,
  handleDiscount: func
};
