import { bool } from 'prop-types';
import React from 'react';

const Responsive = React.createContext({
  isMobile: false,
  isTablet: false,
  details: true,
  impersonate: false,
  setImpersonate: () => {},
  isImpersonate: false,
  setIsPersonate: () => {},
  setDetails: () => {},
  navbar: bool,
  setNavbar: () => {},
  isTrial: false,
  setIsTrial: () => {},
  sunset: false,
  setSunset : () => {},
  hitLimit: false,
  setHitLimit: () => {},
  isLiteFree: false,
  setIsLiteFree: () => {},
  role: null,
  setRole: () => {},
  active: true,
  setActive: () => {},
  showNewConversion: false,
  setShowNewConversion: () => {}
});

export default Responsive;