import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';
import Cookies from 'universal-cookie';
import { Block, Loading, GradientButton } from 'base/styled';
import styled from 'styled-components';
import TooltipDark from 'base/components/TooltipDark';
import NoStatystic from './NoStatystic';
import network from 'base/network';

const Box = styled(Block)`
  padding: 13px 12px;
  min-width: 18%;
  button {
    margin-top: 13px;
  }
`;

const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0rem;
  color: ${(props) => props.theme.colors.lightText};
  margin: 0 10px;
`;

const TextLight = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0rem;
  color: ${(props) => props.theme.colors.lightText};
  margin: 0 10px;
`;

const TextCompanies = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 23px;
  color: ${(props) => props.theme.colors.accentGrey};
  letter-spacing: 0rem;
  text-align: left;
  width: 95px;
  margin: 10px 0 10px 8px;
`;

const NewBox = styled.div`
  .opacity {
    opacity: 0;
    transition: .7s
  }
  .scale {
    transition: .3s
    transform: scale3d(1.1, 1.1, 1.1);
  }
  @-webkit-keyframes bounce {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        -webkit-transform: translate3d(0, 200px, 0);
        transform: translate3d(0, 200px, 0);
    }
}
`;

const New = styled.div`
  background: ${(props) => props.theme.colors.yellow};
  color: #000;
  padding: 2px 8px;
  width: 48px;
  border-radius: 10px;
  margin-left: auto;
  margin-top: 8px;
  p {
    margin: 0;
    font-size: 0.75rem;
    white-space: nowrap;
    font-weight: 500;
  }
`;

const Right = styled.div`
  @media (max-width:768px) {
    width: 80%;
    text-align: right;
  }
`;

const Tooltip = styled(TooltipDark)`
  left: -3px;
`;

const TextHuge = styled(Text)`
  font-size: 3.5rem;
`;

const Margin = styled.div`
  margin: 0 auto;
`;

const Number = styled.p`
  font-weight: 500;
  text-align: left;
  font-size: 52px;
  line-height: 6rem;
  color: ${(props) => props.theme.colors.accentGrey};
  margin: 5px 0;
`;

const Loader = styled(Loading)`
  width: 106%;
  height: 100%;
  margin-top: 0;
`;

export default function Companies({ id }) {
  const { t: homeT } = useTranslation('home');
  const [show, setShow] = useState(false);
  const [fade, setFade] = useState(true);
  const [summ, setSum] = useState({ unique_leads: 1});
  const [stamp, setStamp] = useState();
  const [status, setStatus] = useState('loading');

  async function repeatRequest() {
    let sec = 2000;

    try {
      const response = await network.get('api/dashboard_stats/summary', { 
        params: 
        {website_id: id, 
          previous_summary_stats_load: stamp
        }});
      if (response.status === 200) {
        setSum(response.data.results);
        setStatus('success');
        return response;
      } else {
        throw new Error("Response status not 200");
      }
    } catch (error) {
      sec *= 2;
      setTimeout(repeatRequest, sec);
    }
  }

  useEffect(() => {
    repeatRequest();
    /* eslint-disable */
  }, [id])
   /* eslint-enable */

  useEffect(() => {
    setShow(false);
    setFade(true);
  }, [status]);

  useEffect(() => {
    const timerIn = setTimeout(() => {
      setShow(true);
    }, 3000);
    const fade = setTimeout(() => {
      setFade(false);
    }, 3400);
    const timerOut = setTimeout(() => {
      setShow(false);
    }, 13000);
    return () => clearTimeout(timerIn, timerOut, fade);
  }, [status, summ]);

  useEffect(() => {
    const cookies = new Cookies();
    if(summ?.prev_summary_timestamp){
      cookies.set('stats', summ?.prev_summary_timestamp, { path: '/' });    
      setStamp(cookies.get('stats'));
    }
  }, [summ, stamp]);

  return (
    <Box>
      {status === 'loading' ? (
        <Loader />
      ) : (
        <>
          <Tooltip text={homeT('tooltipSummary')} />
          {summ?.unique_leads == 0 ? (
            <Margin>
              <NoStatystic />
            </Margin> 
          ) : (
            <>
              <div>
                {summ?.unique_leads &&
                <Number
                  style={
                    (summ?.unique_leads <= 100 && { fontSize: '72px' }) ||
                    (summ?.unique_leads > 100 &&
                      summ?.unique_leads <= 1000 && { fontSize: '53px' }) ||
                    (summ?.unique_leads > 1000 &&
                      summ?.unique_leads <= 10000 && { fontSize: '45px' }) ||
                    (summ?.unique_leads > 10000 && { fontSize: '30px' })
                  }

                >
                  {summ?.unique_leads}
                </Number>}
                {summ?.available_leads_count > 99990 ? (
                  <TextHuge>∞</TextHuge>
                ) : (
                  <Text>{summ?.available_leads_count}</Text>
                )}

                <TextLight>{homeT('remain')}</TextLight>
              </div>
              <Right>
                <NewBox>
                  <New className={show ? (fade ? 'scale' : '') : 'opacity'}>
                    <p>+ {summ?.new_leads}</p>
                  </New>
                </NewBox>
                <TextCompanies>{homeT('uniqueCompanies')}</TextCompanies>
                <a href="/account">
                  <GradientButton>{homeT('addMore')}</GradientButton>
                </a>
              </Right>
            </>
          )}
        </>
      )}
    </Box>
  );
}

Companies.propTypes = {
  id: number
};
