import styled from 'styled-components';

const TextDarkBig = styled.p`
  font-family: Montserrat;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0rem;
  color: ${props => props.theme.colors.dark};
`;

export default TextDarkBig;
