import { useState } from 'react';
import styled from 'styled-components';
import network from 'base/network';
import { ReactComponent as Switch } from 'assets/images/switcher.svg';

const SwitchBox = styled.div`
   display: flex; 
   p {
    margin: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    display: none;
     &.classic {
      color: #FFD9C3;
     }
     &.rise {
      color: #FF6A16;
     }
   }
   
   .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 60px;
    height: 28px;
    background: #FFD9C3;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
    padding-left: 11%;
  }
  .right {
    text-align: right;
    padding-left: 63%;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 4px;
    left: 0px;
    padding: 1px 4px;
    width: 20px;
    height: 20px;
    transition: 0.2s;
    background: #FF6A16;
    box-shadow: 0 2px 12px rgb(0 0 0 / 13%);
    border-radius: 13.5px;
    p {
      margin: 4px 0;
    }
  }
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(92%);
    transform: translateX(-100%);
  }
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
  @media (max-width: 768px) {
    padding: 20px 0 0 40px;
    p {
      display: block;
    }
  }
`;

export default function Switcher() {

  const [rise, setRise] = useState(true);

  const handleToggle = () => {
    network.post('/api/user_profile/old_interface');
    setRise(!rise);
    const url = process.env.REACT_APP_API_PATH + `/interface/classic`;
    window.open(url, '_self');
  };

  return (
    <SwitchBox>
      <p className='classic'>Classic</p>
      <input
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
        checked={rise}
        onChange={handleToggle}
      />
      <label
        className={rise ? "react-switch-label" : "react-switch-label right"}
        htmlFor={`react-switch-new`}
      >
        
        <div className={`react-switch-button`}>{rise ? <Switch /> : ''}</div>
      </label>
      <p className='rise'>Rise</p>
    </SwitchBox>
  );
}
  