import { any, bool, func } from 'prop-types';
import {
  AccentButton,
  ButtonsWrap,
  SecondaryBtnHigh,
  TextBlackBig,
  TextBlackThin,
} from 'base/styled';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import network from 'base/network';

const Content = styled.div`
  padding: 10px 10px 10px 20px;
  button {
    margin-top: 20px;
  }
`;

export default function Archive({handleShowModalOff, handleSuccess, leadsMarked, setResult, archived, setId}) {
  const { t: popupT } = useTranslation('popup');
  const { t: rootT } = useTranslation('');
  
  function handleSubmit() {
    network.post('/api/leads/archive', {
      archived: !archived,
      selected_ids: leadsMarked.join(),
    });
    setResult(popupT('archived'));
    handleSuccess();
    setId();
    handleShowModalOff();
  }

  return (
    <form>
      <TextBlackBig style={{textAlign: "center"}}>{rootT('archive')}</TextBlackBig>
      <Content>
        <TextBlackThin>{archived ? popupT('unArchiveTo') : popupT('archiveTo')}</TextBlackThin>
        <ButtonsWrap>
          <SecondaryBtnHigh onClick={handleShowModalOff}>
            {rootT('cancel')}
          </SecondaryBtnHigh>
          <AccentButton onClick={handleSubmit}>{archived ? rootT('unarchive') : rootT('archive')}</AccentButton>
        </ButtonsWrap>
      </Content>
    </form>
  );
}

Archive.propTypes = {
  handleShowModalOff: func,
  handleSuccess: func,
  leadsMarked: any,
  setResult: func,
  archived: bool,
  setId: func
};
