import styled from 'styled-components';

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  &.wrapped{
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  }

`;

export default FlexWrapper;
