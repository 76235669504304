import { useState } from 'react';
import styled from 'styled-components';
import { string, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ThemeButton,
  Block,
  Overflow
} from 'base/styled';
import { ReactComponent as Sugar } from 'assets/images/sugar.svg';
import Account from './Account';
import Lead from './Lead';
import Opportunity from './Opportunity';

const Wrap = styled.div`
  position: relative;
`;

const PopupBtn = styled(Block)`
  margin: -2% auto 0;
  padding: 10px 0;
  width: 190px;
`;

const ModalBtn = styled.div`
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 25;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  a {
    font-size: .75rem;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 15px;
  }
`;

export default function Index({ id, leadName, website }) {
  const { t: homeT } = useTranslation('home');
  const [openHubspot, setOpenHubspot] = useState(false);
  const [modal, setModal] = useState();
  const [account, setAccount] = useState();
  const [lead, setLead] = useState();
  const [opportunity, setOpportunity] = useState();
  
  function handleAccount() {
    setModal(true);
    setAccount(true);
    setOpenHubspot(false);
  }

  function handleAccountOff() {
    setModal(false);
    setAccount(false);
  }

  function handleLead() {
    setModal(true);
    setLead(true);
    setOpenHubspot(false);
  }

  function handleLeadOff() {
    setModal(false);
    setLead(false);
  }

  function handleOpportunity() {
    setModal(true);
    setOpportunity(true);
    setOpenHubspot(false);
  }

  function handleOpportunityOff() {
    setModal(false);
    setOpportunity(false);
  }
  
  return (
    <Wrap>
      <ThemeButton onClick={() => setOpenHubspot(true)}>
        <Sugar />
        {homeT('sendSugar')}
      </ThemeButton>
      {openHubspot && (
        <>
          <Modal onClick={() => setOpenHubspot(false)}></Modal>
          <ModalBtn>
            <PopupBtn>
              <Links>
                <a onClick={handleAccount}>{homeT('createAccount')}</a>
                <a onClick={handleLead}>{homeT('createLeadBtn')}</a>
                <a onClick={handleOpportunity}>{homeT('createOpportunity')}</a>
              </Links>
            </PopupBtn>
          </ModalBtn>
        </>
      )}
      {modal && account && (
        <Modal>
          <Overflow onClick={handleAccountOff}></Overflow>
          <Account handleFormEmailOff={handleAccountOff} id={id} leadName={leadName} />
        </Modal>
      )}
      {modal && lead && (
        <Modal>
          <Overflow onClick={handleLeadOff}></Overflow>
          <Lead handleFormOff={handleLeadOff} id={id} leadName={leadName} website={website} />
        </Modal>
      )}
      {modal && opportunity && (
        <Modal>
          <Overflow onClick={handleOpportunityOff}></Overflow>
          <Opportunity handleFormOff={handleOpportunityOff} id={id} leadName={leadName} />
        </Modal>
      )}
    </Wrap>
  );
}

Index.propTypes = {
  id: number,
  leadName: string,
  website: string
};
