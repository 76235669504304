import styled from 'styled-components';

const ThemeLink = styled.a`
  font-family: ${props => props.theme.fonts.main};
  font-weight: 600;
  font-size: .75rem;
  white-space: nowrap;
  text-decoration: none;
  padding: 6px 16px;
  display: flex;
  border-radius: 24px;
  border: none;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  box-shadow: 0px 2px 12px 0px #00000021;
  background-color: transparent;
  svg {
    margin-right: 10px;
  }

`;

export default ThemeLink;
