import styled from 'styled-components';

const Block = styled.div`
  background: ${props => props.theme.colors.bgLight};
  border-radius: 15px;
  padding: 13px 20px;
  display: flex;
  position: relative;
  z-index: auto;
  &.rounded {
    border-radius: 32px;
  }
  &.column {
    flex-direction: column;
  }
`;

export default Block;
