import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import { AccentButton, 
  Block, Flex,
  SecondaryBtnHigh, StyledSelect, TextBlack, TextBlackBig, TextGreyBold, TextLightSmall } from 'base/styled';
import { ReactComponent as Close } from 'assets/images/close.svg';
import network from 'base/network';

const Popup = styled(Block)`
  margin: 0 auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    margin: 0 auto;
  }
  button {
    height: 37px;
    margin-right: 5px;
  }
  .center {
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  form {
    width: 100%;
    text-align: left;
    .center {
      text-align: center;
      font-size: .85rem;
      line-height: 1.3rem;
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 0;
    }
    .bold {
      margin: 11px;
    }
  }
`;

const Title = styled(Flex)`
  p {
    padding: 8px 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  svg {
    margin-top: 18px;
  }
`;

const Buttons = styled.div`
  text-align: right;
  margin-top: 20px;
`;

const Notice = styled.div`
  padding: 10px;
  margin-top: 10px;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  p {
    font-size:.7rem;
    margin: 0;
    padding: 2px 0;
  }
`;

const Select = styled(StyledSelect)`
  margin-right: 0;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

export default function SugarForm({handleFormOff}) {
  const { t: homeT } = useTranslation('home');
  const [name, setName] = useState();
  const [site, setSite] = useState();
  const [password, setPassword] = useState();
  const [version, setVersion] = useState({ value: '9', label: 'Version 9' });
  const [result, setResult] = useState(false);

  function handleName(e) {
    setName(e.target.value);
  }

  function handleSite(e) {
    setSite(e.target.value);
  }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  function handleVersion(item) {
    setVersion(item);
  }

  const options = [
    { value: '9', label: 'Version 9' },
    { value: '10', label: 'Version 10' },
    { value: '11', label: 'Version 11' },
  ];

  const postAccount = async () => {
    let res = await network
      .post(`/api/sugarcrm/connect`, { 
        site_url: site,
        username: name,
        password: password,
        version: version.value
      })
      .then(({ data }) => {
        console.log(data);
        setResult(data);
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  function handleSubmit(e) {
    e.preventDefault();
    postAccount();
  }

  return (
    <Popup>
      {result ? (
        <>
          {/* <Icon /> */}
          <TextGreyBold className="center">{result}</TextGreyBold>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Title>
            <TextBlackBig>
            SugarCRM Authentication
            </TextBlackBig>
            <Close onClick={handleFormOff}/>
          </Title>
          <FormTextField
            type="text"
            label="Site URL"
            value={site}
            onChange={handleSite}
            placeholder="https://<sugarcrm_site_url>"
            required
          />
          <FormTextField
            type="text"
            label="Username"
            required
            value={name}
            onChange={handleName}
            placeholder="User name"
          />
          <FormTextField
            type='password'
            label="Password"
            value={password}
            onChange={handlePassword}
            placeholder=""
            required
          />
          <TextLightSmall>SugarCRM Rest API Version:</TextLightSmall>
          <Select
            value={version}
            //defaultValue={options[0]}
            options={options}
            onChange={handleVersion}
            styles={customStyles}
            menuPlacement="bottom"
          />
          <Flex>
            <TextLightSmall>SugarCRM Rest API Version:</TextLightSmall>
            <TextBlack className='bold'>vqapp</TextBlack>
          </Flex>
          <Notice>
            <p>Notice: You will have to add &quot;vqapp&quot; in the SugarCRM API Platform:</p>
          
            <p>1. Sign into your SugarCRM account as an admin</p>
            <p>3. Click &quot;Admin&quot; from the dropdown</p>
            <p>2. Click your profile image in the top right.</p>
            <p>4. Scroll down to &quot;Configure API Platforms&quot; (about halfway down) and click it</p>
            <p>5. Scroll down to the text entry box and input: vqapp</p>
            <p>6. Click the &quot;Add&quot; Button</p>
            <p>7. Click &quot;Save&quot; and you are done!</p>
            <p>Please connect using SugarCRM account with access to modules 
              (Accounts, Leads, Contacts, and Opportunities/RevenueLineItems).</p>
            
          </Notice>
          <Buttons>
            <SecondaryBtnHigh onClick={handleFormOff}>
              {homeT('cancel')}
            </SecondaryBtnHigh>
            <AccentButton type="submit">Connect Now</AccentButton>
          </Buttons>
        </form>
      )}
    </Popup>
  );
}

SugarForm.propTypes = {
  handleFormOff: func
};
