import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { func, string, number, object, array } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchLeadById, fetchLeadVisits, fetchLeadContacts, fetchLeadNotes } from 'store/slices/leads';
import { fetchUsers } from 'store/slices/users';
import { 
  Block, 
  FlexWrapper,  
  ImageFluid, 
  LeadAvaWrapper, 
  FlexWrapperStart,
  TextBlack, 
  TextBlackSmall, 
  TextLight, 
  AccentButton,
  Overlay,
  Modal,
  Loading,
  Flex,
  TextBlackSmallThin } from 'base/styled';
import CreatableSelect from 'react-select/creatable';
import { isStatusLoading, makeAsOptions } from 'base/utils';
//import Batch from 'base/components/Leads/Batch';
import img from 'assets/images/location.svg';
import { ReactComponent as Active } from 'assets/images/batch_active.svg';
import { ReactComponent as Youtube } from 'assets/images/youtube.svg';
import { ReactComponent as Crunch } from 'assets/images/crunch.svg';
import { ReactComponent as Twitter } from 'assets/images/twitter_logo.svg';
import { ReactComponent as Linkedin } from 'assets/images/linkedin_logo.svg';
import { ReactComponent as Back } from 'assets/images/back.svg';
import DetailAccordion from './DetailAccordion';
import Responsive from 'context/responsive';
import network from 'base/network';
import BatchBox from 'base/components/Leads/BatchBox';
import Export from 'base/components/Leads/Manage/Export';
import Assign from 'base/components/Leads/Manage/Assign';
import Done from 'base/components/Leads/Manage/Done';
import Tags from 'base/components/Leads/Manage/Tags';
import Hide from 'base/components/Leads/Manage/Hide';
import Archive from 'base/components/Leads/Manage/Archive';
import Buttons from './Buttons';
import Email from 'base/components/Leads/Manage/Email';
import { fetchIntegrations } from 'store/slices/integrations';
import { fetchAllDetails } from 'store/slices/account';

const Details = styled.div`
  width: 100%;
  position: fixed;
  padding: 2% 20%;
  background-color: #F9F9F9;
  overflow-y: auto;
  margin: 0 auto;
  top: 70px;
  height: 90%;
  right: 20px;
  z-index: 36;
  &.top {
    top: 120px;
  }
  @media (max-width: 768px) {
    width: 92%;
    padding: 40px 0;
    top: 80px;
    right: 15px;
    z-index: 9;
    p {
      line-height: 1;
    }
  }
`;

const FlexWrapperFirst = styled.div`
  width: 50%;
  padding: 0 10px;
`;

const FlexWrap = styled(FlexWrapper)`
  flex-wrap: wrap;
  padding: 10px;
  button, a {
    margin-left: 10px;
  }
`;

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 15px 8px;
  border-bottom: 1px solid ${props => props.theme.colors.greyLight};
`;

const BlockDetail = styled(Block)`
  padding: 15px 10px;
  flex-direction: column;
  .iSNfK {
    width: 49px;
  }
`;

const Info = styled.div`
  padding: 20px 7px 15px;
  border-bottom: 1px solid ${props => props.theme.colors.greyLight};
`;

const Tag = styled.div`
  padding: 2px 10px;
  text-align: center;
  background: ${props => props.theme.colors.greyLight};
  border-radius: 10px;
  p {
    margin: 0;
    white-space: nowrap;
  }
`;

const UserWrapper = styled.div`
  padding: 3.5px 10px;
  text-align: center;
  background: #E2F6FB;
  border-radius: 10px;
  white-space: nowrap;
  margin-bottom: 5px;
  p {
    margin: 0;
    white-space: nowrap;
  }
`;

const InfoBtn = styled.button`
  background: transparent;
  border: 1px dotted #BABABA;
  border-radius: 10px;
  cursor: pointer;
  p {
    margin: 0;
  }
`;

const Margin = styled.div`
  margin: 0 10px 10px 0;
  &.soc_logo {
    width: 18px
  }
`;

const Select = styled.div`
  position: absolute;
  z-index: 9;
  display: flex;
`;

const Button = styled.a`
  position: absolute;
  top: 15%;
  left: 5%;
  display: flex;
  text-decoration: none;
  p {
    margin: 0 0 0 30px
  }
  @media (max-width: 768px) {
    top: 1%;
    z-index: 9;
  }
`;

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: '#000000',
    fontSize: '.75em',
    padding: 10,
    width: 120,
    background: '#C6D5DD'
  }),
  control: () => ({
    borderRadius: '20px',
    fontSize: '.75em',
    border: '1px solid #d1d1d1',
    width: 120,
    background: '#C6D5DD'
  }),
  menu: () => ({
    background: '#C6D5DD',
    borderRadius: '20px',
    overflow: 'hidden',
    fontSize: '.75em',
    fontWeight: '600'
  })
};

const BatchWrapper = styled.div`
position: relative;
  a {
    cursor: pointer;
    svg {
      margin: 2px 0 0 10px;
    }
  }
`;

const ModalOver = styled(Modal)`
  z-index: 35;
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  z-index: 90;
`;

const Head = styled.div`
margin: 0 15px;
  p {
    margin: 0;
  }
`;

const Avatar = styled(LeadAvaWrapper)`
  margin-left: 54px;
  min-width: 47px;
`;

const Name = styled(Flex)`
  justify-content: flex-start;
`;

const Location = styled(Flex)`
padding: 10px 0 0 20px;
  background: url(${img}) no-repeat 0% 38%;
`;

const styleLoading = {
  height: '20px',
  width: '100px',
  marginTop: '0'
};

export default function Index ({
  leadName,
  leadLocation,
  leadTime,
  leadCount,
  date,
  leadSource,
  gaViewId,
  number
}) {
  const { t: homeT } = useTranslation('home');
  const [tags, setTag] = useState([
    { value: 'lead', label: 'lead' },
    { value: 'newlead', label: 'new lead' }
  ]);
  const {
    company,
    visits,
    contacts,
    notes,
    detailsStatus: detailsStatus
  } = useSelector((state) => state.leadsReducer);
  const { users } = useSelector((state) => state.usersReducer);
  const { integrations } = useSelector(
    (state) => state.integrationsReducer
  );
  const [user, setUser] = useState([]);
  const [addTag, setAddtag] = useState();
  const [addUser, setAddUser] = useState();
  const [batch, setBatch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [exp, setExport] = useState(false);
  const [asign, setAsign] = useState(false);
  const [archive, setArchive] = useState(false);
  const [hide, setHide] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tag, setTags] = useState(false);
  const [email, setEmail] = useState(false);

  const dispatch = useDispatch();
  const tagOptions = [
    { value: 'tag', label: 'tag' },
    { value: 'new lead', label: 'new lead' },
    { value: 'has contacts', label: 'has contacts' },
    { value: 'assigned', label: 'assigned' },
  ];

  const { details } = useSelector(
    (state) => state.accountReducer
  );

  useEffect(() => {
    dispatch(fetchAllDetails({ details }));
    /* eslint-disable */
  }, []);
   /* eslint-enable */

  // useEffect(() => {
  //   setId(88653);
  // });
  
  const usersOptions = makeAsOptions(users, 'id', 'full_name');

  useEffect(() => {
    if(number) {
      dispatch(fetchUsers({ users }));
      dispatch(fetchLeadById(number));
      dispatch(fetchIntegrations({ integrations }));
    } 
   
    /* eslint-disable */
  }, [dispatch, success]);
    /* eslint-enable */

  function addTagHandlerOff() {
    network.post('/api/leads/lead_tags', {
      tags: tags.join(),
      selected_ids: number,
    });
    dispatch(fetchLeadById(number));
    setAddtag();
  }

  function addUserHandlerOff() {
    network.post('/api/leads/assign', {
      user_id: user,
      selected_ids: number,
    });
    dispatch(fetchLeadById(number));
    setAddUser();
  }

  function handleShowAsign() {
    setShowModal(true);
    setAsign(true);
  }

  function handleShowTag() {
    setShowModal(true);
    setTags(true);
  }

  function tagHandler(e) {
    setTag(Array.isArray(e) ? e.map(x => x.value) : []);
    console.log(tags);
  }

  function userHandler(e) {
    setUser(e.value);
  }

  const ctx = useContext(Responsive);

  function renderTags(item, idx) {
    return (
      <Margin key={idx}>
        <Tag>
          <TextBlackSmallThin>{item.name}</TextBlackSmallThin>
        </Tag>
      </Margin>
    );
  }

  useEffect(() => {
    dispatch(fetchLeadVisits(number));
    dispatch(fetchLeadContacts(number));
    dispatch(fetchLeadNotes(number));
    /* eslint-disable */
  }, [number, dispatch])
  /* eslint-enable */

  function handleBatch() {
    setBatch(true);
  }

  function handleBatchOff() {
    setBatch();
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleShowModalOff() {
    setShowModal(false);
    setBatch(false);
    setExport(false);
    setAsign(false);
    setArchive(false);
    setHide(false);
    setTags(false);
    //setSuccess(false);
    // setShow(false);
  }

  function handleSuccess() {
    setSuccess(true);
    setBatch(false);
    setExport(false);
    setAsign(false);
    setArchive(false);
    setHide(false);
    setTags(false);
    setTimeout(() => {
      setSuccess(false);
      setShowModal(false);
      console.log(2000);
    }, 2000);
  }
 
  return (
    <>
      <Details className={(ctx.impersonate || ctx.isTrial || ctx.isLiteFree) ? 'top' : ''}>
        {batch && <Modal onClick={handleBatchOff}></Modal>}
        {showModal && (
          <ModalOver>
            <Overlay onClick={handleShowModalOff}></Overlay>
            <Popup>
              {exp && (
                <Export
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[number]}
                  gaViewId={gaViewId}
                />
              )}
              {asign && (
                <Assign
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[number]}
                />
              )}
              {archive && (
                <Archive
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[number]}
                />
              )}
              {hide && (
                <Hide
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[number]}
                />
              )}
              {tag && (
                <Tags
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[number]}
                />
              )}
              {email && (
                <Email
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[number]}
                />
              )}
              {success && <Done />}
            </Popup>
          </ModalOver>
        )}
        <Button href='/'>
          <Back />
          <TextBlack>{homeT('toDash')}</TextBlack>
        </Button>
        <BlockDetail>
          <DetailsHeader>
            <FlexWrapper>
              <Avatar>
                <ImageFluid src={company.results?.company_info.logo} />
              </Avatar>
              <Head>
                <Name>
                  <TextBlack>{company.results?.company_name}</TextBlack>
                  <BatchWrapper>
                    <a onClick={handleBatch}>
                      <Active />
                    </a>
                    {batch && (
                      <>
                        <BatchBox
                          leadsMarked={[number]}
                          setShowModal={setShowModal}
                          handleShowModal={handleShowModal}
                          handleShowModalOff={handleShowModalOff}
                          setExport={setExport}
                          setBatch={setBatch}
                          setAsign={setAsign}
                          setArchive={setArchive}
                          setHide={setHide}
                          setTags={setTags}
                          setEmail={setEmail}
                        />
                      </>
                    )}
                  </BatchWrapper>
                </Name>
                <Location>
                  <TextBlackSmallThin>{company.results?.country}</TextBlackSmallThin>
                  <FlexWrapperFirst>
                    {company.results?.company_info.social_profiles && (
                      <FlexWrapperStart>
                        {'twitter' in
                          company.results.company_info.social_profiles && (
                          <Margin>
                            <a
                              href={
                                company.results?.company_info.social_profiles
                                  .twitter
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Twitter />
                            </a>
                          </Margin>
                        )}
                        {'linkedin' in
                          company.results.company_info.social_profiles && (
                          <Margin>
                            <a
                              href={
                                company.results.company_info.social_profiles
                                  .linkedin
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Linkedin />
                            </a>
                          </Margin>
                        )}
                        {'youtube' in
                          company.results.company_info.social_profiles && (
                          <Margin className="soc_logo">
                            <a
                              href={
                                company.results.company_info.social_profiles
                                  .youtube
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Youtube />
                            </a>
                          </Margin>
                        )}
                        {'crunchbase' in
                          company.results.company_info.social_profiles && (
                          <Margin className="soc_logo">
                            <a
                              href={
                                company.results.company_info.social_profiles
                                  .crunchbase
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Crunch />
                            </a>
                          </Margin>
                        )}
                      </FlexWrapperStart>
                    )}
                  </FlexWrapperFirst>
                </Location>
              </Head>
            </FlexWrapper>
          </DetailsHeader>
          <Info>
            <FlexWrapper>
              <Flex>
                <TextBlackSmall>{homeT('assignTo')}:</TextBlackSmall>
                <FlexWrap>
                  {addUser && (
                    <Select>
                      <CreatableSelect
                        //isMulti
                        options={usersOptions}
                        onChange={userHandler}
                        styles={customStyles}
                        components={{
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null
                        }}
                      />
                      <AccentButton onClick={addUserHandlerOff}>
                        {homeT('save')}
                      </AccentButton>
                    </Select>
                  )}
                  {isStatusLoading(detailsStatus) ? (
                    <Loading style={styleLoading} />
                  ) : company.results && company.results.assigned_to ? (
                    <UserWrapper>
                      <TextBlackSmallThin>
                        {company.results.assigned_to}
                      </TextBlackSmallThin>
                    </UserWrapper>
                  ) : null}
                  <Margin>
                    <InfoBtn
                      //onClick={addUserHandler}
                      onClick={handleShowAsign}
                    >
                      <TextLight>{homeT('add')}</TextLight>
                    </InfoBtn>
                  </Margin>
                </FlexWrap>
              </Flex>
            </FlexWrapper>
            <FlexWrapper>
              <Flex>
                <TextBlackSmall>{homeT('tags')}:</TextBlackSmall>
                <FlexWrap>
                  {addTag && (
                    <Select>
                      <CreatableSelect
                        isMulti
                        options={tagOptions}
                        onChange={tagHandler}
                        styles={customStyles}
                        components={{
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null
                        }}
                      />
                      <AccentButton onClick={addTagHandlerOff}>
                        {homeT('save')}
                      </AccentButton>
                    </Select>
                  )}
                  {company.results?.tags &&
                    company.results.tags.map(renderTags)}
                  <Margin>
                    <InfoBtn
                      //onClick={addTagHandler}
                      onClick={handleShowTag}
                    >
                      <TextLight>{homeT('add')}</TextLight>
                    </InfoBtn>
                  </Margin>
                </FlexWrap>
              </Flex>
            </FlexWrapper>
          </Info>
          {integrations.length ?
            <Buttons
              id={number}
              leadName={leadName}
              website={company?.results?.company_info.website}
              integrations={integrations}
            />: null}
          <DetailAccordion
            leadName={leadName}
            leadLocation={leadLocation}
            leadTime={leadTime}
            leadCount={leadCount}
            date={date}
            leadSource={leadSource}
            companyInfo={company.results}
            visitsInfo={visits.results?.visit_details}
            contactsInfo={contacts}
            notesInfo={notes}
            leadToShow={number}
            detailsStatus={detailsStatus}
            integrations={integrations}
            contactDisabled={details?.settings?.disable_dashboard_contacts}
          />
        </BlockDetail>
      </Details>
    </>
  );
}

Index.propTypes = {
  handleShowDetailsOff: func,
  leadName: string,
  leadLocation: string,
  leadLogo: string,
  leadTime: string,
  leadCount: number,
  date: string,
  leadSource: string,
  companyInfo: object,
  visitsInfo: array,
  contactsInfo: array,
  notesInfo: array,
  gaViewId: number,
  setId: func,
  number: number
};