import { useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import styled from 'styled-components';
import {
  AccentButton,
  FlexWrapper,
  OutlinedButton,
  TextField,
  TextLight,
  TitleSmallBlack,
  ModalContent,
  TextGreyBold,
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import network from 'base/network';
import { ReactComponent as Icon } from 'assets/images/saved.svg';

const BtnWrap = styled.div`
  padding: 30px 0 0;
  text-align: right;
`;

const Form = styled.form`
  input {
    margin-bottom: 10px;
  }
`;

const Flex = styled(FlexWrapper)`
  h1 {
    text-align: center;
    margin: 0 auto;
  }
`;

const Result = styled.div`
  margin: 0 auto;
  text-align: center;
`;

export default function SendContact({ handleOpenAddOff, id, name, mail, number, job,
  contact,
  lead,
  hubspot}) {

  const { t: homeT } = useTranslation('home');
  const [userName, setUserName] = useState(name);
  const [title, setTitle] = useState(job);
  const [email, setEmail] = useState(mail);
  const [phone, setPhone] = useState(number);
  const [company, setCompany] = useState('');
  const [result, setResult] = useState(false);

  function handleUserName(e) {
    setUserName(e.value);
  }
  function handleTitle(e) {
    setTitle(e.value);
  }
  function handleEmail(e) {
    setEmail(e.value);
  }
  function handlePhone(e) {
    setPhone(e.value);
  }
  function handleCompany(e) {
    setCompany(e.value);
  }

  const postContact = async () => {
    let res = await network
      .post(`/api/sugarcrm/contact`, { 
        lead_id: id, 
        first_name: userName,
        email: email,
        phone: phone
      })
      .then(({ data }) => {
        console.log(data.message);
        setResult(data.message);
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  const postLead = async () => {
    let res = await network
      .post(`/api/sugarcrm/lead`, { 
        lead_id: id, 
        first_name: userName,
        email: email,
        phone: phone
      })
      .then(({ data }) => {
        console.log(data.message);
        setResult(data.message);
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  const postHubspot = async () => {
    let res = await network
      .post(`/api/hubspot/contact`, { 
        lead_id: id, 
        first_name: userName,
        email: email,
        phone: phone
      })
      .then(({ data }) => {
        setResult(data.message);
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if(contact) {
      postContact();
    }
    if(lead) {
      postLead();
    }
    if(hubspot) {
      postHubspot();
    }
  }

  return (
    <>
      <ModalContent>
        {result ? (
          <Result>
            <Icon />
            <TextGreyBold className="center">{result}</TextGreyBold>
          </Result>
        ) : (
          <>
            <Flex>
              {contact && (
                <TitleSmallBlack>{homeT('createContact')}</TitleSmallBlack>
              )}
              {lead && <TitleSmallBlack>{homeT('createLead')}</TitleSmallBlack>}
              {hubspot && (
                <TitleSmallBlack>{homeT('createHubspot')}</TitleSmallBlack>
              )}
            </Flex>
            <Form onSubmit={handleSubmit} className={hubspot ? 'full' : ''}>
              <label>
                <TextLight>{homeT('title')}</TextLight>
              </label>
              <TextField value={title} onChange={handleTitle} />
              <label>
                <TextLight>{homeT('name')}</TextLight>
              </label>
              <TextField value={userName} onChange={handleUserName} />
              <label>
                <TextLight>{homeT('email')}</TextLight>
              </label>
              <TextField value={email} onChange={handleEmail} />
              <label>
                <TextLight>{homeT('phone')}</TextLight>
              </label>
              <TextField value={phone} onChange={handlePhone} />
              {hubspot && (
                <>
                  <TitleSmallBlack>{homeT('association')}</TitleSmallBlack>
                  <label>
                    <TextLight>{homeT('company')}</TextLight>
                  </label>
                  <TextField value={company} onChange={handleCompany} />
                </>
              )}
              <BtnWrap>
                <OutlinedButton onClick={handleOpenAddOff}>
                  {homeT('cancel')}
                </OutlinedButton>
                <AccentButton type="submit">{homeT('send')}</AccentButton>
              </BtnWrap>
            </Form>
          </>
        )}
      </ModalContent>
    </>
  );
}

SendContact.propTypes = {
  handleOpenAddOff: func,
  name: string, 
  mail: string, 
  number: string, 
  job: string,
  contact: bool,
  lead: bool,
  hubspot: bool,
  id: number
};
