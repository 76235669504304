import styled from 'styled-components';

const OverflowSolid = styled.div`
width: 100%;
height: 100%;
background: #F1F3F4;
position: absolute;
`;

export default OverflowSolid;
