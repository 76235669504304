import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getStats
} from 'base/network/benchmark';
import { LOADING, SUCCEED, FAILURE } from 'store/CONSTANTS';

export const initialState = {
  status: LOADING,
  statusNew: null,
  page: 1,
  statistic: null,
  errors: {},
};

export const fetchBenchmark = createAsyncThunk(
  'benchmarkSlice/fetchBenchmark',
  async ({ 
    ga_view_id,
    min_date,
    max_date }) => {
    const response = await getStats({ 
      ga_view_id,
      min_date,
      max_date});
    return response.data;
  }
);

export const benchmarkSlice = createSlice({
  name: 'behcnmarkSlice',
  initialState,
  reducers: {
    setFiltersData(state, payload) {
      state.personalizations = payload;
    },
  },
  extraReducers: {

    [fetchBenchmark.pending]: (state) => {
      state.status = LOADING;
    },
    [fetchBenchmark.fulfilled]: (state, action) => {
      state.statistic = action.payload;
      state.status = SUCCEED;
    },
    [fetchBenchmark.rejected]: (state) => {
      state.status = FAILURE;
    },

  },
});

export const { nextPage, setFiltersData } = benchmarkSlice.actions;

export default benchmarkSlice.reducer;
