import styled from 'styled-components';

const Result = styled.div`
  border-radius: 11.5px;
  padding: 12px;
  color: #FEFDFD;
  background: rgba(50, 49, 49, 0.7);
  position: fixed;
  bottom: 50px;
  left: 48%;
  z-index: 9;
  font-size: .75rem;
  font-weight: 400;
  transition: .5s;
  max-width: 200px;
  text-align: center;
  a {
    text-decoration: underline;
    font-weight: 600;
    color: inherit;
  }
  &.error {
    bottom: 30vh;
    left: 40%;
  }
`;

export default Result;