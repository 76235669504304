import { useState, useEffect } from 'react';
import { func, object, string, array, any, bool } from 'prop-types';
import {
  BlockMargin,
  Flex,
  FlexWrapperStartMiddle,
  StyledSelect,
  TextLight
} from 'base/styled';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import { useTranslation } from 'react-i18next';
import FormTextFieldTime from 'base/components/FormTextFieldTime';
import Location from 'base/components/Leads/Filters/Location';

const Wrapper = styled(FlexWrapperStartMiddle)`
  position: relative;
  min-height: 65px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    margin: 10px 0;
    .hsusKX {
      width: 96%;
      min-width: auto;
      margin: 10px 10px 0 0;
    }
  }
`;

const BlockMarginRelative = styled(BlockMargin)`
  position: relative;
    input[type='number'] {
    margin-right: 10px;
  }
  .dvLDJB .mdl .checkbox-item {
    left: -15px;
    top: -9px;
  }
  .node>label {
    margin-left: 15px;
  }
  .dvLDJB .mdl .checkbox-item:checked:after {
    width: 12px;
    height: 12px;
    top: 2.5px;
    left: 2px;
  }
  .dvLDJB .react-dropdown-tree-select .dropdown .dropdown-trigger {
    background: #fff;
  }
`;

const TextLightAbsolute = styled(TextLight)`
  position: absolute;
  top: 65px;
  font-weight: 400!important;
`;

function Cascad({
  handlerType,
  handlerEqual,
  handlerValue,
  styles,
  gic,
  naics,
  setIsSelectValue,
  edit,
  item,
  setNewVisitor,
  ValueFromHandler,
  ValueToHandler,
}) {
  const { t: homeT } = useTranslation('home');
  const [selected, setSelected] = useState(edit ? item.key : '');
  const [input, setInput] = useState(true);
  const [multi, setMulti] = useState(false);
  const [time, setTime] = useState(false);
  const [tree, setTree] = useState(false);
  const [location, setLocation] = useState(false);
  const [between, setBetween] = useState(false);
  const [operator, setOperator] = useState(edit ? item.operator : '');

  function changeSelectOptionHandler(event, item) {
    setSelected(event.value);
    handlerType(event, item);
  }

  const data = [
    { value: 'employees', label: 'Approximate Employees' },
    { value: 'company_name', label: 'Company Name' },
    { value: 'industry', label: 'NAICS Industry' },
    { value: 'gic_industry', label: 'GIC Industry' },
    { value: 'full_contact_city', label: 'Headquarters City' },
    { value: 'full_contact_region', label: 'Headquarters Region' },
    { value: 'full_contact_country', label: 'Headquarters Country' },
    { value: 'keyword', label: 'Keyword' },
    { value: 'visits', label: 'Number of Page Views' },
    { value: 'page_path', label: 'Page Path' },
    { value: 'query', label: 'Query String' },
    { value: 'country', label: 'Visitor Location Country' },
    { value: 'region', label: 'Visitor Location State/Province' },
    { value: 'city', label: 'Visitor Location City' },
    { value: 'tag', label: 'Tag' },
    { value: 'new_visitor', label: 'New Visitor' },
  ];

  const equalsOptions = [
    { value: '=' || 'eq', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: 'contains', label: 'contains' },
    { value: 'does not contain', label: 'does not contain' },
  ];

  const equalsOptionsBetween = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: '>', label: 'greater than' },
    { value: '>=', label: 'greater than or equal to' },
    { value: '<', label: 'less than' },
    { value: '<=', label: 'less than or equal to' },
    { value: 'between', label: 'between'}
  ];

  const equal = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
  ];

  const boolOptions = [
    { value: 'yes', label: 'yes' },
    { value: 'no', label: 'no' },
  ];

  const [type, setType] = useState(equalsOptions);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (
      selected === 'company_name' ||
      selected === 'keyword' ||
      selected === 'page_path' ||
      selected === 'source' ||
      selected === 'tag' ||
      selected === 'query'
    ) {
      setInput(true);
      setTime(false);
      setType(equalsOptions);
      setIsSelectValue(false);
    }  else if (
      selected === 'city' ||
      selected === 'country' ||
      selected === 'region' ||
      selected === 'full_contact_city' ||
      selected === 'full_contact_country' ||
      selected === 'full_contact_region' 
    ) {
      setInput(false);
      setTime(false);
      setType(equalsOptions);
      setIsSelectValue(false);
      setLocation(true);
      //setTree(true);
      setIsSelectValue(true);
      //setValue(naics);
    } else if (selected === 'employees' || selected === 'visits') {
      setType(equalsOptionsBetween);
      setTime(false);
      setInput(true);
      setIsSelectValue(false);
    } else if (selected === 'gic_industry') {
      setTime(false);
      setInput(false);
      setTree(false);
      setIsSelectValue(true);
      setType(equal);
      setValue(gic);
    } else if (selected === 'industry') {
      setTime(false);
      setInput(false);
      setTree(true);
      setIsSelectValue(true);
      setType(equal);
      setValue(naics);
    } else if (selected === 'new_visitor') {
      setInput(false);
      setType(boolOptions);
      setNewVisitor(true);
    } else {
      setInput(false);
      setMulti(false);
      setTree(false);
      setIsSelectValue(false);
    }
    console.log(multi);
    /* eslint-disable */
  }, [item, selected]);
  /* eslint-enable */

  useEffect(() => {
    if (operator == 'between') {
      setBetween(true);
    }
  }, [operator]);

  const handleOperator = (e) => {
    handlerEqual(e, item);
    setOperator(e.value);
    if(e.value == 'between') {
      setBetween(true);
    } else {
      setBetween(false);
    }
  };

  return (
    <Wrapper>
      <BlockMargin className='form-field'>
        <StyledSelect
          placeholder="Filter Type"
          options={data}
          onChange={(e) => changeSelectOptionHandler(e, item)}
          styles={styles}
          defaultValue={data.find((el) => el.value === item.key)}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
        />
      </BlockMargin>
      <BlockMargin className='form-field'>
        {
          <StyledSelect
            options={type}
            onChange={handleOperator}
            styles={styles}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            value={type.find((el) => el.value === item.operator)}
          />
        }
      </BlockMargin >
      {selected !== 'new_visitor' && (
        <BlockMarginRelative className='form-field'>
          {input ? (
            <>
              {time ? (
                <>
                  <FormTextFieldTime label={homeT('min')} />
                  <FormTextFieldTime label={homeT('sec')} />
                </>
              ) : between ? (
                <Flex>
                  <FormTextField
                    type="number"
                    onChange={(e) => ValueFromHandler(e, item)}
                    value={item.multiple_filter?.value_from}
                    placeholder={homeT('from')}
                  />
                  <FormTextField
                    type="number"
                    onChange={(e) => ValueToHandler(e, item)}
                    value={item.multiple_filter?.value_to}
                    placeholder={homeT('to')}
                  />
                </Flex>
              ) : (
                <FormTextField
                  type="text"
                  onChange={(e) => handlerValue(e, item)}
                  value={item.value}
                  label={homeT('value')}
                  defaultValue={item.value}
                />
              )}
              {!time && !between && (
                <TextLightAbsolute>{homeT('useCommas')}</TextLightAbsolute>
              )}
            </>
          ) : tree ? (
            <StyledSelect
              isClearable
              isSearchable
              options={value}
              isMulti
              styles={styles}
              onChange={(e) => handlerValue(e, item)}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              defaultValue={
                edit
                  ? []
                    .concat(...naics.map(({ options }) => options || []))
                    .filter(({ value }) =>
                      item.value.split(',').includes(value)
                    )
                  : ''
              }
            />
          ) : location ? (
            <Location
              data={data}
              styles={styles}
              selected={selected}
              item={item}
              handlerValue={handlerValue}
              edit={edit}
              defaultValue={edit ? item.value : ''} 
            />
          ) : (
            <>
              <StyledSelect
                options={value}
                isMulti
                styles={styles}
                onChange={(e) => handlerValue(e, item)}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
                defaultValue={
                  edit
                    ? gic?.filter(({ value }) =>
                      item.value.split(',').includes(value)
                    )
                    : ''
                }
              />
            </>
          )}
        </BlockMarginRelative>
      )}
    </Wrapper>
  );
}
  
export default Cascad;

Cascad.propTypes = {
  handlerType: func, 
  handlerEqual: func,
  handlerValue: func, 
  filterValue: string,
  styles: object,
  filterType: string, 
  setNewVisitor: func,
  setIsSelectValue: func,
  edit: bool,
  item: any,
  gic: array,
  naics: array,
  ValueFromHandler: any,
  ValueToHandler: any,
};