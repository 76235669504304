
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {Block, FilledLink, TextBlackdark, TextDarkBig } from "base/styled";
import img from 'assets/images/request_bg.svg';
import { func } from 'prop-types';

const Wrapper = styled.div`
  font-family: Montserrat;
  width: 800px;
  padding: 0 30px;
  .center {
    margin: 0 auto;
    text-align: center;
  }
  .title {
    width: 30%;
    padding: 15px 0 30px;
  }
  button.submit {
    display: block;
    margin: 40px auto 10px;
  }
`;

const BoxLeft = styled.div`
  padding: 130px 10px 0;
  width: 45%;
  background: url(${img}) 40% 10% / 165px no-repeat;
`;

const BoxRight = styled.div`
  padding: 15px 15px 15px 30px;
  width: 65%;
  background: #E2F0FF;
`;

const Text = styled(TextBlackdark)`
  font-size: 0.875rem;
  line-height: 1.4rem;
  text-align: left;
`;

const Video = styled.div`
  width: 259px;
  height: 146px;
  border-radius: 9px;
  overflow: hidden;
`;

const Link = styled(FilledLink)`
  display: block;
  width: fit-content;
  margin: 50px auto 10px;
`;

export default function RequestPaid() {
  const { t: personalizationT } = useTranslation('personalization');

  return (
    <Wrapper>
      <TextDarkBig className="center title">
        {personalizationT('requestTitle')}
      </TextDarkBig>
      <Block>
        <BoxLeft>
          <Text>{personalizationT('featurePaid')}</Text>
          <Text>{personalizationT('featurePaidAllow')}</Text>
        </BoxLeft>
        <BoxRight>
          <Text>{personalizationT('asExample')}</Text>
          <Video>
            <iframe width="259" height="146" src="https://www.youtube.com/embed/bJ4f5Jj8a-o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </Video>
        </BoxRight>
      
      </Block>
      <Link href='/account' className='submit'>
        {personalizationT('purchase')}
      </Link>
    </Wrapper>
  );
}

RequestPaid.propTypes = {
  handleShowOff: func
};