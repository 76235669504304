import Select from 'react-select';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  @media (min-width: 1120px) {
    min-width: 140px;
  }
  @media (min-width: 1320px) {
    min-width: 190px;
  }
  @media (min-width: 1420px) {
    min-width: 240px;
  }
  font-family: ${props => props.theme.fonts.main};
  margin: 0 15px 0 0;
  .css-1pahdxg-control,
  .css-yk16xz-control {
    border: none;
    box-shadow: 0px 1px 5px rgba(87, 85, 85, 0.24);
    border-radius: 8px;
    color: ${props => props.theme.colors.darkGrey};
    font-size: 12px;
    min-height: 35px;
  }
  .css-1uccc91-singleValue,
  .css-1wa3eu0-placeholder {
    font-size: 14px;
    color: ${props => props.theme.colors.darkGrey};
  }
  .check {
    margin-right: 10px;
  }
  @media (max-width: 1319px) {
    min-width: 120px;
    width: 100%;
  }
  @media (max-width: 768px) {
    min-width: 120px;
    width: 100%;
  }
  
`;

export default StyledSelect;