import { Link } from 'react-router-dom';
import styled from 'styled-components';

const InlineLinkBold = styled(Link)`
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  font-weight: 600;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.accentRed}
  }
`;

export default InlineLinkBold;
