import FormTextField from 'base/components/FormTextField';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { func } from 'prop-types';
import { FilledButton, TextAccentThin, TextBlackBig } from 'base/styled';
import styled from 'styled-components';
import network from 'base/network';

const Form = styled.form`
  padding: 30px!important;
  @media (max-width: 768px) {
    padding: 10px!important;
  }
`;

const FormField = styled.div`
  width: 100%;
  padding: 10px 20px;
  @media (max-width: 768px) {
    padding: 2px;
  }
`;

const Button = styled.div`
  text-align: right;
`;

export default function PasswordForm({setSuccess}) {
  const { t: profileT } = useTranslation('profileForm');
  const { t: generalT } = useTranslation();
  
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirm, setConfirm] = useState();
  const [error, setError] = useState();
  const [result, setResult] = useState();

  function setCurrentPasswordHandler(event) {
    setCurrentPassword(event.target.value);
  }
  function setNewPasswordHandler(event) {
    setNewPassword(event.target.value);
  }

  function setConfirmHandler(event) {
    setConfirm(event.target.value);
  }

  const putPassword = async () => {

    let res = await network
      .put(`/api/user_profile/reset_password`, { 
        password: newPassword,
        current_password: currentPassword
      })
      .then(({ data }) => {
        console.log(data);
        setResult(data.message);
        setSuccess(true);
        return data;
      })
      .catch((error) => {
        console.log(error);
        setResult("Invalid current password.");
      }
      );
    console.log(res);
  };

  function submitHandler(event) {
    event.preventDefault();
    switch(true) {
    case currentPassword.length <= 0 : setError('Please enter current password');
      break;
    case newPassword.length <= 0 : setError('Please enter new password');
      break;
    case newPassword !== confirm  : setError('Password is not the same');
      break;
    }
    
    if(validateForm()) {
      console.log(confirm);
      putPassword();
      setError();
      
      setConfirm();
      setNewPassword();
      setCurrentPassword();
    } else {
      console.log('error');
    }
  }

  function validateForm() {

    return (
      currentPassword.length > 0 &&
      newPassword.length > 0 &&
      newPassword === confirm 
    );
  }

  return (
    <Form onSubmit={submitHandler}>
      <FormField>
        <FormTextField
          value={currentPassword}
          label={profileT('currentPassword')}
          onChange={setCurrentPasswordHandler}
          type='password'
        />
      </FormField>
      <FormField>
        <FormTextField
          value={newPassword}
          label={profileT('newPassword')}
          onChange={setNewPasswordHandler}
          type='password'
        />
      </FormField>
      <FormField>
        <FormTextField
          value={confirm}
          label={profileT('confirmPassword')}
          onChange={setConfirmHandler}
          type='password'
        />
      </FormField>
      <TextAccentThin>{error}</TextAccentThin> 
      <Button>
        <FilledButton type={'submit'}>{generalT('saveChanges')}</FilledButton>
      </Button>
      <TextBlackBig>{result}</TextBlackBig>
    </Form>
  );
}

PasswordForm.propTypes = {
  setSuccess: func
};
