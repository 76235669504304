import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Responsive from 'context/responsive';
import { Block, Flex, Modal, Overflow, SecondaryBtn, 
  TextBlackSmall, TextBlackSmallThin } from 'base/styled';
import AccentButtonLink from 'base/styled/AccentButtonLink';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    z-index: 90;
    background: linear-gradient(270deg, #FFDE88 -0.03%, #FFF0C9 98.43%);
    color: #FEFDFD;
    width: 100%;
    height: 60px;
    padding: 4px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 3px 0;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }
    button {
      font-size: 8px;
      font-weight: 600;
      padding: 8px 16px;
      height: 25px;
      margin: 5px 10px;
    }
`;

const Popup = styled(Block)`
  margin: 0 auto;
  padding: 0;
  height: 90%;
  width: 70%;
  overflow: hidden;
  .lmtWIHO_gkbTeeyuvoJC {
  min-width: auto!important;
 }
`;

export default function Topbar() {
  const { t: generalT } = useTranslation();
  const [popup, setPopup] = useState(false);

  const handlePopup = () => {
    setPopup(true);
  };

  const handlePopupOff = () => {
    setPopup(false);
  };

  const ctx = useContext(Responsive);

  const diff = Date.parse(ctx.isTrial) - Date.now();
 
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

  const date= new Date(ctx.hitLimit).toUTCString().split( " ");
 
  return (
    <Wrapper>
      <div>
        {ctx.isLiteFree ? (
          <TextBlackSmall>{generalT('freeVersion')}</TextBlackSmall>
        ) : ctx.hitLimit ? (
          <TextBlackSmall>
            {generalT('hitLimit')}
            {date[1] + ' ' + date[2] + ' ' + date[3]}
          </TextBlackSmall>
        ) : ctx.sunset ? (null) : (
          <TextBlackSmall>
            {generalT('have')}
            {days}
            {generalT('remain')}
          </TextBlackSmall>
        )}
        <TextBlackSmallThin>{ctx.hitLimit ? generalT('hitLimitUpgrade') :
          ctx.sunset ? (generalT('sunset')) : generalT('upgrade')}</TextBlackSmallThin>
      </div>
      <Flex>
        {!ctx.sunset && <AccentButtonLink className='small' href="/account">{generalT('upgradeBtn')}</AccentButtonLink>}
        {!ctx.hitLimit && !ctx.sunset && <SecondaryBtn onClick={handlePopup}>{generalT('book')}</SecondaryBtn>}
      </Flex>
      {popup && (
        <Modal>
          <Overflow onClick={handlePopupOff}></Overflow>
          <Popup>
            <iframe
              src="https://calendly.com/visitor-queue"
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </Popup>
        </Modal>
      )}
    </Wrapper>
  );
}
