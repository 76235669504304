import styled from 'styled-components';

const BlockMargin = styled.div`
  margin-right: 15px;
  max-width: 240px;
  &.relative {
    position: relative;
  }
  p {
    font-weight: 700;
  }
  label {
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 25px;
    color: ${props => props.theme.colors.lightGrey};
  }
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    margin: 5px 0;
  }
  @media (max-width: 1119px) {
    min-width: 120px;
    width: 100%
  }
  &.form-field {
    @media (min-width: 1400px) {
      min-width: 240px;
    }
    @media (max-width: 1399px) {
      min-width: 190px;
      width: 100%
    }
    @media (max-width: 1250px) {
      min-width: 140px;
      width: 100%
    }
    @media (max-width: 1069px) {
      min-width: 120px;
      width: 100%
    }
  }
  &.form-input {
    width: 90%;
  }
`;

export default BlockMargin;
