import network from './index';

export const getUsersEdit = () => {
  return network.get('/api/user_profile');
};

export const putUser = ({ first_name, last_name }) => {
  return network.put('/api/user_profile/general', {
    last_name,
    first_name
  });
};

export const putUserEmail = ({ email, current_password }) => {
  return network.put('/api/user_profile/email', {
    email,
    current_password
  });
};

export const putUserPassword = ({ password, current_password }) => {
  return network.put('/api/user_profile/reset_password', {
    password,
    current_password
  });
};
