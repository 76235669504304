import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { any } from 'prop-types';
 
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const Layout = ({ children }) => {
  return (
    <>
      <Elements stripe={stripePromise}>{children}</Elements>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: any
};
