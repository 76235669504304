import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  TextBlack,
  TextBlackSmallThin,
} from 'base/styled';
import { ReactComponent as Image } from 'assets/images/denied.svg';

const Content = styled.div`
  padding-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    margin: 20px;
  }
  p {
    max-width: 510px;
    text-align: center;
    line-height: 23px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 50px;
  }

`;

export default function Denied() {
  const { t: homeT } = useTranslation('home');

  return (
    <Content>
    
      <Image />
      <TextBlack>{homeT('denied')}</TextBlack>
      <TextBlackSmallThin>{homeT('deniedText')}</TextBlackSmallThin>
  
    </Content>
  );
}
