import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { string, func, number } from 'prop-types';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import { AccentButton, Block, Flex, SecondaryBtnHigh, TextBlackBig, TextGreyBold } from 'base/styled';
import { ReactComponent as Icon } from 'assets/images/saved.svg';
import { ReactComponent as Close } from 'assets/images/close.svg';
import network from 'base/network';

const Popup = styled(Block)`
  margin: 0 auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    margin: 0 auto;
  }
  button {
    height: 37px;
    margin-right: 5px;
  }
  .center {
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  form {
    width: 100%;
    text-align: left;
    .center {
      text-align: center;
      font-size: .85rem;
      line-height: 1.3rem;
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 0;
    }
    p {
      padding: 10px 0;
    }
  }
`;

const Title = styled(Flex)`
  p {
    padding: 8px 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  svg {
    margin-top: 18px;
  }
`;

const Buttons = styled.div`
  text-align: right;
  margin-top: 20px;
`;

export default function Account({handleFormEmailOff, id, leadName}) {
  const { t: homeT } = useTranslation('home');
  const [name, setName] = useState(leadName);
  const [result, setResult] = useState(false);

  function handleName(e) {
    setName(e.target.value);
  }

  const postAccount = async () => {
    let res = await network
      .post(`/api/sugarcrm/account`, { lead_id: id, lead_name: leadName})
      .then(({ data }) => {
        console.log(data.message);
        setResult(data.message);
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  function handleSubmit(e) {
    e.preventDefault();
    postAccount();
  }

  return (
    <Popup>
      {result ? (
        <>
          <Icon />
          <TextGreyBold className="center">{result}</TextGreyBold>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Title>
            <TextBlackBig>
              {homeT('createAccountSugar')}
            </TextBlackBig>
            <Close onClick={handleFormEmailOff}/>
          </Title>
          <FormTextField
            type="text"
            label="Name"
            value={name}
            onChange={handleName}
            placeholder="Name"
          />
          <Buttons>
            <SecondaryBtnHigh onClick={handleFormEmailOff}>
              {homeT('cancel')}
            </SecondaryBtnHigh>
            <AccentButton type="submit">{homeT('createAccount')}</AccentButton>
          </Buttons>
        </form>
      )}
    </Popup>
  );
}

Account.propTypes = {
  handleFormEmailOff: func,
  id: number,
  leadName: string
};
