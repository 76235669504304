import FormField from "./FormField";
import { func, string } from 'prop-types';
import {
  CountryDropdown,
  RegionDropdown
} from 'react-country-region-selector';
import { Flex } from "base/styled";

const styles = {
  fontSize: 12,
  fontFamily: 'Montserrat',
  padding: '0 6px',
  border: '1px solid #C6D5DD',
  borderRadius: '8px',
  height: '31px',
  color: '#2c2c2c',
  transition: 'all 0.2s',
  width: '50%',
  'option': {
    color: '#2c2c2c',
    fontSize: '14px',
    fontWeight: '300',
    fontFamily: 'Montserrat, sans-serif',
  },
};

const BillingDetailsFields = ({ country, region, handleCountry, handleRegion, name, handleName }) => {
  return (
    <>
      <Flex>
        <FormField name="name" type="text" placeholder="Company Name" required value={name} onChange={handleName} />
      </Flex>
      <Flex>
        <FormField name="address" type="text" placeholder="Address" required className="right"/>
        <FormField name="city" type="text" placeholder="City" required />
      </Flex>
      <Flex>
        <CountryDropdown
          value={country}
          onChange={handleCountry}
          defaultOptionLabel="Country"
          style={styles}
          className="right select"
        />
        <RegionDropdown
          country={country}
          value={region}
          onChange={handleRegion}
          defaultOptionLabel="Region"
          blankOptionLabel="Region"
          style={styles}
        />
      </Flex>
    </>
  );
};

export default BillingDetailsFields;

BillingDetailsFields.propTypes = {
  country: string, 
  region: string, 
  handleCountry: func, 
  handleRegion: func, 
  name: string, 
  handleName: func
};

