import { useContext, useEffect, useState } from 'react';
import img from 'assets/images/check.svg';
import { StyledSelect } from 'base/styled';
import network from 'base/network';
import { any, array } from 'prop-types';
import Responsive from 'context/responsive';

export default function TimeSelect({timezone, details}) {

  const [timez, setTimez] = useState();
  const [choosedTimez, setChoodesTimez] = useState();
  const ctx = useContext(Responsive);
  const styles = {
    option: (provided, state) => ({
      ...provided,
      color: '#000000',
      fontSize: '12px',
      fontFamily: 'Montserrat',
      background: state.isSelected
        ? `url(${img}) no-repeat 4% center`
        : 'inherit',
      borderBottom: state.isSelected ? '1px solid #EDEDED' : '0',
      '&:hover': {
        background: `#FFF9F9`,
      },
      padding: '10px 5px 10px 30px',
      width: 300,
    }),
    control: () => ({
      display: 'flex',
      fontFamily: 'Montserrat',
      borderRadius: '8px',
      boxShadow: '0px 1px 5px rgba(87, 85, 85, 0.24)',
      border: 'none',
      width: ctx.isMobile ? '100%' : 300,
      background: `#FFF`,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 320,
      fontSize: '12px',
      fontFamily: 'Montserrat',
      color: state.selectProps.menuColor,
      padding: 0,
      overflowX: 'hidden',
      display: 'inline-block',
      '@media only screen and (max-width: 1200px)': {
        padding: 0,
      },
    }),
    dropdownIndicator: styles => ({ 
      ...styles, 
      color: '#1D1D1D', 
    })
  };
  
  useEffect(() => {
    setTimez(timezone?.map((d) => {
      return {
        value: d[1],
        label: d[0]
      };
    }));
    setChoodesTimez(timez?.find(el => el.value === details?.settings?.time_zone));
    /* eslint-disable */
  }, [timezone, details]);
    /* eslint-enable */

  function handleSelectChange(e) {
    setChoodesTimez(timez?.find(el => el.value === e.value));
    network.put('/api/account/timezone', {time_zone: e.value});
  }

  return (
    <>
      <StyledSelect
        className='time-select'
        options={timez}
        onChange={handleSelectChange}
        value={choosedTimez}
        defaultInputValue={ choosedTimez ? choosedTimez : details?.settings?.time_zone }
        styles={styles}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator: () => null
        }}
      />
    </>
  );
}

TimeSelect.propTypes = {
  timezone: array, 
  details: any
};