import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  padding: 0 15px;
  @media (max-width: 768px) {
    padding: 0;
  }
  .topbar {
    padding-top: 10px;
  }
  &.open {
    height: 130vh;
    overflow: hidden;
  }
  h1 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
  }
`;

export default Main;
