import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccount, putAccount } from 'base/network/sugarCrm';
import { LOADING, SUCCEED, FAILURE } from 'store/CONSTANTS';

export const initialState = {
  status: LOADING,
  account: [],
  page: 1,
  per_page: 100,
  errors: {}
};

export const updateAccount = createAsyncThunk(
  'sugarslice/updateAccount',
  async ({id}, {rejectWithValue}) => {
    try {
      const response = await putAccount(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data, error);    }
  }
);

export const fetchAccount = createAsyncThunk(
  'sugarslice/fetchAccount',
  async ({ ...rest}) => {
    const response = await getAccount({ page: 1, ...rest });
    return  response.data;
  });

export const sugarslice = createSlice({
  name: 'sugarslice',
  initialState,
  reducers: {
    nextPage (state) {
      state.page += 1;
    }
  },
  extraReducers: {

    [fetchAccount.pending]: (state) => {
      state.status = LOADING;
    },
    [fetchAccount.fulfilled]: (state, action) => {
      state.account = action.payload.results;
      state.page = 1;
      state.status = SUCCEED;
    },
    [fetchAccount.rejected]: (state) => {
      state.status = FAILURE;
    },

    [updateAccount.pending]: (state) => {
      state.status = LOADING;
    },
    [updateAccount.fulfilled]: (state, action) => {
      state.status = SUCCEED;
      state.account =  action.payload.results;
    },
    [updateAccount.rejected]: (state, { payload }) => {
      state.status = FAILURE;
      state.errors = payload?.message;
    },


  }
});

export const { nextPage, setFiltersData } = sugarslice.actions;

export default sugarslice.reducer;
