import { any, bool, func } from 'prop-types';
import {
  AccentButton,
  ButtonsWrap,
  SecondaryBtnHigh,
  TextBlackBig,
  TextBlackThin,
} from 'base/styled';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import network from 'base/network';

const Content = styled.div`
  padding: 10px 10px 10px 20px;
  button {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    p {
      max-width: fit-content;
    } 
  }
`;

export default function Hide({leadsMarked, handleSuccess, setResult,
  handleShowModalOff, setId, details, handleShowDetailsOff, setLeadsHide, one}) {
  const { t: popupT } = useTranslation('popup');
  const { t: rootT } = useTranslation('');
   
  function handleSubmit() {
    network.post('/api/leads/hide', {
      isp: false,
      selected_ids: leadsMarked.join(),
    });
    setResult(popupT('hidden'));
    setId();
    handleShowModalOff();
    handleSuccess();
    if(details) {
      handleShowDetailsOff();
    }
    if(one) {
      setLeadsHide(prev => [...prev, ...leadsMarked]);
    }
  }

  function handleSubmitIsp() {
    network.post('/api/leads/hide', {
      isp: true,
      selected_ids: leadsMarked.join(),
    });

    setResult(popupT('hidden'));
    setId();
    handleSuccess();
    handleShowModalOff();
    if(details) {
      handleShowDetailsOff();
    }
    if(one) {
      setLeadsHide(prev => [...prev, ...leadsMarked]);
    }
  }

  return (
    <>
      <form>
        <TextBlackBig style={{ textAlign: 'center' }}>
          {rootT('hide')}
        </TextBlackBig>
        <Content>
          <TextBlackThin>{popupT('hideTo')}</TextBlackThin>
          <ButtonsWrap>
            <SecondaryBtnHigh onClick={handleSubmit}>
              {popupT('hideCancel')}
            </SecondaryBtnHigh>
            <AccentButton onClick={handleSubmitIsp}>
              {popupT('hideConfirm')}
            </AccentButton>
          </ButtonsWrap>
        </Content>
      </form>
    </>
  );
}

Hide.propTypes = {
  handleShowModalOff: func,
  handleSuccess: func,
  leadsMarked: any.isRequired,
  setSuccess: func,
  setResult: func,
  setId: func, 
  details: any,
  handleShowDetailsOff: func, 
  setLeadsHide: func, 
  one: bool
};
