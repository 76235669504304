import { useState, useRef } from 'react';
import { any, func } from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  AccentButton,
  ButtonsWrap,
  SecondaryBtnHigh,
  TextBlackSmall,
  TextLabel,
} from 'base/styled';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import FormTextField from 'base/components/FormTextField';
import network from 'base/network';
import Success from 'base/components/Success';

const Textarea = styled.textarea`
  border: ${props => props.theme.borders.textInput};
  font-family: 'Montserrat', sans-serif;
  font-size: .75rem;
  color: ${props => props.theme.colors.black};
  border-radius: 8px;
  height: 80px;
  width: 100%;
  padding: 10px;
  margin: 0;
`;

const Message = styled.div`
  margin-top: 20px;
`;

const Box = styled(Success)`
  padding: 0;
`;

export default function Email({handleShowModalOff, leadsMarked, setResult}) {
  const { t: homeT } = useTranslation('home');

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(homeT(''));
  const [success, setSuccess] = useState(false);

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  function handleMessage(e) {
    setMessage(e.target.value);
    console.log(message);
  }
  const postEmail = async (token) => {

    let res = await network
      .post('/api/leads/email', { 
        response: token,
        recipient_address: email, 
        selected_ids: leadsMarked.join(),
        message: message,
      })
      .then(({ data }) => {
        console.log(data);
        //history.push('/');
        setResult(data.results);
        setSuccess();
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  const captchaRef = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    console.log(token);
    captchaRef.current.reset();
    postEmail(token);
    handleShowModalOff();
  }

  return (
    <>
      {success ? (
        <Box text={success} />
      ) : (
        <form onSubmit={handleSubmit}>
          <TextBlackSmall className="center">
            {homeT('whoEmail')}
          </TextBlackSmall>
          <FormTextField
            label="Email"
            value={email}
            onChange={handleEmail}
            placeholder="Enter email"
            multiple pattern="^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},*[\W]*)+$"
          />
          <TextLabel>{homeT('useCommasEmail')}</TextLabel>
          <Message>
            <TextLabel>{homeT('message')}</TextLabel>
            <Textarea value={message} onChange={handleMessage} label="label" placeholder={homeT('enterMessage')}/>
          </Message>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={captchaRef}
          />
          <ButtonsWrap>
            <SecondaryBtnHigh onClick={handleShowModalOff}>
              {homeT('cancel')}
            </SecondaryBtnHigh>
            <AccentButton type="submit">{homeT('send')}</AccentButton>
          </ButtonsWrap>
        </form>
      )}
    </>
  );
}

Email.propTypes = {
  handleShowModalOff: func,
  setResult: func,
  leadsMarked: any
};
