import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOwner } from 'store/slices/hubspot';
import { makeAsOptions} from 'base/utils';
import { func, number, string } from 'prop-types';
import network from 'base/network';
import styled from 'styled-components';
import {
  AccentButton,
  Block,
  StyledSelect,
  TextGreyBold,
  Flex,
  TextBlackBig,
  TextGreyThin,
  TextLightSmall,
  SecondaryBtnHigh,
} from 'base/styled';
import { ReactComponent as Icon } from 'assets/images/saved.svg';
import { ReactComponent as Close } from 'assets/images/close.svg';
import img from 'assets/images/company_detail.svg';

const Popup = styled(Block)`
  margin: 0 auto;
  width: 400px;
  flex-direction: column;
  justify-content: center;
  svg {
    margin: 0 auto;
  }
  button {
    height: 37px;
    margin-right: 5px;
  }
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  form {
    width: 100%;
    text-align: left;
    .center {
      text-align: center;
      font-size: .85rem;
      line-height: 1.3rem;
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 0;
    }
  }
  .center {
    text-align: center;
    font-size: .85rem;
    line-height: 1.3rem;
    width: 80%;
    margin: 0 auto;
    padding: 20px 0 0;
  }
`;

const Buttons = styled.div`
  text-align: center;
  margin: 20px auto;
  button {
    margin-right: 15px;
  }
`;

const Title = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  .title {
    padding: 8px 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  svg {
    margin: 0;
    margin-top: 18px;
  }
`;

const Name = styled.div`
  background: url(${img}) no-repeat  0 0px / 20px;
  padding-left: 35px;
  margin-bottom: 35px;
  p {
    padding: 0;
  }
`;

const Select = styled(StyledSelect)`
  margin-right: 0;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

export default function Company({handleFormOff, leadName, id}) {
  const { t: homeT } = useTranslation('home');
  const [user, setUser] = useState();
  const [result, setResult] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const { owners, status } = useSelector((state) => state.hubspotReducer);
  
  useEffect(() => {
    dispatch(fetchOwner({ owners, status }));
    /* eslint-disable */
  }, [dispatch]);
    /* eslint-enable */

  const options = makeAsOptions(owners, 'id', 'email');
  
  const postEmail = async () => {

    await network
      .post(`/api/hubspot/company`, { 
        lead_id: id,
        user_id: user.value})
      .then(({ data }) => {
        setResult(data.message || data.error);
        if(data.error) {
          setError(true);
        }
        return data;
      })
      .catch((error) => {
        setResult(res.error);
        console.log(error);
      });
  };
  
  function handleSubmit(e) {
    e.preventDefault();
    postEmail();
  }

  function handleAccount(item) {
    setUser(item);
  }

  return (
    <>
      {result == false ? (
        <Popup>
          <form onSubmit={handleSubmit}>
            <Title>
              <TextBlackBig className="title">
                {homeT('createCompanyHubspot')}
              </TextBlackBig>
              <Close onClick={handleFormOff}/>
            </Title>
            <TextLightSmall>{homeT('companyName')}:</TextLightSmall>
            <Name>
              <TextGreyThin>{leadName}</TextGreyThin>
            </Name>
            <TextLightSmall>{homeT('selectOwner')}:</TextLightSmall>     
            <Select
              value={user}
              options={options}
              onChange={handleAccount}
              styles={customStyles}
              menuPlacement="bottom"
            />
            <Buttons>
              <SecondaryBtnHigh onClick={handleFormOff}>
                {homeT('cancel')}
              </SecondaryBtnHigh>
              <AccentButton type="submit">{homeT('createCompany')}</AccentButton>
            </Buttons>
          </form>
        </Popup>
      ) : <Popup>
        {error != true && <Icon />}
        <TextGreyBold className="center">{result}</TextGreyBold>
      </Popup>}
    </>
  );
}

Company.propTypes = {
  handleFormOff: func,
  leadName: string,
  id: number
};
