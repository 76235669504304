import {
  Block,
  FilledLink,
  TextBlackThin,
  TitleTwo,
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';
import alert from 'assets/images/alert_red.svg';
import notification from 'assets/images/notification_red.svg';
import styled from 'styled-components';

const Box = styled(Block)`
  margin: 0 auto;
  flex-direction: column;
  padding: 40px 35px;
    @media (max-width: 768px) {
    width: 90%;
  }
`;

const Error = styled.div`
  background: #FFEADE url(${alert}) 4% 20% no-repeat;
  padding: 2px 30px 10px 60px;
  text-align: left;
  border: 1px solid #FF3317;
  border-radius: 8px;
  margin: 10px 0
  p {
    max-width: 360px;
    line-height: 23px;
  }
`;

const Warning = styled(Error)`
  background: #FFFAEE url(${notification}) 2% 16% no-repeat;
  border: none;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.13);
`;

const Margin = styled.div`
  margin: 10px auto 0;
  padding-top: 20px;
`;

export default function HitLimit({amount}) {
  const { t: generalT } = useTranslation();
  const { t: homeT } = useTranslation('home');

  return (
    <Box>
      <TitleTwo>{homeT('important')}</TitleTwo>
      <Error>
        <TextBlackThin>{homeT('warning')}</TextBlackThin>
      </Error>
      <Warning>
        <TextBlackThin>{homeT('missing')} <b>{amount}</b> {homeT('leadsSince')}</TextBlackThin>
      </Warning>
      <Margin>
        <FilledLink href="/account">{generalT('upgradeBtn')}</FilledLink>
      </Margin> 
    </Box>
  );
}

HitLimit.propTypes = {
  amount: number
};
  