import { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { any, bool, func, array } from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/images/arrow.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as Edit } from 'assets/images/edit.svg';
import { ReactComponent as Delete } from 'assets/images/delete_dark.svg';
import {
  Block,
  Tab,
  Tabs,
  FlexWrapper,
  Flex,
  TextLight,
  SecondaryBtnHigh,
  Result
} from 'base/styled';
import AccountFilterForm from './AccountFilterForm';
import ContactFilter from './ContactFilter';
import TableFilterUser from './TableFilterUser';
import TooltipDark from 'base/components/TooltipDark';
import TableFilterAccount from './TableFilterAccount';
import UserFilterForm from './UserFilterForm';
import Responsive from 'context/responsive';
import network from 'base/network';
import { fetchUsers } from 'store/slices/users';
import { useDispatch, useSelector } from 'react-redux';

const Button = styled.a`
  cursor: pointer;
`;

const ContactButton = styled.a`
  cursor: pointer;
  font-size: .875rem;
  font-weight: 500;
  margin-left: 20px
`;

const BlockFilter = styled(Block)`
  margin: 20px 0;
  padding: 20px;
`;

const BlockFilterContact = styled(BlockFilter)`
  justify-content: space-between;
`;

const FlexWrapperTop = styled(Flex)`
  padding-top: 40px;
  position: relative;
  button {
    position: absolute;
    right: 0;
    top: -50px;
  }
  @media (max-width: 768px) {
    padding-top: 15px;
    justify-content: center;
    margin-bottom: 15px;
    button {
      width: 100%;
      margin: 30px 0 10px;
    }
    p {
      line-height: .45
    }
  }
`;

const Text = styled.div`
  line-height: 20px;
  font-size: 12px;
  position: relative;
  padding-left: 25px
  .tooltip {
    position: absolute;
    left: auto;
    left: 2px;
    top: 0;
    span {
      top: -28px;
    }
  }
`;

const Content = styled.div`
  ${(props) => (props?.active ? '' : 'display:none')}
`;

const TabLink = styled(Tab)`
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 8px 2px;
  border-bottom: 2px solid ${(props) => (props?.active ? props => props.theme.colors.accentRed : 'transparent')};
  color: ${(props) => (props?.active ? props => props.theme.colors.black : props => props.theme.colors.grey)};
`;

const CloseBtn = styled.div`
  position: absolute;
  bottom: 10px;
  left: 49%
  svg {
    transform: rotate(-90deg);
  }
`;

export default function TabsFilter({ handleFilter, contactDisabled, setAddFilter, addFilter, gic,
  naics }) {
  const { t: homeT } = useTranslation('home');
 
  const [filterAccount, setFilterAccount] = useState(false);
  const [filterUser, setFilterUser] = useState(false);
  const [filterContact, setFilterContact] = useState(false);
  const [contactArray, setContactArray] = useState();
  const [filterAccountEdit, setFilterAccountEdit] = useState();
  const [filterUserEdit, setFilterUserEdit] = useState();
  const [edit, setEdit] = useState(false);
  const [allContact, setAllContact] = useState([]);
  const { users} = useSelector((state) => state.usersReducer);
  const [userList, setUserList] = useState();
  const [result, setResult] = useState(false);
  const dispatch = useDispatch();
 
  const ctx = useContext(Responsive);
  const { user } = useSelector((state) => state.userReducer);

  const [active, setActive] = useState(user?.role == 'admin' ? 0 : 1);
  useEffect(() => {
    dispatch(fetchUsers({ users }));
    if(user?.role !== 'admin') {
      network.get(`/api/account/users`)
        .then(({ data }) => {
          setUserList(data.results);
        })
        .catch((error) => console.log(error));
    }
  /* eslint-disable */
  }, [dispatch, user]);
  /* eslint-enable */

  function handleTab(e) {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  }
 
  function handleFilterAccount(id) {
    if (edit) {
      setFilterAccountEdit(id);
      setFilterAccount(true);
      setEdit(true);
    } else {
      setFilterAccount(true);
      setFilterAccountEdit();
      setEdit();
    }
  }

  function handleFilterUser(id) {
    if (edit && filterUserEdit) {
      setFilterUserEdit(id);
      setFilterUser(true);
      setEdit(true);
      console.log(filterUser);
    } else {
      setFilterUser(true);
      setFilterUserEdit();
      setEdit();
    }
  }

  function handleFilterContact() {
    setFilterContact(true);
  }

  function handleFilterContactDelete() {
    const formdata = {
      contact_filters_attributes: allContact.map((el) => ({
        id: el.id,
        key: el.key,
        value: false,
      })),
    };
    network.put('/api/contact_filters', formdata);

    setContactArray();
  }

  const handleFilterAccountOff = () => {
    setFilterAccount(false);
    setFilterAccountEdit(false);
    setEdit(false);
  };

  function handleFilterUserOff() {
    setFilterUser(false);
    setFilterUserEdit();
    setEdit(false);
  }

  function handleFilterContactOff() {
    setFilterContact(false);
    getCFilters();
  }

  const getCFilters = async () => {
    let res = await network
      .get(`/api/contact_filters`, { params: {} })
      .then(({ data }) => data)
      .catch((error) => console.log(error));
    const results = res?.results?.filter((item) => {
      return item.value === true;
    });
    setContactArray(results);
    setAllContact(res?.results);
  };

  useEffect(() => {
    if(!contactDisabled) {
      getCFilters();
    }
    /* eslint-disable */
  }, [filterContact]);
  /* eslint-enable */

  const filterRef = useRef(null);
  const executeScroll = () => filterRef.current.scrollIntoView();

  useEffect(() => {
    
    const timerIn = setTimeout(() => {
      if(result) {
        setResult(false);
      }
    }, 2200);
    return () => clearTimeout(timerIn);
  }, [result]);

  return (
    <>
      <FlexWrapper ref={filterRef}>
        {!ctx.isMobile ? (
          <Button>
            <CloseIcon onClick={handleFilter} />
          </Button>
        ) : null}
        {(ctx.impersonate?.role !== 'manager' && ctx.role != 'manager') ? (
          <Tabs>
            <TabLink onClick={handleTab} active={active === 0} id={0}>
              {homeT('accountFilters')}
            </TabLink>

            <TabLink onClick={handleTab} active={active === 1} id={1}>
              {homeT('userFilters')}
            </TabLink>

            {contactDisabled != '1' && (
              <TabLink onClick={handleTab} active={active === 2} id={2}>
                {homeT('contactFilters')}
              </TabLink>
            )}
          </Tabs>
        ) : (
          <Tabs>
            <Tab onClick={handleTab} active={active === 1} id={1}>
              {homeT('userFilters')}
            </Tab>
          </Tabs>
        )}
      </FlexWrapper>
      <>
        <Content active={active === 0}>
          {!filterAccount && (
            <FlexWrapperTop>
           
              <SecondaryBtnHigh onClick={handleFilterAccount}>
                {homeT('addAccountFilters')}
              </SecondaryBtnHigh>
            </FlexWrapperTop>
          )}
          {filterAccount && (
            <BlockFilter>
              <AccountFilterForm
                handle={handleFilterAccountOff}
                filterAccountEdit={filterAccountEdit}
                edit={edit}
                setAddFilter={setAddFilter}
                addFilter={addFilter}
                setResult={setResult}
              />
            </BlockFilter>
          )}
          {!ctx.isMobile && (
            <Text>
              {homeT('whatAccountFilters')}
              <TooltipDark
                text={homeT('accountFilterTooltip')}
                className="tooltip"
              />
            </Text>
          )}
          {ctx.impersonate ? (
            ctx.impersonate.role == 'admin' && (
              <TableFilterAccount
                handleFilterAccount={handleFilterAccount}
                setEdit={setEdit}
                setFilterAccountEdit={setFilterAccountEdit}
                executeScroll={executeScroll}
                setAddFilter={setAddFilter}
                addFilter={addFilter}
              />
            )
          ) : ctx.role == 'admin' ? (
            <TableFilterAccount
              handleFilterAccount={handleFilterAccount}
              setEdit={setEdit}
              setFilterAccountEdit={setFilterAccountEdit}
              executeScroll={executeScroll}
              setAddFilter={setAddFilter}
              addFilter={addFilter}
              setResult={setResult}
            />
          ) : null}
        </Content>
        <Content active={active === 1}>
          {!filterUser && (
            <FlexWrapperTop>
           
              <SecondaryBtnHigh onClick={handleFilterUser}>
                {homeT('addUserFilters')}
              </SecondaryBtnHigh>
            </FlexWrapperTop>
          )}
          {filterUser && (
            <BlockFilter>
              <UserFilterForm
                handle={handleFilterUserOff}
                filterUserEdit={filterUserEdit}
                edit={edit}
                gic={gic}
                naics={naics}
                setAddFilter={setAddFilter}
                addFilter={addFilter}
                users={userList || users}
                setResult={setResult}
              />
            </BlockFilter>
          )}
          {!ctx.isMobile && (
            <Text>
              {homeT('whatUserFilters')}
              <TooltipDark
                text={homeT('userFiltersTooltip')}
                className="tooltip"
              />
            </Text>
          )}
          <TableFilterUser
            handleFilterUser={handleFilterUser}
            setEdit={setEdit}
            setFilterUserEdit={setFilterUserEdit}
            gic={gic}
            naics={naics}
            edit={edit}
            executeScroll={executeScroll}
            setAddFilter={setAddFilter}
            addFilter={addFilter}
            users={users}
            setResult={setResult}
          />
        </Content>
        <Content active={active === 2}>
          {(!filterContact && contactArray?.length > 0) && (
            <BlockFilterContact>
              <div>
                {contactArray &&
                  contactArray?.map((item) => (
                    <TextLight key={item.id}>
                      Has{' '}
                      {item.key === 'phone_number'
                        ? 'Phone Number'
                        : item.key.charAt(0).toUpperCase() + item.key.slice(1)}
                    </TextLight>
                  ))}
              </div>
              <Flex>
                <ContactButton onClick={handleFilterContact}>
                  <Edit />
                  {homeT('edit')}
                </ContactButton>
                {contactArray?.length > 0 && <ContactButton onClick={handleFilterContactDelete}>
                  <Delete />
                  {homeT('delete')}
                </ContactButton>}
              </Flex>
            </BlockFilterContact>
          )}
          {(filterContact || contactArray?.length < 1 || !contactArray?.length) && (
            <BlockFilter>
              <ContactFilter
                allContact={allContact}
                array={contactArray}
                handle={handleFilterContactOff}
                setResult={setResult}
              />
            </BlockFilter>
          )}
        </Content>
        <>
          {ctx.isMobile && <CloseBtn onClick={handleFilter}>
            <Close />
          </CloseBtn>}
        </>
      </>
      {result && <Result>{result}</Result>}
    </>
  );
}

TabsFilter.propTypes = {
  handleFilter: func,
  contactDisabled: any,
  setAddFilter: func,
  addFilter: bool,
  gic: array,
  naics: array,
};
