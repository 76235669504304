import styled from 'styled-components';

const Tabs = styled.div`
  overflow: hidden;
  height: 3rem;
  display: flex;
  width: 80%;
  justify-content: start;
  margin: 0 auto;
  gap: 40px
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    gap: 8px;
    height: 5.5rem;
  }
`;

export default Tabs;