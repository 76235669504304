import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { any, array, func } from 'prop-types';
import styled from 'styled-components';
import {
  BlockMargin,
  FilledButton,
  FlexWrapperStart,
  FlexWrapperStartMiddle,
  Check,
  TextAccentThin,
  SecondaryBtnHigh,
  TextLight,
  FlexWrapper
} from 'base/styled';
import { useForm } from "react-hook-form";
import network from 'base/network';

const FlexWrapperStartMiddlePadding = styled(FlexWrapperStartMiddle)`
  padding-top: 40px;
`;

const Block = styled(BlockMargin)`
  position: relative;
  @media (max-width: 768px) {
    margin: 8px 0
  }
  label {
    color: #000;
  }
`;

const FlexWrap = styled(FlexWrapperStart)`
.relative {
  position: relative;
}
  @media (max-width: 768px) {
    flex-direction: column;
  }
  p {
    padding-right: 5px;
    color: #85929b;
  }
`;

const Form = styled.form`
  width: 100%;
  padding: 10px;
  hr {
    margin-top: 20px;
    background: #C6D5DD;
    border: none;
    width: 100%;
    height: 2px;
    border-radius: 1px;
  }
`;

export default function ContactFilter({handle, allContact, setResult, array}) {
  const { t: homeT } = useTranslation('home');
  const [text, setText] = useState('');
  const {
    register,
    getValues,
    handleSubmit,
    reset
  } = useForm();
  const atLeastOne = () =>
    getValues("contact")?.length ? setText('') : setText("errorFormText");
  
  const options = [
    {name:'name', label:'Name' },
    {name:'position', label:'Position'},
    {name:'phone_number', label:'Phone Number'},
    {name:'linkedin', label: 'Linkedin'},
    {name:'twitter', label: 'Twitter'}
  ];

  const [list, setList] = useState(options?.filter(option => array?.some(item => item.key === option.name)));
  const [checkedItems, setCheckedItems] = useState(
    options?.reduce((acc, option) => ({
      ...acc,
      [option.name]: array?.map(i => i.key)?.includes(option.name) || false
    }), {})
  );

  const onSubmit = data => {
    if (getValues("contact")?.length) {
      const formdata = {
        contact_filters_attributes: allContact.map((el) => (
          {
            id: el.id,
            key: el.key,
            value: data.contact.includes(el.key)
          }
        ))
      };
      network.put('/api/contact_filters', formdata);
      setList(data?.contact.map(item => ({ name: item, 
        key: item, label: item.charAt(0).toUpperCase() + item.slice(1) })));
      setResult('Saved!');
      handle();
    }
    else {
      return;
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const optionName = e.target.value;
    setCheckedItems(prevState => ({
      ...prevState,
      [optionName]: isChecked
    }));
    if (isChecked) {
      const newOption = options.find(option => option.name === optionName);
      setList(prevList => [...prevList, newOption]);
    } else {
      setList(prevList => prevList.filter(option => option.name !== optionName));
    }
  };
  
  const handleOff = () => {
    setList([]);
    setCheckedItems(options.reduce((acc, option) => ({
      ...acc,
      [option.name]: false
    }), {}));
    reset({ contact: [] });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {text && <TextAccentThin>{text}</TextAccentThin>}
      <FlexWrapper className='wrapped'>
        {options.map((value) => (
          <Block className='relative' key={value.name}>   
            <Check
              type="checkbox"
              value={value.name}
              checked={checkedItems[value.name]}
              {...register('contact', {
                validate: atLeastOne,
              })}
              defaultChecked={array?.map(i => i.key)?.includes(value.name)}
              {...register('contact', {
                validate: atLeastOne,
              })}
              onChange={handleCheckboxChange}
            />
            <span></span>
            <label>Has {value.label}</label>
          </Block>
        ))} 
      </FlexWrapper>
      <hr />
      <FlexWrap>
        {list && list.map((e, idx) =>(
          <TextLight key={idx} >
            {'Has ' + e.label}
            {(idx < list?.length - 1) && ", "}
          </TextLight>
        ))}
      </FlexWrap>
      <FlexWrapperStartMiddlePadding>
        <BlockMargin >
          <FilledButton className={list?.length > 0 ? '' : 'disabled'} 
            type="submit">{homeT('saveFilter')}</FilledButton>
        </BlockMargin>
        {list?.length > 0 && <BlockMargin>
          <SecondaryBtnHigh onClick={handleOff}>{homeT('cancel')}</SecondaryBtnHigh>
        </BlockMargin>}
      </FlexWrapperStartMiddlePadding>
    </Form>
  );
}

ContactFilter.propTypes = {
  handle: func,
  allContact: array,
  setResult: func,
  array: any
};
