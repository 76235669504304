import styled from 'styled-components';

const FilledButton = styled.button`
  padding: 10px 16px;
  height: 37px;
  border-radius: 18px;
  font-family: ${props => props.theme.fonts.main};
  font-weight: 600;
  background-color: ${props => props.theme.colors.accentRed};
  border: none;
  color: ${props => props.theme.colors.bgLight};
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    background-color: #e1d8d7;
    color: #626262;
  }
`;

export default FilledButton;
