import styled from 'styled-components';

const AccentButtonLink = styled.a`
  font-family: inherit;
  padding: 6px 14px;
  font-size: .875rem;
  font-weight: 600;
  height: 33px;
  border-radius: 18px;
  border: 1px solid ${props => props.theme.colors.accentRed};
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  background: ${props => props.theme.colors.accentRed};
  white-space: nowrap;
  text-decoration: none;
  &.disabled {
    cursor: auto!important;
  }
  svg {
    margin-right: 8px;
  }
  &.small {
    height: 25px
    font-size: 9px;
    margin: auto;
  }
  @media (max-width: 830px) {
    svg {
      margin-right: 4px;
    }
  }
`;

export default AccentButtonLink;
