import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getLocations } from 'base/network/location';
import { LOADING, SUCCEED, FAILURE } from 'store/CONSTANTS';

export const initialState = {
  count: 0,
  statusLocation: LOADING,
  location: [],
  errors: {}
};

export const fetchLocation = createAsyncThunk(
  'locationSlice/fetchLocation',
  async (id) => {
    try {
      const response = await getLocations(id);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error; 
    }
  }
);

export const locationSlice = createSlice({
  name: 'locationSlice',
  initialState,
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count -= 1;
    },
    incrementByAmount: (state, action) => {
      state.count += action.payload;
    },
  },
  extraReducers: {
    [fetchLocation.pending]: (state) => {
      state.statusLocation = LOADING;
    },
    [fetchLocation.fulfilled]: (state, action) => {
      state.statusLocation = SUCCEED;
      state.location = action.payload?.results;
      state.errors = action.payload?.error;
    },
    [fetchLocation.rejected]: (state, action) => {
      state.statusLocation = FAILURE;
      state.errors = action.errors;
    },
  }
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = locationSlice.actions;

export default locationSlice.reducer;
