import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'assets/images/impersonate_white.svg';
import Responsive from 'context/responsive';
import network from 'base/network';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    z-index: 99;
    background: #2C2C2C;
    color: #FEFDFD;
    width: 100%;
    height: 60px;
    padding: 4px 8px;
    display: flex;
    @media (max-width: 768px) {
      height: auto;
    }
`;

const IconBlock = styled.div`
  text-align: center;
  padding: 10px 20px;
  margin-right: 20px;
  position: relative;
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: #525559;
    top: 0;
    right: 0;
  }
`;

const Text = styled.p`
  font-size: .75rem;
  font-weight: 400;
  margin: 11px 0;
`;

const Button = styled.a`
  font-size: .75rem;
  font-weight: 600;
  outline: none;
  background: none;
  color: #FEFDFD;
  cursor: pointer;
  text-decoration: underline;
  padding: 11px 10px 10px 20px;
`;

export default function Impersonate() {
  const { t: generalT } = useTranslation();

  const ctx = useContext(Responsive);

  const postImpersonateOff = async () => {
    let res = await network
      .post(`/api/users/become_myself`)
      .then(({ data }) => {
        ctx.setIsPersonate(false);
        ctx.setImpersonate(false);
        window.localStorage.removeItem('IMPERSONATE');
        window.localStorage.removeItem('USER');
        window.location.reload();
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  const handleOff = () => {
    postImpersonateOff();
  };
 
  const user = JSON.parse(window.localStorage.getItem('USER'));

  return (
    <Wrapper>
      <IconBlock>
        <Icon />
      </IconBlock>
      <Text>
        {generalT('impersonateText')}
        {user?.first_name + ' ' + user?.last_name}
      </Text>
      <Button onClick={handleOff}>{generalT('exitImpersonate')}</Button>
    </Wrapper>
  );
}
