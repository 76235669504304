import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { func, string, number, object, array, any, bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchLeadById, fetchLeadVisits, fetchLeadContacts, fetchLeadNotes } from 'store/slices/leads';
import { fetchIntegrations } from 'store/slices/integrations';
import { fetchUsers } from 'store/slices/users';
import { 
  Block, 
  FlexWrapper,  
  ImageFluid, 
  LeadAvaWrapper, 
  FlexWrapperStart,
  TextBlack, 
  TextBlackSmall, 
  TextLight, 
  Overlay,
  Modal,
  Loading,
  Flex,
  TextBlackSmallThin } from 'base/styled';
import { isStatusLoading } from 'base/utils';
//import Batch from 'base/components/Leads/Batch';
import img from 'assets/images/location.svg';
import { ReactComponent as Active } from 'assets/images/batch_active.svg';
import { ReactComponent as Arrow } from 'assets/images/arrow_right.svg';
import { ReactComponent as Youtube } from 'assets/images/youtube.svg';
import { ReactComponent as Crunch } from 'assets/images/crunch.svg';
import { ReactComponent as Twitter } from 'assets/images/twitter_logo.svg';
import { ReactComponent as Linkedin } from 'assets/images/linkedin_logo.svg';
import { ReactComponent as Delete } from 'assets/images/delete_white.svg';
import DetailAccordion from './DetailAccordion';
import Responsive from 'context/responsive';
import network from 'base/network';
import BatchBox from '../BatchBox';
import Export from '../Manage/Export';
import Assign from '../Manage/Assign';
import Tags from '../Manage/Tags';
import Hide from '../Manage/Hide';
import Archive from '../Manage/Archive';
import Buttons from './Buttons';
import Email from '../Manage/Email';

const Over = styled(Overlay)`
 z-index: 0;
`;

const Details = styled.div`
  width: 29%;
  padding: 25px 0 0 15px;
  margin-right: -15px;
  position: fixed;
  top: 45px;
  overflow-y: auto;
  height: 92%;
  right: 18px;
  z-index: 5;
  
  &.top {
    top: 85px;
    height: 84%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    top: 5px;
    right: 15px;
    z-index: 9;
    height: 90%;
    border-radius: 0 0 15px 15px;

  }
`;

const FlexWrapperFirst = styled.div`
  width: 50%;
  padding: 0 10px;
`;

const FlexWrap = styled(FlexWrapper)`
  flex-wrap: wrap;
  padding: 10px;
  button, a {
    margin-left: 10px;
  }
`;

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 15px 8px;
  border-bottom: 1px solid ${props => props.theme.colors.greyLight};
`;

const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 20px;
  &.bottom {
    @media (max-width: 768px) {
      top: auto;    bottom: 9%;
      left: 0;
      position: fixed;
      text-align: center;
      padding: 22px;
      width: 100%;
      background: #fff;
      border-radius: 0 0 18px 18px;
      &:after {
        content: '';
        display: block;
        background: #C6D5DD;
        width: 245px;
        height: 2px;
        height: 2px;
        position: absolute;
        top: 0;
        left: 24%;
      }
      svg {
        transform: rotate(-90deg)
      }
    }
  }
 
`;

const BlockDetail = styled(Block)`
  padding: 35px 10px 15px;
  box-shadow: -9px -3px 12px 0px #5ea4c22b;
  border-radius: 15px 0 0 15px;
  flex-direction: column;
  .iSNfK {
    width: 49px;
  }
  @media (max-width: 768px) {
    padding: 35px 25px 85px;
    border-radius: 0 0 15px 15px;
    position: relative;
  }
`;

const Info = styled.div`
  padding: 20px 7px 15px;
  border-bottom: 1px solid ${props => props.theme.colors.greyLight};
`;

const Tag = styled.div`
  padding: 2px 10px;
  text-align: center;
  background: #595B5B;
  border-radius: 14px;
  display: flex;
  p {
    margin: 0;
    white-space: nowrap;
    color: #fff
  }
  svg {
    margin: 1px 0 0 11px
  }
`;

const UserWrapper = styled.div`
  display: flex;
  padding: 0.5px 12px;
  text-align: center;
  background: #373737;
  border-radius: 14px;
  white-space: nowrap;
  height: 18px;
  p {
    margin: .5px 0 0;
    white-space: nowrap;
    color: #fff;
  }
  svg {
    margin: 3px 0 0 11px;
  }
`;

const InfoBtn = styled.button`
  background: transparent;
  border: 1px dotted #BABABA;
  border-radius: 10px;
  cursor: pointer;
  p {
    margin: 0;
  }
`;

const Margin = styled.div`
  margin: 0 10px 10px 0;
  &.soc_logo {
    width: 18px
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const BatchWrapper = styled.div`
position: relative;
  a {
    cursor: pointer;
    svg {
      margin: 2px 0 0 10px;
    }
  }
`;

const ModalOver = styled(Modal)`
  z-index: 35;
  background: #9999993d;
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  z-index: 90;
`;

const Head = styled.div`
margin: 0 15px;
  p {
    margin: 0;
  }
`;

const Avatar = styled(LeadAvaWrapper)`
  margin-left: 54px;
  min-width: 47px;
  @media (max-width: 768px) {
    height: 45px;
  }
`;

const Name = styled(Flex)`
  justify-content: flex-start;
`;

const Location = styled(Flex)`
padding: 10px 0 0 20px;
  background: url(${img}) no-repeat 0% 38%;
`;

const styleLoading = {
  height: '20px',
  width: '100px',
  marginTop: '0'
};

export default function DetailInfo({
  handleShowDetailsOff,
  leadName,
  leadTime,
  leadCount,
  date,
  leadSource,
  leadToShow,
  gaViewId,
  contactDisabled,
  setFilter,
  setAddFilter,
  leadsHide,
  setLeadsHide,
  setId,
  idName,
  setIdName,
  archived
}) {

  const { t: homeT } = useTranslation('home');

  const {
    company,
    visits,
    contacts,
    notes,
    detailsStatus: detailsStatus
  } = useSelector((state) => state.leadsReducer);
  const { users } = useSelector((state) => state.usersReducer);
  const { integrations } = useSelector(
    (state) => state.integrationsReducer
  );
  const [batch, setBatch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [exp, setExport] = useState(false);
  const [asign, setAsign] = useState(false);
  const [archive, setArchive] = useState(false);
  const [hide, setHide] = useState(false);
  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState(false);
  const [tag, setTags] = useState(false);
  const [email, setEmail] = useState(false);
  const [enabled, setEnabled] = useState();

  const { details } = useSelector(
    (state) => state.accountReducer
  );
  const { ids } = useSelector((state) => state.gaViewIdReducer);
 
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers({ users }));
    dispatch(fetchLeadById(leadToShow?.id));
    dispatch(fetchIntegrations({ integrations }));
    /* eslint-disable */
  }, [dispatch, success, result, asign, tag]);
    /* eslint-enable */

  useEffect(() => {
    if(details?.settings?.hubspot_enabled_website) {
      setEnabled(ids.find(e => e.id == gaViewId));
    }
  }, [details, ids, gaViewId]);

  function handleDeleteAssign() {
    network.delete(`api/leads/assign/${leadToShow.id}`, {
      lead_id: leadToShow.id
    })
      .then(data => {
        dispatch(fetchLeadById(leadToShow.id));
        return data;
      });
  }

  function handleDeleteTag(item) {
  
    network.delete(`api/leads/lead_tags/${item.id}`, {
      params: {
        id: item.id,
        lead_id: leadToShow.id  
      }
    })
      .then(data => {
        dispatch(fetchLeadById(leadToShow.id));
        return data;
      });
  }

  function handleShowAsign() {
    setShowModal(true);
    setAsign(true);
  }

  function handleShowTag() {
    setShowModal(true);
    setTags(true);
  }

  const ctx = useContext(Responsive);

  function renderTags(item, idx) {
    return (
      <Margin key={idx}>
        <Tag>
          <TextBlackSmallThin>{item.name}</TextBlackSmallThin>
          <Delete onClick={() => handleDeleteTag(item)}/>
        </Tag>
      </Margin>
    );
  }

  function FetchContacts(id, query) {
    dispatch(fetchLeadContacts(id, {query}));
  }

  useEffect(() => {
    dispatch(fetchLeadById(leadToShow?.id));
    dispatch(fetchLeadVisits(leadToShow?.id));
    dispatch(fetchLeadNotes(leadToShow?.id));
    /* eslint-disable */
  }, [leadToShow?.id, dispatch, result])
  /* eslint-enable */

  function handleBatch() {
    setBatch(true);
  }

  function handleBatchOff() {
    setBatch();
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleShowModalOff() {
    setShowModal(false);
    setBatch(false);
    setExport(false);
    setAsign(false);
    setArchive(false);
    setEmail(false);
    setHide(false);
    setTags(false);
  }

  function handleShowModalOffOver() {
    setShowModal(false);
    setBatch(false);
    setExport(false);
    setAsign(false);
    setArchive(false);
    setEmail(false);
    setHide(false);
    setTags(false);
    setSuccess(false);
  }

  function handleSuccess() {
    setSuccess(!success);
    setBatch(false);
    setExport(false);
    setAsign(false);
    setArchive(false);
    setHide(false);
    setTags(false);
    setTimeout(() => {
      setSuccess(false);
      setShowModal(false);
    }, 2000);
  }

  return (
    <>
      {ctx.isMobile && (
        <Over>
         
        </Over>
      )}
      <Details className={(ctx.impersonate || ctx.isTrial || ctx.isLiteFree) ? 'top' : ''}>
        {batch && <Modal onClick={handleBatchOff}></Modal>}
        {showModal && (
          <ModalOver onClick={handleShowModalOffOver}>
            <Popup onClick={(e) => e.stopPropagation()}>
              {exp && (
                <Export
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[leadToShow?.id]}
                  gaViewId={gaViewId}
                  setResult={setResult}
                />
              )}
              {asign && (
                <Assign
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[leadToShow?.id]}
                  setResult={setResult}
                />
              )}
              {archive && (
                <Archive
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  setResult={setResult}
                  leadsMarked={[leadToShow?.id]}
                  archived={archived}
                  setId={setId}
                />
              )}
              {hide && (
                <Hide
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[leadToShow?.id]}
                  setResult={setResult}
                  setFilter={setFilter}
                  setAddFilter={setAddFilter}
                  handleShowDetailsOff={handleShowDetailsOff}
                  details={true}
                  leadsHide={leadsHide}
                  setLeadsHide={setLeadsHide}
                  setId={setId}
                  idName={idName}
                  setIdName={setIdName}
                  one={true}
                />
              )}
              {tag && (
                <Tags
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[leadToShow?.id]}
                  setResult={setResult}
                />
              )}
              {email && (
                <Email
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={[leadToShow?.id]}
                  setResult={setResult}
                />
              )}
              {/* {success && <Done />} */}
            </Popup>
          </ModalOver>
        )}
        <BlockDetail>
          <DetailsHeader>
            <FlexWrapper>
              <Avatar>
                <ImageFluid src={leadToShow.logo} />
              </Avatar>
              <Head>
                <Name>
                  <TextBlack>{leadToShow.name}</TextBlack>
                  <BatchWrapper>
                    <a onClick={handleBatch}>
                      <Active />
                    </a>
                    {batch && (
                      <>
                        <BatchBox
                          leadsMarked={[leadToShow?.id]}
                          setShowModal={setShowModal}
                          handleShowModal={handleShowModal}
                          handleShowModalOff={handleShowModalOff}
                          setExport={setExport}
                          setBatch={setBatch}
                          setAsign={setAsign}
                          setArchive={setArchive}
                          setHide={setHide}
                          setTags={setTags}
                          setEmail={setEmail}
                          archived={archived}
                        />
                      </>
                    )}
                  </BatchWrapper>
                </Name>
                <Location>
                  <TextBlackSmallThin>{leadToShow.location}</TextBlackSmallThin>
                  <FlexWrapperFirst>
                    {company.results?.company_info.social_profiles && (
                      <FlexWrapperStart>
                        {'twitter' in
                          company.results.company_info.social_profiles && (
                          <Margin>
                            <a
                              href={
                                company.results?.company_info.social_profiles
                                  .twitter
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Twitter />
                            </a>
                          </Margin>
                        )}
                        {'linkedin' in
                          company.results.company_info.social_profiles && (
                          <Margin>
                            <a
                              href={
                                company.results.company_info.social_profiles
                                  .linkedin
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Linkedin />
                            </a>
                          </Margin>
                        )}
                        {'youtube' in
                          company.results.company_info.social_profiles && (
                          <Margin className="soc_logo">
                            <a
                              href={
                                company.results.company_info.social_profiles
                                  .youtube
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Youtube />
                            </a>
                          </Margin>
                        )}
                        {'crunchbase' in
                          company.results.company_info.social_profiles && (
                          <Margin className="soc_logo">
                            <a
                              href={
                                company.results.company_info.social_profiles
                                  .crunchbase
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Crunch />
                            </a>
                          </Margin>
                        )}
                      </FlexWrapperStart>
                    )}
                  </FlexWrapperFirst>
                </Location>
              </Head>
            </FlexWrapper>
          </DetailsHeader>
          <Info>
            <FlexWrapper>
              <Flex>
                <TextBlackSmall>{homeT('assignTo')}:</TextBlackSmall>
                <FlexWrap>
                  {isStatusLoading(detailsStatus) ? (
                    <Loading style={styleLoading} />
                  ) : company.results && company.results.assigned_to ? (
                    <UserWrapper>
                      <TextBlackSmallThin>
                        {company.results.assigned_to} 
                      </TextBlackSmallThin>
                      <Delete onClick={handleDeleteAssign}/>
                    </UserWrapper>
                  ) : null}
                  <Margin>
                    <InfoBtn
                      onClick={handleShowAsign}
                    >
                      <TextLight>{homeT('add')}</TextLight>
                    </InfoBtn>
                  </Margin>
                </FlexWrap>
              </Flex>
            </FlexWrapper>
            <FlexWrapper>
              <Flex>
                <TextBlackSmall>{homeT('tags')}:</TextBlackSmall>
                <FlexWrap>
                  {isStatusLoading(detailsStatus) ? (
                    <Loading style={styleLoading} />
                  ) :
                    company.results?.tags &&
                    company.results?.tags.map(renderTags)}
                  <Margin>
                    <InfoBtn
                      onClick={handleShowTag}
                    >
                      <TextLight>{homeT('add')}</TextLight>
                    </InfoBtn>
                  </Margin>
                </FlexWrap>
              </Flex>
            </FlexWrapper>
          </Info>
          {integrations?.length ?
            <Buttons
              id={leadToShow?.id}
              leadName={leadName}
              website={company?.results?.company_info.website}
              integrations={integrations}
              gaViewId={gaViewId}
              leadLocation={leadToShow.location}
              enabled={enabled}
            />: null}
          <DetailAccordion
            leadTime={leadTime}
            leadCount={leadCount}
            date={date}
            leadSource={leadSource}
            companyInfo={company.results}
            visitsInfo={visits.results?.visit_details}
            contactsInfo={contacts}
            notesInfo={notes}
            leadToShow={leadToShow}
            detailsStatus={detailsStatus}
            integrations={integrations}
            contactDisabled={contactDisabled}
            FetchContacts={FetchContacts}
            gaViewId={gaViewId}
            enabled={enabled}
          />
          <CloseBtn className='bottom' onClick={handleShowDetailsOff}>
            <Arrow />
          </CloseBtn>
        </BlockDetail>
      </Details>
    </>
  );
}

DetailInfo.propTypes = {
  handleShowDetailsOff: func,
  leadName: string,
  leadLocation: string,
  leadLogo: string,
  leadTime: string,
  leadCount: number,
  date: string,
  leadSource: string,
  companyInfo: object,
  visitsInfo: array,
  contactsInfo: array,
  notesInfo: array,
  leadToShow: any,
  gaViewId: number,
  contactDisabled: any,
  setFilter: func,
  setAddFilter: func,
  leadsHide: any,
  setLeadsHide: func,
  setId: func,
  idName: any,
  setIdName: func,
  archived: bool
};