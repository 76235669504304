import { useState, useEffect, useMemo } from 'react';
import { any, array, bool, func, object } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  BlockMargin,
  Flex,
  FlexWrapperStartMiddle,
  StyledSelect,
  TextLight,
} from 'base/styled';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import { useTranslation } from 'react-i18next';
import FormTextFieldTime from 'base/components/FormTextFieldTime';
import Location from './Location';
import { makeAsOptions } from 'base/utils';

const Wrapper = styled(FlexWrapperStartMiddle)`
  position: relative;
  min-height: 65px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    margin: 10px 0;
    .bsfINW {
      width: 100%;
      min-width: auto;
    }
  }
  label {
    line-height: 9px!important
  }
`;

const BlockMarginRelative = styled(BlockMargin)`
  position: relative;
  input[type='number'] {
    margin-right: 10px;
  }
  label {
    line-height: 16px
  }
`;

const TextLightAbsolute = styled(TextLight)`
  position: absolute;
  top: 47px;
  font-weight: 400!important;
`;

const Block = styled(BlockMargin)`
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

function Cascad({
  handlerType,
  handlerEqual,
  handlerValue,
  edit,
  styles,
  item,
  setIsSelectValue,
  gic,
  naics,
  usersOptions,
  ValueFromHandler,
  ValueToHandler,
}) {
  const { t: homeT } = useTranslation('home');
  const [selected, setSelected] = useState(edit ? item.key : '');
  const [input, setInput] = useState(true);
  const [time, setTime] = useState(false);
  const [tree, setTree] = useState(false);
  const [location, setLocation] = useState(false);
  const [between, setBetween] = useState(false);
  const [operator, setOperator] = useState(edit ? item.operator : '');
  const { users } = useSelector((state) => state.usersReducer);

  function changeSelectOptionHandler(event, item) {
    setSelected(event.value);
    handlerType(event, item);
  }

  const filterTypesMap = useMemo(
    () => ({
      employees: { value: 'employees', label: 'Approximate Employees' },
      assigned: { value: 'assigned', label: 'Assigned' },
      company_name: { value: 'company_name', label: 'Company Name' },
      full_contact_city: {
        value: 'full_contact_city',
        label: 'Headquarters City',
      },
      full_contact_region: {
        value: 'full_contact_region',
        label: 'Headquarters Region',
      },
      full_contact_country: {
        value: 'full_contact_country',
        label: 'Headquarters Country',
      },
      industry: { value: 'industry', label: 'NAICS Industry' },
      gic_industry: { value: 'gic_industry', label: 'GIC Industry' },
      keyword: { value: 'keyword', label: 'Keyword' },
      visits: { value: 'visits', label: 'Number of Page Views' },
      page_path: { value: 'page_path', label: 'Page Path' },
      query: { value: 'query', label: 'Query String' },
      source: { value: 'source', label: 'Source' },
      time_on_page: { value: 'time_on_page', label: 'Time on Website' },
      city: { value: 'city', label: 'Visitor Location City' },
      country: { value: 'country', label: 'Visitor Location Country' },
      region: { value: 'region', label: 'Visitor Location State/Province' },
      tag: { value: 'tag', label: 'Tag' },
      domain: { value: 'domain', label: 'Domain' },
    }),
    []
  );

  const filterTypesOptions = useMemo(
    () => Object.values(filterTypesMap),
    [filterTypesMap]
  );

  useEffect(() => {
    setSelected(item.key);
  }, [item]);

  const equalsOptions = [
    { value: '=' || 'eq', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: 'contains', label: 'contains' },
    { value: 'does not contain', label: 'does not contain' },
  ];

  const equalsOptionsGr = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: '>', label: 'greater than' },
    { value: '>=', label: 'greater than or equal to' },
    { value: '<', label: 'less than' },
    { value: '<=', label: 'less than or equal to' },
  ];

  const equalsOptionsBetween = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: '>', label: 'greater than' },
    { value: '>=', label: 'greater than or equal to' },
    { value: '<', label: 'less than' },
    { value: '<=', label: 'less than or equal to' },
    { value: 'between', label: 'between'}
  ];

  const equal = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
  ];

  const boolOptions = [
    { value: 'yes', label: 'yes' },
    { value: 'no', label: 'no' },
  ];

  const [type, setType] = useState(equalsOptions);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (
      selected === 'company_name' ||
      selected === 'keyword' ||
      selected === 'page_path' ||
      selected === 'source' ||
      selected === 'tag' ||
      selected === 'query' ||
      selected === 'domain'
    ) {
      setInput(true);
      setTime(false);
      setType(equalsOptions);
      setIsSelectValue(false);
      setLocation(false);
    } else if (
      selected === 'city' ||
      selected === 'country' ||
      selected === 'region' ||
      selected === 'full_contact_city' ||
      selected === 'full_contact_country' ||
      selected === 'full_contact_region'
    ) {
      setInput(false);
      setTime(false);
      setType(equalsOptions);
      setIsSelectValue(false);
      setLocation(true);
      //setTree(true);
      setIsSelectValue(true);
      //setValue(naics);
    } else if (selected === 'assigned') {
      setInput(false);
      setValue(usersOptions || makeAsOptions(users, 'id', 'full_name'));
      setType(boolOptions);
      setIsSelectValue(true);
      setLocation(false);
    } else if (selected === 'time_on_page') {
      setTime(true);
      setInput(true);
      setIsSelectValue(false);
      setLocation(false);
      setType(equalsOptionsGr);
    } else if (selected === 'employees' || selected === 'visits' ) {
      setType(equalsOptionsBetween);
      setTime(false);
      setInput(true);
      setIsSelectValue(false);
    } else if (selected === 'gic_industry') {
      setTime(false);
      setInput(false);
      setTree(false);
      setIsSelectValue(true);
      setType(equal);
      setValue(gic);
      setLocation(false);
    } else if (selected === 'industry') {
      setTime(false);
      setInput(false);
      setTree(true);
      setIsSelectValue(true);
      setType(equal);
      setValue(naics);
      setLocation(false);
    } else {
      setInput(false);
      setTree(false);
      setIsSelectValue(false);
      setLocation(false);
    }
    /* eslint-disable */
  }, [item, selected, users]);
  /* eslint-enable */
 
  useEffect(() => {
    if (operator == 'between') {
      setBetween(true);
    }
  }, [operator]);

  const handleOperator = (e) => {
    handlerEqual(e, item);
    setOperator(e.value);
    if(e.value == 'between') {
      setBetween(true);
    } else {
      setBetween(false);
    }
  };

  return (
    <Wrapper>
      <Block className='select-wrap form-field'>
        <StyledSelect
          placeholder="Filter Type"
          options={Object.values(filterTypesMap)}
          className='cascad-select'
          onChange={(e) => changeSelectOptionHandler(e, item)}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          styles={styles}
          value={filterTypesMap[selected]}
        />
      </Block>
      <Block className='select-wrap form-field'>
        {
          <StyledSelect
            options={type}
            onChange={handleOperator}
            styles={styles}
            className='cascad-select'
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
            value={
              selected == 'assigned'
                ? boolOptions.find((el) => el.value === item.operator)
                : type.find((el) => el.value === item.operator)
              // {label: "yes", value: 'yes'}
            }
          />
        }
      </Block>
      <BlockMarginRelative className='select-wrap form-field'>
        {input ? (
          <>
            {time ? (
              <>
                <FormTextFieldTime
                  onChange={(e) => handlerValue(e, item)}
                  value={item.value}
                  label={homeT('sec')}
                  className='cascad-select'
                />
              </>
            ) : between ? (
              <Flex>
                <FormTextField
                  type="number"
                  onChange={(e) => ValueFromHandler(e, item)}
                  value={item.multiple_filter?.value_from}
                  placeholder={homeT('from')}
                />
                <FormTextField
                  type="number"
                  onChange={(e) => ValueToHandler(e, item)}
                  value={item.multiple_filter?.value_to}
                  placeholder={homeT('to')}
                />
              </Flex>
            ) : (
              <FormTextField
                type="text"
                onChange={(e) => handlerValue(e, item)}
                value={item.value}
                label={homeT('value')}
              />
            )}
            {!time && !between && (
              <TextLightAbsolute>{homeT('useCommas')}</TextLightAbsolute>
            )}
          </>
        ) : tree ? (
          <StyledSelect
            isClearable
            isSearchable
            options={value}
            isMulti
            styles={styles}
            className='cascad-select'
            onChange={(e) => handlerValue(e, item)}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
            defaultValue={
              edit
                ? []
                  .concat(...naics.map(({ options }) => options || []))
                  .filter(({ value }) =>
                    item.value.split(',').includes(value)
                  )
                : ''
            }
          />
        ) : location ? (
          <Location
            data={filterTypesOptions}
            styles={styles}
            selected={selected}
            item={item}
            handlerValue={handlerValue}
            edit={edit}
            defaultValue={edit ? item.value : ''}
          />
        ) : (
          <>
            <StyledSelect
              options={value}
              isMulti
              styles={styles}
              onChange={(e) => handlerValue(e, item)}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              defaultValue={
                edit
                  ? selected == 'assigned'
                    ? value?.filter(
                      (user) =>
                        item.value
                          .split(',')
                          .includes(user.value?.toString()) ||
                        item.value == user.value
                    )
                    : gic.filter(({ value }) =>
                      item.value.split(',').includes(value)
                    )
                  : ''
              }
            />
          </>
        ) }
      </BlockMarginRelative>
    </Wrapper>
  );
}

export default Cascad;

Cascad.propTypes = {
  handlerType: func,
  handlerEqual: func,
  handlerValueInput: func,
  handlerValue: func,
  styles: object,
  edit: bool,
  item: any,
  usersOptions: any,
  users: array,
  setIsSelectValue: func,
  gic: array,
  naics: array,
  ValueFromHandler: func,
  ValueToHandler: func,
};
