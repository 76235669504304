import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOwner, getPipeline } from 'base/network/hubspot';
import { LOADING, SUCCEED, FAILURE } from 'store/CONSTANTS';

export const initialState = {
  status: LOADING,
  owners: [],
  pipeline: null,
  page: 1,
  per_page: 100,
  errors: {}
};

export const fetchOwner = createAsyncThunk(
  'hubspotslice/fetchOwner',
  async ({ ...rest}) => {
    const response = await getOwner({ page: 1, ...rest });
    return response.data;
  });

export const fetchPipeline = createAsyncThunk(
  'hubspotslice/fetchPipeline',
  async ({ ...rest}) => {
    const response = await getPipeline({ page: 1, ...rest });
    return  response.data;
  });

export const hubspotslice = createSlice({
  name: 'hubspotslice',
  initialState,
  reducers: {
    nextPage (state) {
      state.page += 1;
    }
  },
  extraReducers: {
    [fetchOwner.pending]: (state) => {
      state.status = LOADING;
    },
    [fetchOwner.fulfilled]: (state, action) => {
      state.owners = action.payload.results;
      state.page = 1;
      state.status = SUCCEED;
    },
    [fetchOwner.rejected]: (state) => {
      state.status = FAILURE;
    },

    [fetchPipeline.pending]: (state) => {
      state.status = LOADING;
    },
    [fetchPipeline.fulfilled]: (state, action) => {
      state.status = SUCCEED;
      state.pipeline =  action.payload.results;
    },
    [fetchPipeline.rejected]: (state, { payload }) => {
      state.status = FAILURE;
      state.errors = payload?.message;
    },
  }
});

export const { nextPage, setFiltersData } = hubspotslice.actions;

export default hubspotslice.reducer;
