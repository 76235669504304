import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeAsOptions } from 'base/utils';
import { func, number, string } from 'prop-types';
import network from 'base/network';
import { fetchOwner } from 'store/slices/hubspot';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import {
  AccentButton,
  Block,
  StyledSelect,
  TextBlackBig,
  TextGreyBold,
  Flex,
  TextBlackSmall,
  TextLabel,
  TextLightSmall,
  TextAccentSmall,
  SecondaryBtnHigh,
} from 'base/styled';
import { ReactComponent as Close } from 'assets/images/close.svg';
import { ReactComponent as Icon } from 'assets/images/saved.svg';
import ReactDatePicker from 'react-datepicker';
import FormTextArea from 'base/components/FormTextArea';

const Popup = styled(Block)`
  margin: 0 auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translate(0, 16%);
  svg {
    margin: 0 auto;
  }
  button {
    height: 37px;
    margin-right: 5px;
  }
  .center {
    margin: 0 auto;
    padding-top: 20px;
  }
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  form {
    width: 100%;
    text-align: left;
    position: relative;
    .center {
      text-align: center;
      font-size: .85rem;
      line-height: 1.3rem;
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 0;
    }
    .date {
      border: ${props => props.theme.borders.textInput};
      font-family: 'Montserrat', sans-serif;
      font-size: .75rem;
      border-radius: 8px;
      height: 38px;
      width: 60%%;
      padding: 0 10px;
      color: ${props => props.theme.colors.lightGrey};
      transition: all 0.2s;
      position: relative;
      &:focus-visible {
        outline: none;
      }
      &::-webkit-input-placeholder  {
        color: ${props => props.theme.colors.lightGrey};
      }
    }
    
    p {
      padding: 0px 0;
    }
    .react-datepicker-popper {
      z-index: 90;
      left: -50%!important;
    }
    .time {
     
    }
    .react-datepicker.react-datepicker--time-only {
      left: 52%!important;
    }
    .react-datepicker {
      font-family: ${(props) => props.theme.fonts.main};
      font-size: 14px;
      border: ${(props) => props.theme.borders.textInput};
      border-radius: 8px;
      .react-datepicker__navigation {
        top: 34px;
      }
      .react-datepicker__navigation--previous {
        right: 54px;
        left: auto;
      }
      .react-datepicker__day--keyboard-selected:hover,
      .react-datepicker__month-text--keyboard-selected:hover,
      .react-datepicker__quarter-text--keyboard-selected:hover,
      .react-datepicker__year-text--keyboard-selected:hover,
      .react-datepicker__day--selected:hover,
      .react-datepicker__day--in-selecting-range:hover,
      .react-datepicker__day--in-range:hover,
      .react-datepicker__month-text--selected:hover,
      .react-datepicker__month-text--in-selecting-range:hover,
      .react-datepicker__month-text--in-range:hover,
      .react-datepicker__quarter-text--selected:hover,
      .react-datepicker__quarter-text--in-selecting-range:hover,
      .react-datepicker__quarter-text--in-range:hover,
      .react-datepicker__year-text--selected:hover,
      .react-datepicker__year-text--in-selecting-range:hover,
      .react-datepicker__year-text--in-range:hover,
      react-datepicker__day--selected,
      .react-datepicker__day--today,
      .react-datepicker__day--keyboard-selected {
        background-color: #f966525e;
        border-radius: 50%;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background-color: ${(props) => props.theme.colors.accentRed};
        border-radius: 50%;
      }
      .react-datepicker__navigation--next {
        right: 22px;
      }

      .react-datepicker__navigation-icon::before {
        width: 6px;
        height: 6px;
        border-color: #010101;
      }

      .react-datepicker__triangle {
        margin: 0;
        z-index: -9;
      }
      .react-datepicker__current-month {
        padding: 0 0 20px 5px;
      }
      .react-datepicker__header {
        text-align: left;
        background: inherit;
        padding: 35px 40px 0;
        font-size: inherit;
        border-bottom: 0;
      }
      .react-datepicker__day-names {
        font-weight: 600;
      }
      .react-datepicker__month {
        margin-bottom: 20px;
      }
  }
  .bottom {
    margin-bottom: 35px;
  }
`;

const Buttons = styled.div`
  text-align: center;
  margin-top: 20px;
  button {
    margin-right: 20px;
  }
`;

const Select = styled(StyledSelect)`
  margin-right: 0;
`;

const Title = styled(Flex)`
  justify-content: space-between;
  p {
    padding: 8px 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  svg {
    margin: 0;
    margin-top: 10px;
  }
`;

const TypeSelect = styled(Select)`
  min-width: 100%;
`;

const PrioritySelect = styled(Select)`
min-width: 100%;
`;

const FlexBetween = styled(Flex)`
justify-content: space-between;
`;

const Type = styled.div`
  width: 65%;
`;

const Priority = styled.div`
width: 30%;
.bottom {
  max-width: auto;
  min-width: auto
}
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

export default function Task({handleFormOff, id}) {
  const { t: homeT } = useTranslation('home');
  const [name, setName] = useState(null);
  const [owner, setOwner] = useState(null);
  const [note, setNote] = useState('');
  const [result, setResult] = useState(false);
  const [priority, setPriority] = useState(null);
  const [company, setCompany] = useState(null);
  const [contact, setContact] = useState(null);
  const [deal, setDeal] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const { owners, status } = useSelector((state) => state.hubspotReducer);
  
  useEffect(() => {
    dispatch(fetchOwner({ owners, status }));
    /* eslint-disable */
  }, [dispatch]);
   /* eslint-enable */
  const optionsOwner = makeAsOptions(owners, 'id', 'email');

  const optionsType = [
    { name: 'TODO', label: 'To-Do' },
    { name: 'CALL', label: 'Call' },
    { name: 'EMAIL', label: 'Email' }
  ];
  const optionsPriority = [
    { name: 'NONE', label: 'None' },
    { name: 'LOW', label: 'Low' },
    { name: 'MEDIUM', label: 'Medium' },
    { name: 'HIGH', label: 'High' }
  ];

  const [type, setType] = useState(null);
  const [optionsCompany, setOptionsCompany] = useState([]);
  const [optionsContact, setOptionsContact] = useState([]);
  const [optionsDeal, setOptionsDeal] = useState([]);
  const [companyValue, setCompanyValue] = useState('');
  const [contactInput, setContactInputValue] = useState('');
  const [dealValue, setDealValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleCompanyChange = (newInputValue) => {
    setCompanyValue(newInputValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      getCompanies(newInputValue);
    }, 400); 

    setTimeoutId(newTimeoutId);
  };

  const handleContactChange = (newInputValue) => {
    setContactInputValue(newInputValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      getContacts(newInputValue);
    }, 400); 

    setTimeoutId(newTimeoutId);
  };

  const handleDealChange = (newInputValue) => {
    setDealValue(newInputValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      getDeal(newInputValue);
    }, 400); 

    setTimeoutId(newTimeoutId);
  };

  const getCompanies = async (inputValue) => {
    await network
      .get(`/api/hubspot/search`, { params: {id: id, object: 'Company', query: inputValue} })
      .then(({ data }) => {
        setOptionsCompany(makeAsOptions( data.results, 'id', 'name'));
        return data;
      })
      .catch((error) => console.log(error));
  };

  const getContacts = async (inputValue) => {
    await network
      .get(`/api/hubspot/search`, { params: {id: id, object: 'Contact', query: inputValue} })
      .then(({ data }) => {
        setOptionsContact(makeAsOptions( data.results, 'id', 'name'));
        return data;
      })
      .catch((error) => console.log(error));
  };

  const getDeal = async (inputValue) => {
    await network
      .get(`/api/hubspot/search`, { params: {id: id, object: 'Deal', query: inputValue} })
      .then(({ data }) => {
        setOptionsDeal(makeAsOptions( data.results, 'id', 'name'));
        return data;
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCompanies();
    getContacts();
    getDeal();
    /* eslint-disable */
  }, []);
    /* eslint-enable */

  function handleName(e) {
    setName(e.target.value);
  }

  function handleOwner(e) {
    setOwner(e);
  }

  function handleNote(e) {
    setNote(e.target.value);
  }

  function handlePriority(e) {
    setPriority(e);
  }

  function handleType(e) {
    setType(e);
  }

  function handleCompany(e) {
    setCompany(e);
  }

  function handleContact(e) {
    setContact(e);
  }

  function handleDeal(e) {
    setDeal(e);
  }
  
  const postDeal = async () => {
    //const date = startDate.toLocaleString().split('.').join('/').substring(0, 10)
    const d = startDate;

    const curr_date = d.getDate();
    let curr_month = d.getMonth();
    curr_month++;
    const curr_year = d.getFullYear();
  
    const date = curr_month + "/" + curr_date + "/" + curr_year;

    let options = {  
      weekday: "long", year: "numeric", month: "short",  
      day: "numeric", hour: "2-digit", minute: "2-digit"  
    };
    const time = startDate.toLocaleTimeString("en-us", options).slice(-8);
 
    let res = await network
      .post(`/api/hubspot/task`, { 
        task_title: name,
        company_id: company?.value,
        deal_id: deal?.value,
        task_type: type.name,
        task_priority: priority.name,
        contact_id: contact?.value,
        user_id: owner.value,
        deal_type: type.name,
        close_date: date,
        close_time: time,
        notes: note
      })
      .then(({ data }) => {
        setResult(data.message || data.error);
        if(data.error) {
          setErrorMessage(true);
        }
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  function handleSubmit(e) {
    e.preventDefault();
     
    if(priority == null) {
      setError('choose the priority');     
    } else if (type == null){
      setError('choose the type');
    } else if (startDate == null){
      setError('choose date');
    } else if (owner == null){
      setError('choose the owner');
    } else {
      postDeal();
    }
  }

  return (
    <Popup>
      {result ? (
        <>
          {errorMessage != true && <Icon />}
          <TextGreyBold className="center">{result}</TextGreyBold>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Title>
            <TextBlackBig>{homeT('CreateTaskHubspot')}</TextBlackBig>
            <Close onClick={handleFormOff} />
          </Title>
          <FormTextField
            type="text"
            label="Title"
            placeholder="Enter a task name"
            value={name}
            onChange={handleName}
            required
          />
          <FlexBetween>
            <Type>
              <TextLightSmall>{homeT('type')}</TextLightSmall>
              <TypeSelect
                value={type}
                options={optionsType}
                onChange={handleType}
                styles={customStyles}
                menuPlacement="bottom"
                className="bottom"
                required={true}
              />
            </Type>
            <Priority>
              <TextLightSmall>{homeT('priority')}</TextLightSmall>
              <PrioritySelect
                value={priority}
                options={optionsPriority}
                onChange={handlePriority}
                styles={customStyles}
                menuPlacement="bottom"
                className="bottom"
                required={true}
              />
            </Priority>
          </FlexBetween>
          <TextBlackSmall>{homeT('associateRecords')}</TextBlackSmall>
          <TextLightSmall>{homeT('company')}</TextLightSmall>
          <Select
            value={company}
            options={optionsCompany}
            onChange={handleCompany}
            onInputChange={handleCompanyChange}
            inputValue={companyValue}
            styles={customStyles}
            menuPlacement="bottom"
          />
          <TextLightSmall>{homeT('deals')}</TextLightSmall>
          <Select
            value={deal}
            options={optionsDeal}
            onChange={handleDeal}
            onInputChange={handleDealChange}
            inputValue={dealValue}
            styles={customStyles}
            menuPlacement="bottom"
          />
          <TextLightSmall>{homeT('contacts')}</TextLightSmall>
          <Select
            value={contact}
            options={optionsContact}
            onChange={handleContact}
            onInputChange={handleContactChange}
            inputValue={contactInput}
            styles={customStyles}
            menuPlacement="bottom"
          />
          <TextLightSmall>{homeT('taskOwner')}</TextLightSmall>
          <Select
            value={owner}
            options={optionsOwner}
            onChange={handleOwner}
            styles={customStyles}
            menuPlacement="bottom"
            className="bottom"
          />
          <TextLabel>{homeT('dueDate')}</TextLabel>
          <FlexBetween>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="mm/dd/yyyy"
              className="date"
            />
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="date time"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </FlexBetween>
          <FormTextArea
            type="text"
            label={homeT('notes')}
            value={note}
            onChange={handleNote}
          />
          <Buttons>
            <TextAccentSmall>{error}</TextAccentSmall>
            <SecondaryBtnHigh onClick={handleFormOff}>
              {homeT('cancel')}
            </SecondaryBtnHigh>
            <AccentButton type="submit">{homeT('createDeal')}</AccentButton>
          </Buttons>
        </form>
      )}
    </Popup>
  );
}

Task.propTypes = {
  handleFormOff: func,
  id: number,
  leadName: string
};
