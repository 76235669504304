import { useState, useRef, useEffect } from "react";
import { any, bool, string } from 'prop-types';
import styled from 'styled-components';
import { TextBlackThin } from 'base/styled';
import { ReactComponent as CollapseMain } from 'assets/images/triangle.svg';

const AccordionTitle = styled.div`
  background: transparent;
  padding: 8px 20px 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 33px;
  &.is-open {
    border-bottom: none;
  }
  p {
    margin: -1px 8px 0 10px;
  }
  &:hover {
    background: #FFF9F0;
  }
`;

const AccordionBlock = styled.div`
  margin: 5px 0;
  border: none;
  box-shadow: 0px 1px 5px 0px #5755553D;
  border-radius: 18px;
  overflow: hidden;
  background: #fff;
  .collapse-btn {
    display: none;
  }
  &.visites {
    position: relative;
    &.border {
      .collapse-btn {
        display: flex;
        position: absolute;
        right: 20px;
        top: 8px;
        z-index: 9;
        svg {
          margin: 1px 0 0 8px
        }
      }
    }

  }
  &.company {
    .accordion-content {
      position: relative;
      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 94%;
        height: 1px;
        background: #C6D5DD;
        top: 0;
        left: 10px;
      }
    }
  }
  &:nth-child(3){
    position: relative;
    overflow: visible;
  }
  svg {
    transition: transform 280ms cubic-bezier(0, 1, 0, 1);
  }
  .is-open {
    .collapse {
      transform: rotate(90deg);
    }
  }
  .rotate {
    transform: rotate(90deg);
  }
  &.border {
    box-shadow: 0px 2px 12px 0px #F8876475;
    border: 1px solid #FFC77C;
    overflow: hidden;
  
  }
  @media (max-width: 1180px) {
    p {
      line-height: 1;
    }
   }
  .accordion-content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    height: fit-content;
  }
`;

const ToggleBtn = styled.div`
  cursor: pointer;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;

  &.opened {
    svg {
        transform: rotate(90deg);
    }
  }
`;

const Accordion = ({allactive, title, children, icon, collapse, className, activeItem, activeAll }) => {
  const [active, setActive] = useState(allactive);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.height = active
      //? `${contentRef.current.scrollHeight + 1000}px`
      ? 'fit-content'
      : "0px";
    
  }, [contentRef, active]);

  // useEffect(() => {
  //   setActive(allactive);
  // }, [allactive]);

  function toogleActive() {
    setActive(!active);
  }

  return (
    <AccordionBlock className={active ? className + ' border' : className}>
      <AccordionTitle className={active ? 'is-open accordion-title' : 'closed accordion-title'} onClick={toogleActive}>
        <TextBlackThin>{title}</TextBlackThin> {icon}
        <TextBlackThin className={active ? "accordion-icon rotate" : "accordion-icon"}>
          {collapse}
        </TextBlackThin>
        <ToggleBtn onClick={activeAll} className={!activeItem ? 'collapse-btn' : 'opened collapse-btn'}>
         reveal all<CollapseMain />
        </ToggleBtn>
      </AccordionTitle>

      <div style={{height: 'fit-content;'}} ref={contentRef} className="accordion-content wrap">
        {children}
      </div>
    </AccordionBlock>
  );
};

export default Accordion;

Accordion.propTypes = {
  allactive: bool,
  title: string,
  children: any,
  icon: any,
  collapse: any,
  className: string,
  activeItem: any, 
  activeAll: any
};
