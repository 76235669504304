import { any, bool, func, array } from 'prop-types';
import { ModalFull } from 'base/styled';
import TabsFilter from './TabsFilter';

export default function FilterList({handleFilter, contactDisabled, setAddFilter, addFilter, gic,
  naics}) {

  return (
    <ModalFull>
      <TabsFilter
        handleFilter={handleFilter}
        contactDisabled={contactDisabled}
        setAddFilter={setAddFilter}
        addFilter={addFilter}
        gic={gic}
        naics={naics}
      />
    </ModalFull>
  );
}

FilterList.propTypes = {
  handleFilter: func,
  contactDisabled: any,
  setAddFilter: func,
  addFilter: bool,
  gic: array,
  naics: array,
};
