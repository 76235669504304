import { func, any } from 'prop-types';
import {
  Block,
  Flex,
  OutlinedButton,
  TextBlackSmall,
  TextBlackSmallThin,
  TextGreySmallBold,
  TextLightExtraSmall,
  TitleSmallBlack,
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'assets/images/success_huge.svg';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { Helmet } from 'react-helmet-async';

const BlockMargin = styled(Block)`
  margin: 0 auto;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 40px 40px 15px;
  width: 40%;
  .center {
    text-align: center;
  }
   @media (max-width: 1148px) {
    .column {
    flex-direction: column;
    }

  }

`;

const BlockLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    margin: 0 auto;
    display: block;
  }
  h1 {
    text-align: center;
  }
`;

const BlockRight = styled.div`
  width: 52%;
   @media (max-width: 1148px) {
    width: auto;
  }
  @media (max-width: 768px) {
    width: auto;
  }
  svg {
    margin: 0 auto;
    display: block;
  }
  button {
    margin-left: auto;
    display: block;
    margin-top: 20px;
  }
`;

const BillInfo = styled.div`
  background:  #F9F9F9;
  padding: 5px 15px;
  border-radius: 15px;
  margin: 10px;
`;

const BillRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  .after {
    border-bottom: 1px dotted 1px #626262;
    background-image: linear-gradient(
      to right,
      #626262 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 6px 1.5px;
    background-repeat: repeat-x;
    width: 100%;
    height: 24px;
    bottom: 0;
    left: 0;
  }
  .bold {
    font-weight: 600;
    white-space: nowrap;
  }
  .relative {
    position: relative;
    margin-bottom: 10px;
  }
  .absolute {
    position: absolute;
    top: 15px;
  }
`;

export default function SuccessSubscribe({ details, close, subscribtionNew, subscription }) {
  const { t: generalT } = useTranslation();
  const { t: companyT } = useTranslation('company');

  return (
    <>
      <Helmet>
        <script src="https://script.tapfiliate.com/tapfiliate.js" type="text/javascript" async></script>
        <script type="text/javascript">
          {`const amount = $('#tapfiliate_container').data('amount');
          const charge = $('#tapfiliate_container').data('charge');
          const customerId = $('#tapfiliate_container').data('customer-id');
          const currency = $('#tapfiliate_container').data('currency');
          const orderId = $('#tapfiliate_container').data('order-id');
          (function (t, a, p) {
            t.TapfiliateObject = a; t[a] = t[a] || function () {
              (t[a].q = t[a].q || []).push(arguments)
            }
          })(window, 'tap');
          tap('create', '23595-710c62', { integration: "stripe" });
          tap('conversion', charge, amount, { customer_id: ${details?.stripe_customer_id} });`}
        </script>
      </Helmet>
      <BlockMargin>
        <Flex className='column'>
          <BlockLeft>
            <Icon />
            <TitleSmallBlack>{generalT('thanks')}</TitleSmallBlack>
          </BlockLeft>

          <BlockRight>
            <Logo />
            <BillInfo>
              <BillRow>
                <div className="relative">
                  <TextBlackSmallThin>{companyT('newPlan')}</TextBlackSmallThin>
                </div>  
                <TextBlackSmall className="bold">
                  {subscribtionNew?.new_plan_name}
                </TextBlackSmall>
              </BillRow>
              <BillRow>
                <div className="relative">
                  <TextBlackSmallThin>{companyT('oldPlan')}</TextBlackSmallThin>
                </div>
                
                <TextBlackSmall className="bold">
                  {subscription?.name}
                </TextBlackSmall>
              </BillRow>
              <BillRow>
                <TextBlackSmallThin>{companyT('invoice')}</TextBlackSmallThin>
              
                <TextBlackSmall className="bold">
                  {subscribtionNew?.invoice}
                </TextBlackSmall>
              </BillRow>
              <BillRow>
                <div className="relative">
                  <TextBlackSmallThin>{companyT('subtotal')}</TextBlackSmallThin>
                </div>           
                <TextBlackSmall className="bold">
                  {subscribtionNew?.new_plan_name.includes('USD') && '$' ||
                    subscribtionNew?.new_plan_name.includes('CAD') && '$' ||
                    subscribtionNew?.new_plan_name.includes('EUR') && '€' ||
                    subscribtionNew?.new_plan_name.includes('GBP') && '£'
                  }
                  {parseFloat(subscribtionNew?.subtotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                </TextBlackSmall>
              </BillRow>
              <BillRow>
                <TextBlackSmallThin>{companyT('taxes')}</TextBlackSmallThin>
                <TextBlackSmall className="bold"> {subscribtionNew?.taxes}</TextBlackSmall>
              </BillRow>

              <BillRow>
                <TextGreySmallBold>{companyT('total')}</TextGreySmallBold>
                <TextGreySmallBold className="bold">
                  {subscribtionNew?.new_plan_name.includes('USD') && '$' ||
                    subscribtionNew?.new_plan_name.includes('CAD') && '$' ||
                    subscribtionNew?.new_plan_name.includes('EUR') && '€' ||
                    subscribtionNew?.new_plan_name.includes('GBP') && '£'
                  }
                  {parseFloat(subscribtionNew?.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                </TextGreySmallBold>
              </BillRow>
            </BillInfo>
            <OutlinedButton onClick={() => close()}>{companyT('close')}</OutlinedButton>
          </BlockRight>
        </Flex>
        <TextLightExtraSmall className='center'>©Visitor Queue | London, Canada</TextLightExtraSmall>
      </BlockMargin>
    </>
  );
}

SuccessSubscribe.propTypes = {
  close: func, 
  subscribtionNew: any, 
  subscription: any,
  details: any
};
