import { useTranslation } from 'react-i18next';
import { Doughnut} from 'react-chartjs-2';
import { Block, Flex, Loading } from 'base/styled';
import { number } from 'prop-types';
import { ReactComponent as ChartIcon } from 'assets/images/countries.svg';
import styled from 'styled-components';
import TooltipDark from 'base/components/TooltipDark';
import NoStatystic from './NoStatystic';
import { useEffect, useState } from 'react';
import network from 'base/network';


const ChartBlock = styled(Block)`
  padding: 13.5px 16px 10px 20px;
  width: 24%;
  min-width: 260px;
  @media (max-width:1080px) {
    margin: 20px
  }
  .esQzEn {
    left: 1px;
    top: -11px;
  }
  @media (max-width:768px) {
    width: 100%;
    margin: 20px 0;
  }


`;

const Loader = styled(Loading)`
  width: 106%;
  height: 100%;
  margin-top: 0;
`;

const options = {
  legend: {
    display: true,
    labels: {
      fontSize: 12,
      boxWidth: 12,
      borderRadius: '2px',
      usePointStyle: true,
      pointStyle: 'rectRounded',
      // boxWidth: 12,
      // borderRadius: 2,
      useBorderRadius: true,
  
      font: {
        family: 'Montserrat'
      }
    },
    position: 'left',
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  tooltips: {
    //enabled: false,
    callbacks: {
      label: (tooltipItem, data) => {
        // Get the dataset label, global label or fall back to empty label
        let label =
          (data.datasets[tooltipItem.datasetIndex].labels &&
            data.datasets[tooltipItem.datasetIndex].labels[
              tooltipItem.index
            ]) ||
          data.labels[tooltipItem.index] ||
          "";
        if (label) {
          label += ": ";
        }

        // Apply the value and suffix
        label +=
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
          (data.datasets[tooltipItem.datasetIndex].labelSuffix || "");

        return label;
      }
    }
  },
};

function Countries({id}) {
  const { t: homeT } = useTranslation('home');
  const [result, setResult] = useState();
  const [label, setLabel] = useState();
  const [status, setStatus] = useState('loading');
  const [numbers, setNumbers] = useState();

  const [data, setData] = useState({
    maintainAspectRatio: false,
    responsive: false,
    labels: label,
    datasets: [
      {
        data: [31, 12, 7, 50],
        backgroundColor: ['#000', '#FFDA7C', '#F97B6A', '#A1D066', '#9D3636', '#F55B6A' ],
        borderRadius: '2px',
        labelSuffix: "%$",
      },
    ],
  });

  async function repeatRequest() {
    let sec = 2500;

    try {
      const response = await network.get('api/dashboard_stats/countries', { 
        params: 
        {website_id: id
        }});
      if (response.status === 200) {
        setResult(response.data.results);

        let topArray = response.data.results?.countries?.sort((a, b) => a[1]-b[1]).reverse().slice(0, 3);
        setLabel(topArray.map(e => e[0]));
        setNumbers(topArray.map(e => Math.floor(e[1])));      
        setStatus('success');
        return response;
      } else {
        throw new Error("Response status not 200");
      }
    } catch (error) {
      sec *= 2;
      setTimeout(repeatRequest, sec);
    }
  }

  useEffect(() => {
    repeatRequest();
    /* eslint-disable */
  }, [id]);
  /* eslint-enable */

  useEffect(() => {
    if(numbers) {
      setData({
        maintainAspectRatio: false,
        responsive: true,
        labels: 100 - numbers.reduce((ac, v) => ac + v, 0) == 0 ? label : label.concat('Other countries'),
        datasets: [
          {
            data: numbers.concat(100 - numbers.reduce((ac, v) => ac + v, 0) ),
            backgroundColor: ['#FFDA7C', '#F97B6A', '#A1D066', '#9D3636', '#F55B6A' ],
            labelSuffix: "%",
            borderRadius: 2,
            fontSize: 9
          },
        ],
      });
    } 
    /* eslint-disable */ 
  }, [numbers]);
  /* eslint-enable */

  return (
    <ChartBlock>
      {status === 'loading' ? (
        <Loader />
      ) : (result?.countries?.length !=0 ? (
        <> 
          <TooltipDark text={homeT('tooltipCountries')} />
          <Doughnut data={data} options={options} />
        </>
      ) : (
        <Flex>
          <NoStatystic />
          <ChartIcon />
        </Flex>
      )
      )}
    </ChartBlock>
  );
}

export default Countries;

Countries.propTypes = {
  id: number
};
