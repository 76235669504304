import { useState, useContext, useEffect } from 'react';
import {
  LeadAvaWrapper,
  Result,
  LeadTitle,
  Table,
  TableTitle,
  Text,
  TextLight,
  TextBlack,
  Check,
  Modal,
  Overlay,
  Block,
  TextBlackThin
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { func, array, bool, number, string, any } from 'prop-types';
import { ReactComponent as Batch } from 'assets/images/batch.svg';
import { ReactComponent as Active } from 'assets/images/batch_active.svg';
import { ReactComponent as HideIcon } from 'assets/images/hide.svg';
import { ReactComponent as Nav } from 'assets/images/navigation.svg';
import BatchBox from './BatchBox';
import Export from './Manage/Export';
import Assign from './Manage/Assign';
// import Move from './Manage/Move';
import Archive from './Manage/Archive';
import Hide from './Manage/Hide';
import Tags from './Manage/Tags';
import Responsive from 'context/responsive';
import Email from './Manage/Email';

const TextLocation = styled(Text)`
  height: 21px;
  color: ${props => props.theme.colors.black};
`;

const BatchWrapper = styled.div`
position: relative;
  a {
    margin-left: 10px;
    cursor: pointer;
  }
  .active {
    a {
      margin-left: 0;
    }
    .disabled {
      cursor: not-allowed;
    }
    z-index: 30;
  }
  .disabled {
    a {
      cursor: not-allowed;
      &:hover {
        background: transparent;
        border-radius: 9px;
      }
      span {
        color: ${(props) => props.theme.colors.lightGrey};
      }
    }
`;

const Logo = styled(LeadAvaWrapper)`
  background-color: #f0f0f073;
  background-size: 102%;
  background-repeat: no-repeat;
  background-position: center;
  `;

const ManageRight = styled.p`
  text-align: center;
  cursor: pointer;
`;

const ManageBox = styled.div`
  position: absolute;
  top: 23px;
  left: 40px;
  .hYkmDi {
    left: -152px;
    width: 110px;
  }
  .iyCZfd {
    z-index: 34;
    right: 59px;
    top: 43px;
  }
  @media (max-width: 768px) {
    right: 25px;
    top: 15px;
    .iyCZfd {
      right: 30px;
      top: 18px;
    }
  }
  &.tabled {
    .active {
      right: 33px;
    }
  }
`;

const Popup = styled(Block)`
  margin: -2% auto 0;
  z-index: 90;
`;

const ModalOver = styled(Modal)`
  z-index: 35;
`;

const TableMain = styled(Table)`
  .clicked {
    background: #F2FCFF;
  }
  td:nth-child(3) {
    width: 35%;
    position: relative;
    a {
      text-decoration: none;
      position: relative;
      z-index: -1;
    }
    .disabled {
      -webkit-pointer-events: none;
      pointer-events: none;
    }
  }
  td:nth-child(4) {
    width: 7%;
  }
  td:nth-child(5) {
    width: 15%;
  }
  p {
    line-height: 1;
  }
  .pml {
    padding: 0 5px;
  }
  .pl {
    padding-left: 5px;
  }
  @media (max-width: 768px) {
    td:nth-child(3) {
      width: 7%;
    }
  }
  #hidden {
    display: none;
  }
`;

const Tr = styled.tr`
position: static;
&::after {
  display: none!important;
}
  td {
    padding: 0 5px!important
    p {
      margin: 8px 0!important;
    }
  }
`;

const Hidden = styled.div`
  width: 50px;
  cursor: pointer;
`;

const MobileCheck = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  input {
    width: 45%;
  }
`;

export default function TableLeads({
  leadsWithCheck,
  handleShowDetails,
  gaViewId,
  success,
  setSuccess,
  leadsMarked, 
  setLeadsMarked,
  checkAll, 
  setCheckAll,
  handleCheckedAll,
  setListChecked,
  maxDate,
  minDate,
  selectAll,
  setSelectAll,
  clickedTr,
  setOne,
  setAddFilter,
  addFilter,
  one,
  queueId,
  leadsHide, setLeadsHide,
  setId,
  idName,
  archived
}) {
  const { t: homeT } = useTranslation('home');

  const [show, setShow] = useState(false);
  const [batch, setBatch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [exp, setExport] = useState(false);
  const [asign, setAsign] = useState(false);
  const [archive, setArchive] = useState(false);
  const [hide, setHide] = useState(false);
  const [email, setEmail] = useState(false);
  const [tags, setTags] = useState(false);
  const [result, setResult] = useState(false);
  
  function handleBatch() {
    if (!one) {
      setBatch(true);
    }
  }

  function handleBatchOff() {
    setBatch();
  }

  const handleShowOff = (idx) => () => {
    setShow((state) => ({
      ...state,
      [idx]: false,
    }));
    setBatch();
    setSuccess();
  };

  const handleShowOne = (idx, id) => () => {
    setShow((state) => ({
      ...state,
      [idx]: !state[idx],
    }));
    setLeadsMarked([id]);
    setOne(true);
  };

  const handleHide = (lead) => () => {
    setHide(true);
    setLeadsMarked([lead?.id]);
    //setLeadsHide(prev => [...prev, lead.id]);
    setShowModal(true);
  };

  function handleShowModalOff() {
    setShowModal(false);
    setBatch(false);
    setExport(false);
    setAsign(false);
    setArchive(false);
    setHide(false);
    setTags(false);
    setEmail(false);
    // setSuccess();
    setShow(false);
    setLeadsMarked([]);
    setOne(false);
    setCheckAll(!checkAll);
    setSelectAll(false);
    setListChecked(
      leadsWithCheck?.map(function (d) {
        d.select = false;
        return d;
      })
    );
    handleOff();
  }

  function handleSuccess() {
    setSuccess(!success);
    setBatch(false);
    setExport(false);
    setAsign(false);
    setArchive(false);
    setHide(false);
    setTags(false);
    setEmail(false);
    setLeadsMarked([]);
    setListChecked(
      leadsWithCheck?.map(function (d) {
        d.select = false;
        return d;
      })
    );
    setCheckAll(false);
    handleOff();
  }

  const ctx = useContext(Responsive);

  function handleOff() {
    setCheckAll(false);
    setLeadsMarked([]);
    leadsWithCheck?.map(function (d) {
      d.select = false;
      return d;
    });  
  }

  useEffect(() => {
    const timerIn = setTimeout(() => {
      setResult(false);
    }, 2200);
    return () => clearTimeout(timerIn);
  }, [result]);

  return (
    <>
      {ctx.isMobile && (
        <MobileCheck>
          <Check onChange={handleCheckedAll} checked={checkAll}></Check>
          <span></span>
          <div>
            <BatchWrapper>
              {(leadsMarked?.length > 0 && !one) ? (
                <a onClick={handleBatch}>
                  <Active />
                </a>
              ) : (
                <a className="disactive">
                  <Batch />
                </a>
              )}
              {batch && (
                <>
                  <BatchBox
                    leadsMarked={leadsMarked}
                    setShowModal={setShowModal}
                    setExport={setExport}
                    setAsign={setAsign}
                    setArchive={setArchive}
                    setHide={setHide}
                    setTags={setTags}
                    setEmail={setEmail}
                    checkAll={checkAll}
                    selectAll={selectAll}
                    archived={archived}
                  />
                </>
              )}
            </BatchWrapper>
          </div>
        </MobileCheck>)}
      <TableMain>
        {batch && <Modal onClick={handleBatchOff}></Modal>}
        {show && <Modal onClick={handleShowModalOff}></Modal>}
        {showModal && (
          <ModalOver>
            <Overlay onClick={handleShowModalOff}></Overlay>
            <Popup>
              {exp && (
                <Export
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={leadsMarked}
                  checkAll={checkAll}
                  selectAll={selectAll}
                  gaViewId={gaViewId}
                  maxDate={maxDate}
                  minDate={minDate}
                  setResult={setResult}
                  queueId={queueId}
                />
              )}
              {asign && (
                <Assign
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  setResult={setResult}
                  leadsMarked={leadsMarked}
                />
              )}
              {email && (
                <Email
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={leadsMarked}
                  setResult={setResult}
                />
              )}
              {archive && (
                <Archive
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={leadsMarked}
                  setResult={setResult}
                  archived={archived}
                  setId={setId}
                />
              )}
              {hide && (
                <Hide
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={leadsMarked}
                  setSuccess={setSuccess}
                  setResult={setResult}
                  setAddFilter={setAddFilter}
                  addFilter={addFilter}
                  setId={setId}
                  setLeadsHide={setLeadsHide}
                  one={one}
                />
              )}
              {tags && (
                <Tags
                  handleShowModalOff={handleShowModalOff}
                  handleSuccess={handleSuccess}
                  leadsMarked={leadsMarked}
                  setResult={setResult}
                />
              )}
              {/* {success && !hide && <Done text={success} />} */}
            </Popup>
          </ModalOver>
        )}   
        <tbody>
          {!ctx.isMobile && (
            <tr>
              <th>
                <Check onChange={handleCheckedAll} checked={checkAll}></Check>
                <span></span>
              </th>
              <th>
                <BatchWrapper>
                  {(leadsMarked?.length > 0 & !one) ? (
                    <a onClick={handleBatch}>
                      <Active />
                    </a>
                  ) : (
                    <a className="disactive">
                      <Batch />
                    </a>
                  )}
                  {batch && (
                    <>
                      <BatchBox
                        leadsMarked={leadsMarked}
                        setShowModal={setShowModal}
                        setExport={setExport}
                        setAsign={setAsign}
                        setArchive={setArchive}
                        setHide={setHide}
                        setTags={setTags}
                        setEmail={setEmail}
                        checkAll={checkAll}
                        selectAll={selectAll}
                        archived={archived}
                      />
                    </>
                  )}
                </BatchWrapper>
              </th>
              <th>
                <TableTitle>{homeT('name')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('views')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('timeSpent')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('source')}</TableTitle>
              </th>
            </tr>
          )}
          {leadsWithCheck?.map((lead, idx) => (
            <tr key={lead.id} className={clickedTr === idx ? 'clicked' : ''}
              id={leadsHide?.includes(lead.id) ? idName : ''}>
              {!ctx.isMobile && (
                <td>
                  {/* eslint-disable */}
                  <Check
                    onChange={function handleChecked(event) {
                      let checked = event.target.checked;
                      setListChecked(
                        leadsWithCheck?.map(function (item) {
                          if (lead.id === item.id) {
                            item.select = checked;
                          }
                          return item;
                        })
                      );
                      const leadMarked = leadsWithCheck.filter(
                        (el) => el.select === true
                      );
                      setLeadsMarked(leadMarked?.map((e) => e.id));
                    
                    }}
                    /*eslint-enable */
                    type="checkbox"
                    checked={lead.select}
                  />
                  <span></span>
                </td>
              )}
              <td>
                <Logo style={{backgroundImage: `url(${lead.logo})`}} >
                  {/* <ImageFluid src={lead.logo} /> */}
                </Logo>
              </td>
              <td onClick={handleShowDetails(lead.id, idx)}>
                <a href={!ctx.isMobile && `/leads/${lead.id}`} rel="noopener" className="disabled">
                  <LeadTitle>{lead.name}</LeadTitle>
                </a>
                <TextLocation> {lead.location}</TextLocation>
                <TextLight> {lead.visit}</TextLight>
                {ctx.isMobile && <Tr>
                  <td>
                    <TextBlack> {homeT('views')}</TextBlack>
                    <TextLight> {lead.count}</TextLight>
                  </td>
                  <td>
                    <TextBlack> {homeT('timeSpent')}</TextBlack>
                    <TextLight> {lead.time}</TextLight>
                  </td>
                  <td>
                    <TextBlack> {homeT('source')} </TextBlack>
                    <TextLight> {lead.source}</TextLight>
                  </td>
                </Tr>}
             
              </td>
              {!ctx.isMobile && (
                <>
                  <td>
                    <TextBlackThin> {lead.count}</TextBlackThin>
                  </td>
                  <td className='pml'>
                    <TextBlackThin> {lead.time}</TextBlackThin>
                  </td>
                  <td className='pl'>
                    <TextBlackThin> {lead.source == '(direct) / (none)' ? 'Direct' : lead.source} </TextBlackThin>
                  </td>
                  <td>
                    <Hidden onClick={handleHide(lead)}>
                      <HideIcon />
                    </Hidden>
                  </td>
                </>
              )}
              {ctx.isMobile && (
                <td>
                  {/* eslint-disable */}
                  <Check
                    onChange={function handleChecked(event) {
                      let checked = event.target.checked;
                      setListChecked(
                        leadsWithCheck?.map(function (item) {
                          if (lead.id === item.id) {
                            item.select = checked;
                          }
                          return item;
                        })
                      );
                      const leadMarked = leadsWithCheck?.filter(
                        (el) => el.select === true
                      );
                      setLeadsMarked(leadMarked?.map((e) => e.id));
                    }}
                    /*eslint-enable */
                    type="checkbox"
                    checked={lead.select}
                  />
                  <span></span>
                </td>
              )}
              <td className={show[idx] ? 'last' : ''}>
                <ManageRight onClick={handleShowOne(idx, lead.id)}>
                  <Nav />
                </ManageRight>
                {show[idx] && (
                  <>
                    <ManageBox className="tabled">
                      <BatchBox
                        leadsMarked={[lead?.id]}
                        setShowModal={setShowModal}
                        setExport={setExport}
                        setBatch={setBatch}
                        setAsign={setAsign}
                        setArchive={setArchive}
                        setHide={setHide}
                        setTags={setTags}
                        setEmail={setEmail}
                        archived={archived}
                        /* eslint-disable */
                        handleShowOff={handleShowOff}
                        /* eslint-enable */
                      />
                    </ManageBox>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        {result && <Result>{result}</Result>}
      </TableMain>
    </>
  );
}

TableLeads.propTypes = {
  leadsWithCheck: array,
  gaViewId: number,
  success: bool,
  setSuccess: func,
  handleShowDetails: func,
  leadsMarked: array, 
  setLeadsMarked: func,
  checkAll: bool, 
  setCheckAll: func,
  handleCheckedAll: func,
  setListChecked: func,
  maxDate: string,
  minDate: string,
  selectAll: bool,
  setOne: func,
  setSelectAll: func,
  clickedTr: number,
  setAddFilter: func,
  addFilter: bool,
  one: any,
  queueId: any,
  leadsHide: array, 
  setLeadsHide: func,
  setId: func,
  idName: any,
  archived: bool
};
