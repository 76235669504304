import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { any, array, bool, func, string } from 'prop-types';
import { makeAsOptions } from 'base/utils';
import { putHidingRule, fetchAllHidingRules } from 'store/slices/hidingRules';
import { setSelectedId } from 'store/slices/gaViewId';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  BlockMargin,
  FilledButton,
  FlexWrapperStart,
  FlexWrapper,
  StyledSelect,
  TitleSmallBlack,
  TextAccentExtraSmall,
  SecondaryBtnHigh
} from 'base/styled';
import network from 'base/network';
import FormTextArea from 'base/components/FormTextArea';

const FlexWrapperStartMiddlePadding = styled(FlexWrapper)`
  padding: 40px 0 10px;
  justify-content: end;
`;

const Label = styled.label`
line-height: 5px!important;
`;

const Upload = styled.label`
  border: none;
  border-radius: 8px;
  display: block;
  padding: 5px 15px;
  color: ${props => props.theme.colors.accentRed}!important;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 38px;
`;

const UploadInput = styled.input`
  display: none;
`;

const Title = styled(TitleSmallBlack)`
  text-align: left;
`;

const Flex = styled(FlexWrapperStart)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

export default function HiddenForm({setShowOff, setShow, setSuccess, name, edit, keyword, lead }) {
  const { t: homeT } = useTranslation('home');
  const { t: hideT } = useTranslation('hidden');
  const [filterKey, setFilterKey] = useState(edit ? keyword : '');
  const [ga_id] = useState(lead?.ga_view.id);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState();
  const { selectedId, ids } = useSelector((state) => state.gaViewIdReducer);
  const dispatch = useDispatch();
  const { page, allhidingRules, status } = useSelector(
    (state) => state.hidingRulesReducer
  );
  
  const filterNameOptions = makeAsOptions(ids, 'id', 'name');

  function handleSelectChange (payload) {
    dispatch(setSelectedId(payload));
  }

  function filterKeyHandler(e) {
    setFilterKey(e.target.value);
  }

  function selectedFileHandler(event) {
    setSelectedFile(event.target.files[0]);
  }

  function filterSubmit(e) {
    e.preventDefault();
    
    if(edit) {
      if (filterKey.length <= 0) { 
        setError('Please enter at least one keyword');
        return;
      } else { 
        dispatch(putHidingRule({status, id: lead.id, google_analytics_view_id_id: ga_id, keywords: filterKey}));
        setFilterKey('');
        //dispatch(fetchAllHidingRules({ page, allhidingRules }));   
        setSuccess(true);
      }
    } 

    if(!edit) {
      if (filterKey.length <= 0 && selectedFile == null) { 
        setError('Please enter at least one keyword');
        return;
      } else {
     
        const post = async () => {
          const formData = new FormData();
          formData.append('google_analytics_view_id_id', selectedId.value);
          formData.append('keywords', filterKey);
        
          if (selectedFile) {
            const csvFile = selectedFile;
            formData.append('csv_list', csvFile);
          }

          const config = {
            headers: {
              'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary1WWeEy3O6odUTKoF',
            },
          };
        
          try {
            const response = await network.post(`/api/hiding_rules`, formData, config);
        
            if (response.status === 201 || response.status === 200) {
              const { data } = response;
              setFilterKey('');
              dispatch(fetchAllHidingRules({ page, allhidingRules }));
              setSuccess('Rule was saved!');
              setShow(false);
              return data;
            } else {
              console.error('Invalid response status:', response.status);
            }
          } catch (error) {
            console.error('Error during POST request:', error);
        
            // Log the response if available
            if (error.response) {
              console.error('Response data:', error.response.data);
            }
          }
        };
        post();
      }
    } 
  }

  return (
    <form onSubmit={filterSubmit}>
      <Title>{homeT('hiddenCompanies')}</Title>
      <Flex>
        <BlockMargin>
          <label>{homeT('appliedTo')}</label>
          {
            <StyledSelect
              value={edit ? filterNameOptions.find((el) => el.label === name) : selectedId}
              options={filterNameOptions}
              onChange={handleSelectChange}
              styles={customStyles}
              label={homeT('filterName')}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
          }
        </BlockMargin>
        <BlockMargin>
          <label>{hideT('keyword')}</label>
          <FormTextArea
            type="text"
            onChange={filterKeyHandler}
            value={filterKey} 
          />
          <Label>{hideT('newLine')}</Label>
        </BlockMargin>
      
        <BlockMargin>
          
          <Upload htmlFor="file" className="custom-file-upload">
            {selectedFile ? selectedFile.name : hideT('choose')}
          </Upload>
          <UploadInput
            id="file"
            type="file"
            onChange={selectedFileHandler}
            accept=".csv"
          />
          <label>{hideT('bulkUpload')}</label>
          {selectedFile && (
            <div>
              <p>Attached File: {selectedFile.name}</p>
              <p>File Size: {selectedFile.size} bytes</p>
            </div>
          )}
        </BlockMargin>
      </Flex>
      <FlexWrapperStartMiddlePadding>
        <TextAccentExtraSmall>{error}</TextAccentExtraSmall>
        <BlockMargin>
          <SecondaryBtnHigh type="reset" onClick={setShowOff} >
            {homeT('cancel')}
          </SecondaryBtnHigh>
        </BlockMargin>
        <BlockMargin>
          <FilledButton type="submit">{hideT('saveRule')}</FilledButton>
        </BlockMargin>
      </FlexWrapperStartMiddlePadding>
    </form>
  );
}

HiddenForm.propTypes = {
  setSuccess: func,
  setShow: func,
  onAddRule: func,
  setShowOff: func,
  name: string, 
  edit: bool, 
  keyword: string,
  hidingRules: array,
  lead: any
};
