import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { any, bool, func } from 'prop-types';
import { makeAsOptions } from 'base/utils';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import {
  BlockMargin,
  FilledButton,
  FlexWrapperStart,
  FlexWrapperStartMiddle,
  OutlinedButton,
  StyledSelect,
  StyledSelectSmall,
  TextAccentSmall,
  TextAccentThin
} from 'base/styled';
import FormTextField from 'base/components/FormTextField';
import { ReactComponent as Plus } from 'assets/images/plus.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import CascadAccount from './CascadAccount';
import { fetchAllAFs, postAFs, putAFilter } from 'store/slices/accFilters';

const FlexWrapperStartMiddlePadding = styled(FlexWrapperStartMiddle)`
  padding-top: 40px;
  button {
    margin-right: 15px
  }
`;

const Wrapper = styled(FlexWrapperStartMiddle)`
  height: 55px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    margin: 10px 0;
    .bsfINW {
      width: 100%;
      min-width: auto;
    }
  }

`;

const Button = styled.div`
  position: absolute;
  right: -35px;
  bottom: 0;
  cursor: pointer;
  width: 30px;
  height: 28px;
`;

const AddButton = styled.div`
 display: flex;
 padding-top: 30px;
 cursor: pointer;
 svg {
  margin: 7px 10px 0 0;
 }
`;

const CascadWrapper = styled.div`
  position: relative;
  &#destroy {
    display: none;
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

const FlexWrap = styled(FlexWrapperStart)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Form = styled.form`
@media (max-width: 768px) {
  width: 100%;
  .equal {
    margin: 10px 0
  }
  .value {
    margin-bottom: 10px;
  }
}
.cascad:nth-child(2) .operator,
.cascad:nth-child(2) .delete {
  display: none;
}
.next .operator {
  display: none;
}
.high {
  max-width: 440px
}
`;

const BlockMarginSelect = styled(BlockMargin)`
  padding-top: 30px;
`;

function generateEmptyCriteria() {
  return {
    key: '',
    operator: '',
    value: '',
    join_operator: 'AND',
    _id: uuidv4()
  };
}

export default function AccountFilterForm({ handle,
  filterAccountEdit,
  edit,
  setAddFilter,
  addFilter,
  setResult
}) {
  const { t: homeT } = useTranslation('home');
  const [filterName, setFilterName] = useState(
    filterAccountEdit ? filterAccountEdit.name : ''
  );
  const [criteria, setCriteria] = useState(
    edit ? filterAccountEdit?.lead_filters_attributes : [generateEmptyCriteria()]
  );
  const [isSelectValue, setIsSelectValue] = useState(false);
  const [newc, setNew] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [filterType, setFilterType] = useState([]);
  const [filterEqual, setFilterEqual] = useState('');
  const [criteriaErrors, setCriteriaErrors] = useState({});
  const [error, setError] = useState('');
  const [high, setHigh] = useState('');
  const { selectedId, ids } = useSelector((state) => state.gaViewIdReducer);

  const dispatch = useDispatch();
  
  const websitesOptions = makeAsOptions(ids, 'id', 'name');

  const [selectedWebsite, setSelectedWebsite] = useState(
    filterAccountEdit
      ? websitesOptions.find((el) => el.value === filterAccountEdit?.ga_view?.id)
      : selectedId
  );

  useEffect(() => {
    if(filterAccountEdit) {
      setFilterName(filterAccountEdit.name);
      setSelectedWebsite(websitesOptions.find((el) => el.value === filterAccountEdit?.ga_view?.id));
      setCriteria(filterAccountEdit?.lead_filters_attributes);
    } else {
      setCriteria([generateEmptyCriteria()]);
    }
    /* eslint-disable */
  },[filterAccountEdit]);
    /* eslint-enable */

  const operatorOptions = [
    {value: 'OR', label: 'or'},
    {value: 'AND', label: 'and'},
  ];

  function handleWebsite(item) {
    setSelectedWebsite(item);
  }

  function filterNameHandler(e) {
    setFilterName(e.target.value);
  }

  function filterValueHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    if(isSelectValue) {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: (Array.isArray(e) ? e.map(x => x.value) : []).toString()
      };
      setCriteria(newCriteria);
      setFilterValue((Array.isArray(e) ? e.map(x => x.value) : []).toString());
      console.log(filterValue);
      console.log(e);
    } else {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: e.target.value
      };
      setCriteria(newCriteria);
      setFilterValue(e.target.value);
    }
  }
 
  function filterTypeHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      key: e.value
    };
    setCriteria(newCriteria);
    setFilterType(e.value);
  }

  function filterEqualHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: e.value
    };
    setCriteria(newCriteria);
    setFilterEqual(e.value);
  }

  function filterOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      join_operator: e.value
    };
    setCriteria(newCriteria);
  }

  function validateForm() {
    let isValid = true;
    const pattern = /(http:\/\/|https:\/\/|http:|https:|www\.)/g;
    if (filterName.trim() === '') {
      setError('Please enter a name');
      isValid = false;
    } else if (!selectedId.value) {
      setError('Please choose a website');
      isValid = false;
    } 

    const criteriaValidationErrors = criteria.map((item) => {
      const errors = {};
      
      if (item.key == "page_path" && pattern.test(item.value)) {
        errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
        isValid = false;
        setHigh('high');
      }

      if ( item.value.trim() === '') {
        errors.value = 'Please enter a value';
        isValid = false;
      }

      if (item.key.trim() === '') {
        errors.key = 'Please choose a filter type';
        isValid = false;
      }

      if (item.operator.trim() === '') {
        errors.operator = 'Please choose an operator';
        isValid = false;
      }
    
      return errors;
    });

    setCriteriaErrors(criteriaValidationErrors);

    return isValid;
  }

  function filterSubmit(e) {
    e.preventDefault();
    
    switch (true) {
    case filterName.length <= 0:
      setError('Please enter name');
      break;
    case !selectedId.value:
      setError('Please choose website');
      break;
    case !filterType:
      setError('Please choose filter type');
      break;
    case !filterEqual:
      setError('');
      break;
    case filterValue.length <= 0:
      setError('');
      break;
    }
    if (!edit && validateForm()) {
      const filterAccountData = {
        name: filterName,
        google_analytics_view_id_id: selectedWebsite.value,
        lead_filters_attributes: criteria
      };
      dispatch(postAFs({...filterAccountData}));
      setAddFilter(!addFilter);
      handle();
      setResult('New filter was added!');
    }

    if (edit && validateForm()) {
      const filterAccountData = {
        name: filterName,
        google_analytics_view_id_id: selectedWebsite.value,
        lead_filters_attributes: criteria
      };
      const Id = filterAccountEdit.id;
      dispatch(putAFilter({ id: Id, body: filterAccountData }));
      dispatch(fetchAllAFs);
      setAddFilter(!addFilter);
      handle();
      setResult('New filter was added!');
    }
  }

  function addFilterCriteria(e) {
    e.preventDefault();
    setCriteria(criteria.concat(generateEmptyCriteria()));
    setNew(true);
  }

  const handleDelete = (item) => () => {
    const updatedCriteria = criteria.map(e =>
      e.id === item.id ? { ...e, _destroy: 'true' } : e
    );
    setCriteria(edit ? updatedCriteria : criteria.filter(e => e._id !== item._id));
  };

  return (
    <Form onSubmit={filterSubmit}>
      <FlexWrap>
        <BlockMargin className='form-field'>
          <FormTextField
            type="text"
            onChange={filterNameHandler}
            value={filterName}
            label={homeT('filterName')}
          />
        </BlockMargin>
        <BlockMarginSelect className='form-field'>
          {
            <StyledSelect
              placeholder={homeT('website')}
              value={selectedWebsite}
              options={websitesOptions}
              onChange={handleWebsite}
              styles={customStyles}
              width="300px"
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
          }
        </BlockMarginSelect>
      </FlexWrap>
      {criteria?.filter(e => !e._destroy)?.map((item, index) => (
        
        <CascadWrapper key={index}
          className='cascad'
          id={item._destroy == 'true' ? 'destroy' : ''}>
          <Wrapper className="operator">
            
            {
              <StyledSelectSmall
                placeholder={homeT('or')}
                options={operatorOptions}
                defaultValue={ operatorOptions.find((el) => el.value === item.join_operator) }
                onChange={(e) => filterOperatorHandler(e, item)}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
              />
            }
          </Wrapper>
          <CascadAccount
            handlerType={filterTypeHandler}
            handlerEqual={filterEqualHandler}
            handlerValue={filterValueHandler}
            filterValue={filterValue}
            styles={customStyles}
            index={index}
            item={item}
            edit={edit}
            setIsSelectValue={setIsSelectValue}
            newc={newc}
          />
          <Button className={(criteria.length >1 && edit) ? 'active' : 'delete'} onClick={handleDelete(item)}>
            <Delete />
          </Button>
        </CascadWrapper>
      ))}
      {criteriaErrors?.length > 0 && criteriaErrors?.map((errors, index) => (
        <div key={index} className={high}>
          {errors.key && <TextAccentSmall className={errors ? 'relative' : 'relative'}>{errors.key}</TextAccentSmall>}
          {errors.operator && (
            <TextAccentSmall className={errors ? 'relative' : 'relative'}>{errors.operator}</TextAccentSmall>
          )}
          {errors.value && <TextAccentSmall className={errors ? 'relative' : 'relative'}>
            {errors.value}</TextAccentSmall>}
        </div>
      ))}
      <FlexWrapperStart>
        <TextAccentSmall>{error}</TextAccentSmall>
        <AddButton onClick={addFilterCriteria}>
          <Plus />
          <TextAccentThin>{homeT('addFilterCriteria')}</TextAccentThin>
        </AddButton>
      </FlexWrapperStart>
      <FlexWrapperStartMiddlePadding>    
        <FilledButton type="submit">{homeT('saveFilter')}</FilledButton>
        <OutlinedButton type="reset" onClick={handle}>
          {homeT('cancel')}
        </OutlinedButton>
      </FlexWrapperStartMiddlePadding>
    </Form>
  );
}

AccountFilterForm.propTypes = {
  filterAccountEdit: any,
  edit: bool,
  handle: func,
  setAddFilter: func,
  addFilter: bool,
  setResult: func
};