import { useState, useEffect, useMemo } from 'react';
import { any, array, bool, func, object } from 'prop-types';
import {
  BlockMargin,
  FlexWrapperStartMiddle,
  StyledSelect,
  TextLight
} from 'base/styled';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import { useTranslation } from 'react-i18next';
import FormTextFieldTime from 'base/components/FormTextFieldTime';
import Location from './Location';

const Wrapper = styled(FlexWrapperStartMiddle)`
  position: relative;
  min-height: 65px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    margin: 10px 0;
    .bsfINW {
      width: 100%;
      min-width: auto;
    }
  }
  label {
    line-height: 16px!important
  }
`;

const BlockMarginRelative = styled(BlockMargin)`
  position: relative;
`;

const TextLightAbsolute = styled(TextLight)`
  position: absolute;
  top: 47px;
  font-weight: 400!important
`;

const Block = styled(BlockMargin)`
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

function CascadAccount({
  handlerType,
  handlerEqual,
  handlerValue,
  edit,
  styles,
  item,
  setIsSelectValue,
  gic
}) {
  const { t: homeT } = useTranslation('home');
  const [selected, setSelected] = useState(edit ? item.key : '');
  const [input, setInput] = useState(true);
  const [time, setTime] = useState(false);
  const [location, setLocation] = useState(false);
  
  function changeSelectOptionHandler(event, item) {
    setSelected(event.value);
    handlerType(event, item);
  }
 
  const data = [
    { value: 'page_path', label: 'Page Path' },
    { value: 'query', label: 'Query String' },
    { value: 'country', label: 'Visitor Location Country' },
    { value: 'region', label: 'Visitor Location State/Province' },
  ];

  const filterTypesMap = useMemo(() => ({
    page_path: {value: 'page_path', label: 'Page Path'},
    query: {value: 'query', label: 'Query String'},
    country: {value: 'country', label: 'Visitor Location Country'},
    region: {value: 'region', label: 'Visitor Location State/Province'}
  }), []);

  useEffect(() => {
    setSelected(item.key);
  }, [item]);

  const equalsOptions = [
    { value: '=' || 'eq', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: 'contains', label: 'contains' },
    { value: 'does not contain', label: 'does not contain' },
  ];

  const [type, setType] = useState(equalsOptions);
  const [value] = useState(null);

  useEffect(() => {
    if (
      selected === 'page_path' ||
      selected === 'query' 
    ) {
      setInput(true);
      setTime(false);
      setType(equalsOptions);
      setIsSelectValue(false);
      setLocation(false);
    } else if (
      selected === 'country' ||
      selected === 'region' 
    ) {
      setInput(false);
      setTime(false);
      setType(equalsOptions);
      setIsSelectValue(false);
      setLocation(true);
      //setTree(true);
      setIsSelectValue(true);
      //setValue(naics);
    }
    /* eslint-disable */
  }, [item, selected]);
  /* eslint-enable */

  return (
    <Wrapper>
      <Block className='form-field'>
        <StyledSelect
          placeholder="Filter Type"
          options={Object.values(filterTypesMap)}
          onChange={(e) => changeSelectOptionHandler(e, item)}
          styles={styles}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
          defaultValue={edit ? data.find((el) => el.value === item.key) : ''}
          value={filterTypesMap[selected]}
        />
      </Block>
      <Block className='form-field'>
        {
          <StyledSelect
            options={type}
            onChange={(e) => handlerEqual(e, item)}
            styles={styles}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            defaultValue={edit ? type.find((el) => el.value === item.operator) : ''}
          />
        }
      </Block>
      <BlockMarginRelative className='form-field'>
        {input ? (
          <>
            {time ? (
              <>
                <FormTextFieldTime label={homeT('min')} />
                <FormTextFieldTime label={homeT('sec')} />
              </>
            ) : (
              <FormTextField
                type="text"
                onChange={(e) => handlerValue(e, item)}
                value={item.value}
                label={homeT('value')}
                // defaultValue={item.value}
              />
            )}
            {!time && (
              <TextLightAbsolute>{homeT('useCommas')}</TextLightAbsolute>
            )}
          </>
        ) : location ? (
          <Location
            data={data}
            styles={styles}
            selected={selected}
            item={item}
            handlerValue={handlerValue}
            edit={edit}
            defaultValue={edit ? item.value : ''} 
          />
        ) : (
          <>
            <StyledSelect
              options={value}
              isMulti
              styles={styles}
              onChange={(e) => handlerValue(e, item)}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              defaultValue={
                edit
                  ? gic.filter(({ value }) =>
                    item.value.split(',').includes(value)
                  )
                  : ''
              }
            />
          </>
        )}
      </BlockMarginRelative>
    </Wrapper>
  );
}
  
export default CascadAccount;

CascadAccount.propTypes = {
  handlerType: func, 
  handlerEqual: func,
  handlerValueInput: func, 
  handlerValue: func,
  styles: object,
  edit: bool,
  item: any,
  users: array,
  setIsSelectValue: func,
  newc: bool,
  gic: array,
  naics: array
};