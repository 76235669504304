import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { string, number, any } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Modal,
  ThemeButton,
  ThemeLink,
  Block
} from 'base/styled';
import { ReactComponent as Google } from 'assets/images/google_logo.svg';
import { ReactComponent as Slack } from 'assets/images/slackBtn.svg';
import { ReactComponent as Sales } from 'assets/images/sales.svg';
import network from 'base/network';
import Hubspot from './Hubspot';
import SugarCrm from './SugarCrm';

const Button = styled(Flex)`
  margin-top: 10px;
  flex-wrap: wrap;
  a,
  button {
    margin: 10px 10px 5px 0;
  }
  @media (max-width: 1180px) {
    margin-top: 40px;
    a {
      white-space: nowrap;
    }
  }
`;

const PopupBtn = styled(Block)`
  margin: -2% auto 0;
  padding: 20px 0 0px;
  width: 190px;
`;

const ModalBtn = styled.div`
  position: absolute;
  top: 105px;
  left: 100px;
  z-index: 25;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  a {
    font-size: .75rem;
    color: inherit;
    text-decoration: none;
    margin-bottom: 15px;
  }
`;

export default function Index({ id, leadName, leadLocation, website, integrations, enabled}) {
  const { t: homeT } = useTranslation('home');
  const [slack, setSlack] = useState();
  const [salesforce, setSalesforce] = useState();
  const [openHubspot, setOpenHubspot] = useState(false);

  useEffect(() => {
    setSlack(homeT('sendSlack'));
    setSalesforce(homeT('sendSales'));
  }, [homeT, id]);

  const postSlack = async () => {
    setSlack('Sending...');
    let res = await network
      .post(`/api/send_to_slack`, {id: id})
      .then(({ data }) => {
        setSlack('lead sent to Slack!');
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  const postSalesforce = async () => {
    setSalesforce('Sending...');
    await network
      .post(`/api/send_to_salesforce`, {id: id})
      .then(({ data }) => {
        setSalesforce('lead sent to SalesForce!');
        return data;
      })
      .catch((error) => console.log(error));
  };

  function handleSlack() {
    postSlack();
  }

  function handleSalesforce() {
    postSalesforce();
  }

  return (
    <Flex>
      <Button className="btn">
        <ThemeLink
          target="_blank"
          rel="noreferrer"
          href={`https://www.google.com/search?q=` + leadName + leadLocation}
        >
          <Google />
          {homeT('googleLead')}
        </ThemeLink>
        {integrations?.find(
          (e) => e.title.includes('Hubspot')
        )?.connected && enabled && (
          <Hubspot id={id} leadName={leadName} website={website} />
        )}
        {integrations?.find(
          (e) => e.title.includes('SugarCRM')
        )?.connected && (
          <SugarCrm id={id} leadName={leadName} website={website} />
        )}
        {integrations?.find(
          (e) => e.title.includes('Salesforce')
        )?.connected && (
          <ThemeButton onClick={handleSalesforce}>
            <Sales />
            {salesforce}
          </ThemeButton>
        )}
        {integrations?.find(
          (e) => e.title.includes('Slack')
        )?.connected && (
          <ThemeButton onClick={handleSlack}>
            <Slack />
            {slack}
          </ThemeButton>
        )}
 
      </Button>
      {openHubspot && (
        <>
          <Modal onClick={() => setOpenHubspot(false)}></Modal>
          <ModalBtn>
            <PopupBtn>
              <Links>
                <a>{homeT('findPeople')}</a>
                <a>{homeT('findSales')}</a>
              </Links>
            </PopupBtn>
          </ModalBtn>
        </>
      )}
    </Flex>
  );
}

Index.propTypes = {
  id: number,
  leadName: string,
  leadLocation: string,
  website: string,
  integrations: any,
  enabled:any
};
