import styled from 'styled-components';

const GradientButton = styled.button.attrs(props => ({
  type: props.type || 'button'
}))`
  font-family: inherit;
  font-weight: 500;
  padding: 6px 14px;
  font-size: .875rem;
  height: 37px;
  border-radius: 18px;
  border: none;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  background: linear-gradient(95.14deg, #F96652 8.9%, #F2BD34 107.73%);
  white-space: nowrap;
  svg {
    margin-right: 8px;
  }
  @media (max-width: 830px) {
    svg {
      margin-right: 4px;
    }
  }
`;

export default GradientButton;
