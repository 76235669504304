import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { any, func, number, string } from 'prop-types';
import {
  AccentButton,
  ButtonsWrap,
  Loading,
  RadioListCircle,
  SecondaryBtnHigh,
  TextBlackBig,
  TextBlackThin,
} from 'base/styled';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import network from 'base/network';

const Wrapper = styled.div`
  @media (max-width: 768px) {
    p {
      width: auto;
      max-width: fit-content !important;
    }
  }
`;

const Radio = styled(RadioListCircle)`
  padding: 0 20px 20px;
  label {
    font-size: 0.875rem;
    font-weight: 600;
  }
  div {
    padding: 5px 0;
  }
`;

const Box = styled.div`
  width: 300px;
  text-align: center;
  margin: 0 auto;
  p {
    line-height: 1;
  }
`;

const Content = styled.div`
  padding: 1px 10px 10px 20px;
`;

const Load = styled(Loading)`
  min-height: 120px;
  margin-bottom: 10px;
  min-width: 240px;
`;

export default function Export({
  handleShowModalOff,
  leadsMarked,
  gaViewId,
  maxDate,
  minDate,
  setResult,
  selectAll,
  queueId
}) {
  const { t: popupT } = useTranslation('popup');
  const { t: rootT } = useTranslation('');
  const [radio, setRadio] = useState('company_info');
  const [exportAll, setExportAll] = useState(false);
  const dispatch = useDispatch();
  const [process, setProcess] = useState();
  const [types, setTypes] = useState();

  const getTypes = async () => {
    let res = await network
      .get(`/api/account/details`, {})
      .then((response) => {
        setTypes(response.data.results.export_types);
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  useEffect(() => {
    getTypes();
    /* eslint-disable */
  }, [dispatch]);
  /* eslint-enable */

  function handleSubmit() {
    setProcess(true);
    const getFiles = async () => {
      let res = await network
        .post(
          `/api/leads/export`,
          { responseType: 'blob' },
          {
            params: {
              ga_view_id: gaViewId,
              selected_ids: leadsMarked.join(),
              export_type: radio,
            },
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'export.csv');
          document.body.appendChild(link);
          link.click();
          setProcess(false);
        })
        .catch((error) => console.log(error));
      console.log(res);
      setResult(popupT('exported'));
      //handleSuccess(false);
      handleShowModalOff();
    };

    const getAllFiles = async () => {
      let res = await network
        .post(`api/leads/export/all`, {
          ga_view_id: gaViewId,
          queue_id: queueId,
          min_date: maxDate,
          max_date: minDate,
          export_type: radio,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
      console.log(res);
      setResult(popupT('exported'));
      //handleSuccess(false);
      // handleShowModalOff();
    };

    if (selectAll) {
      getAllFiles();
      setExportAll(true);
    } else {
      getFiles();
    }
  }

  function handleChange(e) {
    setRadio(e.target.value);
  }

  return (
    <Wrapper>
      {exportAll ? (
        <Box>
          <TextBlackThin>{popupT('exportAll')}</TextBlackThin>
          <SecondaryBtnHigh onClick={handleShowModalOff}>
            {rootT('ok')}
          </SecondaryBtnHigh>
        </Box>
      ) : (
        <>
          <TextBlackBig style={{ textAlign: 'center' }}>
            {rootT('export')}
          </TextBlackBig>
          <Content>
            <TextBlackThin>{popupT('chooseExport')}</TextBlackThin>
            {!process ? (
              types ? (
                <Radio>
                  {types?.map((e, idx) => (
                    <div key={idx}>
                      <input
                        type="radio"
                        checked={radio === e}
                        id={e}
                        value={e}
                        onChange={handleChange}
                      />
                      <label className="radio" htmlFor={e}>
                        {(e == 'company_info' && popupT('companyInfo')) ||
                          (e == 'contact_info' && popupT('contactInfo')) ||
                          (e == 'ga_user_id_info' && popupT('userInfo'))}
                      </label>
                    </div>
                  ))}
                </Radio>
              ) : (
                <Load />
              )
            ) : (
              <Load />
            )}
            <ButtonsWrap>
              <SecondaryBtnHigh onClick={handleShowModalOff}>
                {rootT('cancel')}
              </SecondaryBtnHigh>
              <AccentButton onClick={handleSubmit}>
                {rootT('export')}
              </AccentButton>
            </ButtonsWrap>
          </Content>
        </>
      )}
    </Wrapper>
  );
}

Export.propTypes = {
  handleShowModalOff: func,
  handleSuccess: func,
  leadsMarked: any,
  checkAll: any,
  gaViewId: number,
  maxDate: string,
  minDate: string,
  setResult: func,
  selectAll: any,
  queueId: number
};
