import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Responsive from 'context/responsive';
import { Flex, TextBlackSmall } from 'base/styled';
import network from 'base/network';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    z-index: 90;
    background: #FFF7DA;
    color: #FEFDFD;
    width: 100%;
    height: 55px;
    padding:15px 20px;
    display: flex;
    justify-content: center;
    p {
      margin: 10px 0;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }
`;

const Button = styled.a`
  font-size: .75rem; 
  font-weight: 600;
  outline: none;
  background: #BABABA;
  border-radius: 8px;
  color: #2C2C2C;;
  cursor: pointer;
  text-decoration: none;
  padding: 6px 14px;
  margin-left: 10px;
  height: 28px;
  margin-top: 4px;
`;

export default function Reactivate() {
  const { t: generalT } = useTranslation();
  const ctx = useContext(Responsive);
  const [clicked, setClicked] = useState(false);

  function handleReactivate() {
    setClicked(true);
    network
      .put('/api/account/reactivate')
      .then(({ data }) => {
        ctx.setActive(true);
        window.location.reload();
        return data;
      })
      .catch((error) => console.log(error));
  }
 
  return (
    <Wrapper>
      <div>
        <TextBlackSmall>{generalT('deactivated')}</TextBlackSmall>
      </div>
      <Flex>
        <Button onClick={handleReactivate}>{clicked ? 'sending...' : generalT('reactivate')}</Button>
      </Flex>
    </Wrapper>
  );
}
