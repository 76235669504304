import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AccentButton, 
  Block, Flex, SecondaryBtnHigh, StyledSelect, TextBlackBig, TextGreyBold } from 'base/styled';
import { ReactComponent as Close } from 'assets/images/close.svg';
import network from 'base/network';
import { makeAsOptions } from 'base/utils';

const Popup = styled(Block)`
  margin: 0 auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    margin: 0 auto;
  }
  button {
    height: 37px;
    margin-right: 5px;
  }
  .center {
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  form {
    width: 100%;
    text-align: left;
    .center {
      text-align: center;
      font-size: .85rem;
      line-height: 1.3rem;
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 0;
    }
    .bold {
      margin: 11px;
    }
  }
`;

const Title = styled(Flex)`
  p {
    padding: 8px 0;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 20px;
  }
  svg {
    margin-top: 18px;
  }
`;

const Buttons = styled.div`
  text-align: right;
  margin-top: 20px;
`;

const Notice = styled.div`
  padding: 10px;
  margin-top: 10px;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  p {
    font-size:.7rem;
    margin: 0;
    padding: 2px 0;
  }
`;

const Select = styled(StyledSelect)`
  margin-right: 0;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

export default function HubspotForm({handleFormOff}) {
  const { t: homeT } = useTranslation('home');
  const [choosed, setChoosed] = useState();
  const [result, setResult] = useState(false);
  const [enabled, setEnabled] = useState();
  const [value, setValue] = useState();

  const { ids } = useSelector((state) => state.gaViewIdReducer);
  const { details } = useSelector(
    (state) => state.accountReducer
  );

  const handleChoosed = (e) => {
    setChoosed(Array.isArray(e) ? e.map(x => x.value) : []);
    setValue(e);
  };
  
  const options = makeAsOptions(ids, 'website_id', 'name');

  const postAccount = async () => {
    let res = await network
      .put(`/api/account/hubspot_enabled_websites`, { 
        website_ids: choosed.toString()
      })
      .then(({ data }) => {
        setResult(data.message);
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  function handleSubmit(e) {
    e.preventDefault();
    postAccount();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if(result){
        setResult(false);
        handleFormOff();
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [result, handleFormOff]);

  useEffect(() => {
    if(details?.settings?.hubspot_enabled_website) {
      setEnabled(ids.filter(e => 
        details?.settings?.hubspot_enabled_website.includes(e.website_id)));
    }
  }, [details, ids]);

  return (
    <Popup>
      {result ? (
        <>
          {/* <Icon /> */}
          <TextGreyBold className="center">{result}</TextGreyBold>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Title>
            <TextBlackBig>
              {homeT('accessHubspot')}
            </TextBlackBig>
            <Close onClick={handleFormOff}/>
          </Title>
          <Select
            value={value || makeAsOptions(enabled, 'website_id', 'name') || value}
            options={options}
            onChange={handleChoosed}
            styles={customStyles}
            menuPlacement="bottom"
            isMulti
          />
          <Notice>
            <p>{homeT('selectWebsite')}</p>
          
          </Notice>
          <Buttons>
            <SecondaryBtnHigh onClick={handleFormOff}>
              {homeT('cancel')}
            </SecondaryBtnHigh>
            <AccentButton type="submit">Connect Now</AccentButton>
          </Buttons>
        </form>
      )}
    </Popup>
  );
}

HubspotForm.propTypes = {
  handleFormOff: func
};
