import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { any, array, bool, func, node, number, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeAsOptions } from 'base/utils';
import { postAssignRule, putAssignRule } from 'store/slices/assignRules';
import FormTextField from 'base/components/FormTextField';
import Cascad from './Cascad';
import styled from 'styled-components';
import {
  BlockMargin,
  FilledButton,
  FlexWrapperStart,
  FlexWrapperStartMiddle,
  SecondaryBtnHigh,
  StyledSelect,
  StyledSelectSmall,
  TextAccentSmall,
  TextAccentThin,
} from 'base/styled';
import { ReactComponent as Plus } from 'assets/images/plus.svg';
import { ReactComponent as Delete } from 'assets/images/delete.svg';
import { fetchUsers } from 'store/slices/users';
import { debounce } from 'lodash';


const FlexWrapperStartMiddlePadding = styled(FlexWrapperStartMiddle)`
  padding-top: 40px;
  button {
    margin-right: 15px;
  }
`;

const FlexWrap = styled(FlexWrapperStart)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Form = styled.form`
  input {
    color: #1D1D1D;
    font-size: .8rem;
    font-family: 'Montserrat', sans-serif;
  }
  .pt {
    padding-top: 30px;
  }

  @media (min-width: 1280px) {
    .form-field {
      width: 210px;
    }
    input.name {
      width: 210px;
    }
    .pt {
      width: 210px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  .cascad:nth-child(2) .operator,
  .cascad:nth-child(2) .delete {
    display: none;
  }
  .high {
    max-width: 440px
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

const CascadWrapper = styled.div`
  position: relative;
  &#destroy {
    display: none;
  }
`;

const Wrapper = styled(FlexWrapperStartMiddle)`
  height: 55px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    margin: 10px 0;
    .bsfINW {
      width: 100%;
      min-width: auto;
    }
  }
`;

const Button = styled.div`
  position: absolute;
  right: -25px;
  bottom: 0;
  cursor: pointer;
  width: 30px;
  height: 28px;
`;

const AddButton = styled.div`
 display: flex;
 padding-top: 30px;
 cursor: pointer;
 p {
   margin: 12px 0;
 }
 svg {
  margin: 7px 10px 0 0;
 }
`;

const BlockMarginSelect = styled(BlockMargin)`
  padding-top: 30px;
`;

function generateEmptyCriteria() {
  return {
    key: '',
    operator: '',
    value: '',
    join_operator: 'AND',
    multiple_filter: {
      value_to: '',
      value_from: ''
    },
    _id: uuidv4()
  };
}

export default function AutoAssignForm({
  setShowOff,
  setSuccess,
  edit,
  name,
  website,
  user,
  users,
  filters, 
  lead,
  gic, 
  naics
}) {
  const { t: homeT } = useTranslation('home');
  const [filterName, setFilterName] = useState(edit ? name : '');
  const [filterValue, setFilterValue] = useState(edit ? filters[0].value : '');
  const [filterType, setFilterType] = useState(edit ? filters && filters[0].key : '');
  const [filterEqual, setFilterEqual] = useState(edit ? filters && filters[0].operator : '');
  const [isSelectValue, setIsSelectValue] = useState(false);
  const [criteria, setCriteria] = useState(
    edit ? lead?.lead_filters_attributes : [generateEmptyCriteria()]
  );
  const [error, setError] = useState();
  const [high, setHigh] = useState('');
  const { selectedId, ids } = useSelector((state) => state.gaViewIdReducer);
  const dispatch = useDispatch();
  const [criteriaErrors, setCriteriaErrors] = useState({});

  const websitesOptions = makeAsOptions(ids, 'id', 'name');

  const optionsUsers = makeAsOptions(users, 'id', 'full_name');

  const [filterUser, setFilterUser] = useState(
    edit ? optionsUsers.find((el) => el.label === user) : ''
  );
  const [selectedWebsite, setSelectedWebsite] = useState(
    edit ? websitesOptions.find((el) => el.label === website) : selectedId
  );
  const [newVisitor, setNewVisitor] = useState(false);
  const [between, setBetween] = useState(false);

  const operatorOptions = [
    {value: 'OR', label: 'or'},
    {value: 'AND', label: 'and'},
  ];

  function handleSubmit(formFields) {
    dispatch(postAssignRule(formFields));  
  }

  function handleSubmitEdit(id, body) {
    dispatch(putAssignRule({id, body}));  
  }

  function handleWebsite(lead) {
    setSelectedWebsite(lead);
  }

  function filterNameHandler(e) {
    setFilterName(e.target.value);
  }

  function filterUserHandler(lead) {
    setFilterUser(lead);
  }

  function filterValueHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    if(isSelectValue) {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: (Array.isArray(e) ? e.map(x => x.value) : []).toString()
      };
      setCriteria(newCriteria);
      setFilterValue((Array.isArray(e) ? e.map(x => x.value) : []).toString());
    } else {
      newCriteria[currentItemIndex] = {
        ...newCriteria[currentItemIndex],
        value: e.target.value
      };
      setCriteria(newCriteria);
      setFilterValue(e.target.value);
    }
  }
  
  function filterTypeHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      key: e.value
    };
    setCriteria(newCriteria);
    setFilterType(e.value);
  }

  function filterEqualHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      operator: e.value
    };
    setCriteria(newCriteria);
    setFilterEqual(e.value);
  }

  function filterOperatorHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      join_operator: e.value
    };
    setCriteria(newCriteria);
  }
  
  function ValueFromHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        value_from: e.target.value
      },  
    };
    setCriteria(newCriteria);
  }
  
  function ValueToHandler(e, item) {
    const newCriteria = [...criteria];
    const currentItemIndex = newCriteria.indexOf(item);
    newCriteria[currentItemIndex] = {
      ...newCriteria[currentItemIndex],
      multiple_filter: {
        ...newCriteria[currentItemIndex].multiple_filter,
        value_to: e.target.value
      },  
    };
    setCriteria(newCriteria);
    setBetween(true);
  }
 
  function filterSubmit(e) {
    e.preventDefault();

    switch (true) {
    case filterName.length <= 0:
      setError('Please enter name');
      break;
    case filterUser.length <= 0:
      setError('Please choose user');
      break;
    case filterType.length <= 0:
      setError('Please choose filter type');
      break;
    case filterValue.length <= 0 && !newVisitor && !between:
      setError('Please choose value');
      break;
    case between && criteria[0].multiple_filter.value_from == '' && criteria[0].multiple_filter.value_to == '':
      setError('Please choose values');
      break;
    }

    if (!edit && validateForm()) {
      const postUserData = {
        name: filterName,
        google_analytics_view_id_id: selectedId.value,
        website: selectedId.label,
        user_id: filterUser.value,
        lead_filters_attributes: criteria,
      };

      handleSubmit(postUserData);
      setFilterName('');
      setFilterType('');
      setFilterEqual('');
      setFilterValue('');
      setFilterUser('');
      setSuccess(true);
    }
    if (edit && validateForm()) {
      const data = {
        name: filterName,
        google_analytics_view_id_id: lead.ga_view.id,
        user_id: filterUser?.value,
        lead_filters_attributes: criteria,
      };
    
      handleSubmitEdit(lead.id, data);
      setSuccess(true);
    }  
  }

  function validateForm() {
    let isValid = true;
    const pattern = /(http:\/\/|https:\/\/|http:|https:|www\.)/g;
    if (filterName.trim() === '') {
      setError('Please enter a name');
      isValid = false;
    } else if (!selectedId.value) {
      setError('Please choose a website');
      isValid = false;
    } else if (filterUser === '') {
      setError('Please choose users');
      isValid = false;
    } 

    const criteriaValidationErrors = criteria?.map((item) => {
      const errors = {};
      
      if (item.key == "page_path" && pattern.test(item.value)) {
        setHigh('high');
        errors.value = 'Please include page path without domain name, like "/contact_us", but not "https://abc.com/contact_us"';
        isValid = false;
      }

      if (item.key.trim() === '') {
        errors.key = 'Please choose a filter type';
        isValid = false;
      }

      if (item.operator.trim() === '') {
        errors.operator = 'Please choose an operator';
        isValid = false;
      }
    
      return errors;
    });

    setCriteriaErrors(criteriaValidationErrors);

    return isValid;
  }

  const handleDelete = (item) => () => {
    const updatedCriteria = criteria?.map(e =>
      e.id === item.id ? { ...e, _destroy: 'true' } : e
    );
    setCriteria(edit ? updatedCriteria : criteria.filter(e => e._id !== item._id));
  };

  function addFilterCriteria(e) {
    e.preventDefault();
    setCriteria(criteria.concat([generateEmptyCriteria()]));
  }
  /* eslint-disable */
  const delayedQuery = useCallback(
    debounce((inputValue) => {
      if (inputValue.length > 2) {
        dispatch(fetchUsers({ query: inputValue, per_page: 20 }));
      }
    }, 300), [dispatch]
  );
  /* eslint-enable */
  function handleInputChange(inputValue) {
    delayedQuery(inputValue);
  }

  useEffect(() => {
    dispatch(fetchUsers({}));
  }, [dispatch]); 

  return (
    <Form onSubmit={filterSubmit}>
      <FlexWrap>
        <BlockMargin className='form-field'>
          <FormTextField
            type="text"
            onChange={filterNameHandler}
            value={filterName}
            label={homeT('filterName')}
            className='name'
          />
        </BlockMargin>
        <BlockMarginSelect className=''>
          {
            <StyledSelect
              placeholder={homeT('website')}
              value={selectedWebsite}
              options={websitesOptions}
              onChange={handleWebsite}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
          }
        </BlockMarginSelect>
        <BlockMarginSelect className='pt'>
          {
            <StyledSelect
              placeholder={homeT('users')}
              options={optionsUsers}
              styles={customStyles}
              menuPlacement="bottom"
              value={filterUser}
              onChange={filterUserHandler}
              onInputChange={handleInputChange}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
          }
        </BlockMarginSelect>
      </FlexWrap>
      {criteria?.map((item, index) => (
        <CascadWrapper key={index} className="cascad" id={item._destroy == 'true' ? 'destroy' : ''}>
          <Wrapper className="operator">
            {
              <StyledSelectSmall
                placeholder={homeT('or')}
                options={operatorOptions}
                defaultValue={operatorOptions.find(
                  (el) => el.value === item.join_operator
                )}
                onChange={(e) => filterOperatorHandler(e, item)}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
              />
            }
          </Wrapper>
          <Cascad
            handlerType={filterTypeHandler}
            handlerEqual={filterEqualHandler}
            handlerValue={filterValueHandler}
            filterValue={filterValue}
            styles={customStyles}
            filterType={filterType}
            filterEqual={filterEqual}
            filters={filters}
            setIsSelectValue={setIsSelectValue}
            gic={gic}
            edit={edit}
            item={item}
            naics={naics}
            setNewVisitor={setNewVisitor}
            ValueFromHandler={ValueFromHandler}
            ValueToHandler={ValueToHandler}
          />
          <Button className="delete" onClick={handleDelete(item)}>
            <Delete />
          </Button>
        </CascadWrapper>
      ))}
      <TextAccentSmall>{error}</TextAccentSmall>
      {criteriaErrors?.length > 0 && criteriaErrors?.map((errors, index) => (
        <div key={index} className={high}>
          {errors.key && <TextAccentSmall className={errors ? 'relative' : 'relative'}>{errors.key}</TextAccentSmall>}
          {errors.operator && (
            <TextAccentSmall className={errors ? 'relative' : 'relative'}>{errors.operator}</TextAccentSmall>
          )}
          {errors.value && <TextAccentSmall className={errors ? 'relative' : 'relative'}>
            {errors.value}</TextAccentSmall>}
        </div>
      ))}
      <FlexWrapperStart>
        <AddButton onClick={addFilterCriteria}>
          <Plus />
          <TextAccentThin>{homeT('addFilterCriteria')}</TextAccentThin>
        </AddButton>
      </FlexWrapperStart>
     
      <FlexWrapperStartMiddlePadding>
        <SecondaryBtnHigh type="reset" onClick={setShowOff}>
          {homeT('cancel')}
        </SecondaryBtnHigh>
        <FilledButton type="submit">{homeT('saveFilter')}</FilledButton>
      </FlexWrapperStartMiddlePadding>
    </Form>
  );
}

AutoAssignForm.propTypes = {
  setSuccess: func,
  onAddRule: func,
  setShowOff: func,
  setShow: func,
  edit: bool, 
  name: string, 
  website: string, 
  user: string, 
  users: array,
  id: number,
  ga_view: object,
  item: any, 
  lead: any,
  filters: node,
  gic: array,
  naics: array
};
