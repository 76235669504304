import { Block, TitleSmallBlack } from 'base/styled';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as Icon } from 'assets/images/saved.svg';
import { string } from 'prop-types';

const BlockMargin = styled(Block)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 70px;
  max-width: 500px;
  h1.center {
    text-align: center;
  }
`;

export default function Success({ text }) {
  const { t: generalT } = useTranslation();
  
  return (
    <BlockMargin>
      <Icon />
      <TitleSmallBlack className='center'>
        {text || generalT('saved')}
      </TitleSmallBlack>
    </BlockMargin>
  );
}

Success.propTypes = {
  text: string
};
