import styled from 'styled-components';

const Sidebar = styled.aside`
  max-width: 136px;  
  position: fixed;
  left: 0;
  top: 70px;
  padding-top: 60px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 87%;
  transition: .5s;
  &#accent {
    backdrop-filter: blur(5px);
    background: rgba(255, 238, 213, 0.7);
    border-radius: 14px;
    min-width: 368px;
    transition: .5s;
    z-index: 11;

  }
  @media (min-width: 1000px) {
    width: 120%;
    margin: 0 15px 0 10px;
    background: #fff;
    border-radius: 10px;
    padding-top: 120px;
  }
  @media (max-width: 768px) {
    max-width: fit-content;  
    position: static;
    width: 100%;
    right: 82px;
    left: auto;
    top: 120px;
    padding-top: 0px;
    z-index: 4;
    &.mobile-side {
      padding-top: 100px;
    }
  }
`;

export default Sidebar;
