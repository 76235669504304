import { useEffect, useState } from "react";
import { Loading, StyledSelect } from "base/styled";
import { any, bool, func, object } from "prop-types";
import styled from "styled-components";
import { fetchLocation } from "store/slices/location";
import { useDispatch, useSelector } from "react-redux";

const Load = styled(Loading)`
  width: 240px;
  height: 38px;
`;

export default function Location({ styles, selected, item, handlerValue, edit}) {
  const [type, setType] = useState();
  const [data, setData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (selected) {
    case 'full_contact_city':
      setType('business_cities');
      break;
    case 'full_contact_region':
      setType('business_regions');
      break;
    case 'full_contact_country':
      setType('business_countries');
      break;

    default:
      setType(selected);
      break;
    }
    /* eslint-disable */
  }, [selected]);
    /* eslint-enable */

  const { location, statusLocation } = useSelector((state) => state.locationReducer);

  useEffect(() => {
    if(type){
      dispatch(fetchLocation(type));
    }
  }, [dispatch, type]);
   
  useEffect(() => {
    if(location?.length > 0) {
      let uniqueValues = [];
      let duplicateValues = [];

      for (let i = 0; i < location?.length; i++) {
        if (uniqueValues.indexOf(location[i]) === -1) {
          uniqueValues.push(location[i]);
        } else {
          duplicateValues.push(location[i]);
        }
      }
      setData(location.map((option) => ({
        value: option,
        label: option
      })));
    }
  }, [location]);

  return (
    <>
      {(statusLocation != 'succeed' && !item.value) ? <Load /> : 
        <StyledSelect
          options={data}
          onChange={(e) => handlerValue(e, item)}
          styles={styles}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
          isMulti
          creatable
          // value={item.value.split(',').map(e =>  (
          //   {label: e,
          //     value: e}
          // )) }
          defaultValue={
            (edit && item.value)
              ? item.value.split(',').map(e =>  (
                {label: e,
                  value: e}
              ))
              : ''
          }
        />
      }
    </>
  );
}

Location.propTypes = {
  styles: object, 
  selected: any, 
  item: any, 
  handlerValue: func, 
  edit: bool
};