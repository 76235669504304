import network from './index';

export const getStats = ({ 
  ga_view_id = null,
  min_date = null,
  max_date = null
}) => {
  return network.get('/api/stats', {
    params: {
      ga_view_id: ga_view_id,
      min_date,
      max_date
    }
  });
};
