import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWebsites } from 'store/slices/websites';
import { fetchGAViewIds } from 'store/slices/gaViewId';
import { Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import {
  AccentButton,
  Modal,
  Overflow,
  TextAccentThin,
  TextBlack,
  TextBlackSmallThin,
  TextBlackThin,
  Block,
  Loader,
  SecondaryBtnHigh,
  TitlePage,
  OutlinedButton,
  FlexColumn,
  OverflowSolid
} from 'base/styled';
import FormEmail from 'pages/Websites/FormEmail';
import { any, number } from 'prop-types';
import Responsive from 'context/responsive';
import front from 'assets/images/fronL.png';
import ng from 'assets/images/ngL.png';
import img from 'assets/images/exclamation.png';
import Integration from 'pages/Websites/Integration';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

const Content = styled.div`
  padding: 40px 0;
  text-align: center;
  margin: 0 auto;
  width: 84%;
  .btn-wrap {
    margin: auto;
  }
  hr {
    border: none;
    background: #73ccfe;
    height: 1px;
    margin: 28px 0;
  }
  p {
    line-height: 16px;
  }
  .left {
    text-align: left;
    padding-left: 40px;
    line-height: 23px;
    margin-top: 5px
  }
  .background {
    svg {
      margin-top: 30px;
    }
  }
  h2 {
    max-width: 55%;
    text-align: center;
    margin: 30px auto;
  }
  .info {
    margin: 30px 0 0
  }
  @media (max-width: 768px) {
    width: 100%;
    h2 {
      max-width: 100%;
    }
    .info {
      .left {
        padding-left: 0;
      }
    }
  }
  &.hidden {
  height: 70vh;
  overflow: hidden;
}

`;

const FormCopy = styled.div`
  display: block;
  textarea {
    font-size: 12px;
    line-height: 14px;
    padding: 8px 15px;
    height: 96px;
    background: #f3f3f3;
    width: 100%;
    color: #85929b;
    border: 1px solid #73ccfe;
    border-radius: 13px;
    height: 130px
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin: 10px 0 0 20px;
    height: 37px;
  }
`;

const Resourses = styled.div`
  border-radius: 32px;
  background:  #FFF;
  padding: 45px 60px 60px;
  text-align: left;
  width: 100%;
  margin-top: 50px
  button {
    padding: 6px 14px;
    border-radius: 8px;
    height: auto;
    font-weight: 500;
  }
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
    li {
      padding:0;
      a {
        text-decoration: none;
        p {
          font-weight: 400;
        }
      }
    }
  }
`;

const Popup = styled(Block)`
  margin: 0 auto;
  padding: 0;
  height: 90%;
  width: 70%;
  overflow: hidden;
  .lmtWIHO_gkbTeeyuvoJC {
  min-width: auto!important;
 }
`;

const Imgs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  div.type{
    border-radius: 20px;
    width: 123px;
    height: 123px;
    margin: 10px;
    &.front {
      background: url(${front}) no-repeat 10% center
    }
    &.ng {
      background: url(${ng}) no-repeat 10% center
    }
  }
`;

const Type = styled.div`
  padding: 10px;
  text-align: center;
  cursor: pointer;
  p {
    font-weight: 400;
    text-align: center;
  }
  .sm {
    font-size: 10px;
    max-width: 100px;
    margin: 0 auto;
  }
`;

const Step = styled.div`
  position: relative;
  margin: auto;
  margin-top: 80px;
  padding-bottom: 100px;
  height: 100%;
  overflow-y: auto;
  &.edit {
    margin-top: 0;
    padding-bottom: 0;
  }
  p.steps {
    text-align: center;
    margin-bottom: 40px
  }
`;

const Note = styled.div`
  border-radius: 32px;
  width: 100%;
  margin: 0 auto;
  height: 46px;
  font-size: .875rem;
  background: #ffe1b5 url(${img}) no-repeat 4% 14px;
  padding: 15px 105px;
  position: relative;
  white-space: wrap;
  text-align: left;
  margin: 10px 0 8px;
  @media (max-width: 1200px) {
    width: 95%;
    heigth: auto;
    margin: 0 auto;
    background: #ffe1b5 url(${img}) no-repeat 4% 45%;
  }
  @media (max-width: 800px) {
    height: auto;
    padding: 12px 50px
  }
  .close {
    position: absolute;
    top: 15px;
    right: 32px;
    cursor: pointer;
  }
`;

export default function NoData({id, details}) {
  const { t: homeT } = useTranslation('home');
  const { subscription } = useSelector(
    (state) => state.accountReducer
  );
  const [formEmail, setFormEmail] = useState(false);
  const [copied, setCopied] = useState(false);
  const [popup, setPopup] = useState(false);
  const [script, setScript] = useState('');
  const [typeIntegrations, setTypeIntegrations] = useState();
  const [stepThree, setStepThree] = useState();
  const [note, setNote] = useState(false);

  const handlePopup = () => {
    setPopup(true);
  };

  const handlePopupOff = () => {
    setPopup(false);
  };

  const list = [
    {
      name: 'How to Add the Visitor Queue Tracking Code to your Website',
      link: 'https://resources.visitorqueue.com/hc/en-us/articles/360039585631-How-to-Add-the-Visitor-Queue-Tracking-Code-to-your-Website',
    },
    {
      name: 'Add the Visitor Queue Tracking Script Using Google Tag Manager',
      link: 'https://resources.visitorqueue.com/hc/en-us/articles/360039160012-Add-the-Visitor-Queue-Tracking-Script-Using-Google-Tag-Manager',
    },
    {
      name: 'Add the Visitor Queue Tracking Script to a Wordpress Website',
      link: 'https://resources.visitorqueue.com/hc/en-us/articles/360039568851-Add-the-Visitor-Queue-Tracking-Script-to-a-Wordpress-Website',
    },
    {
      name: 'Add the Visitor Queue Tracking Script to a Wix Website',
      link: 'https://resources.visitorqueue.com/hc/en-us/articles/360039569531-Add-the-Visitor-Queue-Tracking-Script-to-a-Wix-Website',
    },
    {
      name: '+ View more',
      link: 'https://resources.visitorqueue.com/hc/en-us',
    },
  ];

  const types = [
    {name: 'Tracking Script',
      details: 'Easiest to Setup',
      img: 'type front'},
    {name: 'Nginx',
      details: '+10% More Companies Tracked',
      img: 'type ng'},
  ];
 
  function copyToClipboard(script) {
    const ta = document.createElement('textarea');
    ta.innerText = script;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand('copy');
    ta.remove();
    setCopied(true);
  }

  function Next() {
    copyToClipboard(script);
  }

  function handleFormEmailOff() {
    setFormEmail(false);
  }

  function handleFormEmail() {
    setFormEmail(true);
  }
  
  const dispatch = useDispatch();
  const { page, websites, status } = useSelector(
    (state) => state.websitesReducer
  );

  const ctx = useContext(Responsive);

  useEffect(() => {
    if(subscription?.name == 'VQ Unlimited' && details?.trial_end_date == null) {
      setNote(true);
    }
  }, [subscription, details]);

  useEffect(() => {
    if(websites?.length < 1 && ctx.role == 'admin') {
      dispatch(fetchWebsites({ page, websites }));
    }
    if(websites){
      setScript(websites.find((el) => el.id === id)?.tracking_script);
      setCopied(false);
    }   
    /* eslint-disable */
  }, [dispatch, websites, id]);
  /* eslint-enable */

  function handleStepThree(type) {
    setTypeIntegrations(type);
    dispatch(fetchGAViewIds());
    setStepThree(true);
  }

  function handleStepThreeOff() {
    setTypeIntegrations();
    setStepThree();
  }

  return (
    <>
      {stepThree && (
        <Modal>
          <OverflowSolid ></OverflowSolid>
          <Step>
            <Integration 
              handleShowOff={handleStepThreeOff} 
              // websites={true} 
              header={true}
              website_id={id} 
              typeIntegrations={typeIntegrations}/>
          </Step>
        </Modal>
      )}
      <Content className={stepThree ? 'hidden' : ''}>
        <Row>
          <Col xs="12">
            {!ctx.impersonate && ctx.role == 'admin' && (
              <Block className='rounded column'>
                {(
                  <FlexColumn className='center'>
                    <TitlePage>{homeT('howIntegrate')}</TitlePage>
                    <Imgs>
                      {types.map((type, idx) => (
                        <Type key={idx} onClick={() => handleStepThree(type) }>
                          <div className={type.img}></div>
                          <TextBlack>{type.name}</TextBlack>
                          <TextBlackSmallThin className='sm'>{type.details}</TextBlackSmallThin>
                        </Type>
                      ))}            
                    </Imgs>
                    
                  </FlexColumn>
                )}
                {note && <Note>
                  {homeT('noteFreeTrial')}
                  <CloseIcon className='close' onClick={() => setNote(false)}/>
                </Note>}
              </Block>
            )}
            {!ctx.impersonate && ctx.role != 'admin' && (
              <TextBlack>{homeT('noData')}</TextBlack>
            )}
            {ctx.impersonate && ctx.impersonate?.role == 'admin' ? (
              <>
                <TextBlack>{homeT('noData')}</TextBlack>
                <TextBlackSmallThin>{homeT('haveInstalled')}</TextBlackSmallThin>
                <FormCopy>
                  {status === 'succeed' ? (
                    <>
                      <textarea value={script} />
                      <Buttons>
                        <AccentButton onClick={Next}>
                          {copied ? homeT('copied') : homeT('copy')}
                        </AccentButton>
                        <SecondaryBtnHigh onClick={handleFormEmail}>
                          {homeT('sendEmail')}
                        </SecondaryBtnHigh>
                      </Buttons>
                    </>
                  ) : (
                    <Loader />
                  )}
                  {formEmail && (
                    <Modal>
                      <Overflow onClick={handleFormEmailOff}></Overflow>
                      <FormEmail
                        handleFormEmailOff={handleFormEmailOff}
                        code={script}
                        id={id}
                      />
                    </Modal>
                  )}
                </FormCopy>
              </>
            ) : ctx.impersonate && ctx.impersonate?.role != 'admin' ? (
              <TextBlack>{homeT('noData')}</TextBlack>
            ) : null }
          </Col>
      
          {/* {show && (
            <>
              <Modal>
                <OverflowSolid onClick={handleShowOff}></OverflowSolid>
                <>
                  <AddWebsite 
                    handleOpenAddOff={handleShowOff} 
                  />
                </>
              </Modal>
            </>
          )} */}
        </Row>
      
        <Row>
          <Col xs="12">
            <Resourses>
              <TextBlack>{homeT('resourses')}</TextBlack>
              <ul>
                {list.map((item, idx) => (
                  <li key={idx}>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <TextAccentThin>{item.name}</TextAccentThin>
                    </a>
                  </li>
                ))}
              </ul>
              <TextBlack>{homeT('needHelp')}</TextBlack>
              <TextBlackThin>{homeT('schedule')}</TextBlackThin>
              <OutlinedButton onClick={handlePopup}>{homeT('scheduleCall')}</OutlinedButton>
            </Resourses>
          </Col>
        </Row>
        {popup && (
          <Modal>
            <Overflow onClick={handlePopupOff}></Overflow>
            <Popup>
              <iframe
                src="https://calendly.com/visitor-queue"
                width="100%"
                height="100%"
                frameBorder="0"
              ></iframe>
            </Popup>
          </Modal>
        )}
      </Content>
    </>
  );
}

NoData.propTypes = {
  id: number,
  details: any
};
