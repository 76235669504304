import styled from 'styled-components';
import img from 'assets/images/no_data.svg';

const NoData = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  background: url(${img}) no-repeat 5% 29% / 16px;
  padding: 30px 13px;
  text-align: center;
 
  p {
    font-size: 10px;
    font-weight: 500;
    color: #85929B;
  }
  @media (max-width: 768px) {
    
  }
`;
export default NoData;
