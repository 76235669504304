import { InlineLink, Block, List } from 'base/styled';
import styled from 'styled-components';
import { func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Partner } from 'assets/images/partners.svg';
import { ReactComponent as Video } from 'assets/images/video.svg';
import { ReactComponent as Logout } from 'assets/images/logout.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import network from 'base/network';

const UserMenu = styled(Block)`
display: block;
padding: 10px;
position: absolute;
bottom: -172px;
right: 32px;
width: max-content;
box-shadow: 0px 2px 12px rgba(0,0,0,0.13);
z-index: 99;
&:after {
  content: '';
  position: absolute;
  right: 85px;
  top: -6px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  clear: both;
}
ul {
    list-style: none;
    li:first-child {
        padding-top: 20px;
    }
} 
.close {
  display: none;
}
@media (max-width: 768px) {
  bottom: auto;
  top: 0;
  width: 100%;
  border-radius: 0;
  .close {
    display: block;
    position: absolute;
    top:10px;
    right: 10px;
  }
}
`;

const UserLink = styled(InlineLink)`
  font-size: .75rem;
  font-weight: 600;
  color: ${props => props.theme.colors.accentRed}; 
  letter-spacing: 0.05rem;
  margin-bottom: 10px;
`;

const UserMenuItem = styled.a`
  margin: 10px;
  margin-left: 10px;
  color: #000;
  font-size: .75rem;
  text-decoration: none;
  svg {
      margin-right: 7px;
      margin-bottom: -2px;
  }
`;

const Item = styled.li`
  padding: 7px 0;
`;

export default function UserDropdown({user, handleOpenUserOff}) {

  const { t: header } = useTranslation('header');

  const signOut = async () => {   
    let res = await network
      .delete('api/users/sessions/sign_out')
      .then((data) => {
        const url = process.env.REACT_APP_API_PATH;
        window.open(url, '_self');
        console.log(data, "DATA STORED");
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  function handleSignOut() {
    signOut();
  }
  return (
    <>
      <UserMenu onClick={handleOpenUserOff}>
        <UserLink className="user_name" to="/profile">
          {`${user.first_name} ${user.last_name}`}
          <div className='close' onClick={handleOpenUserOff} ><CloseIcon /></div>
        </UserLink>
        <List>
          <Item>
            <UserMenuItem href="https://www.visitorqueue.com/affiliate" target="_blank" rel="noreferrer">
              <Partner />
              {header('partnerProgram')}  
            </UserMenuItem>
          </Item>
          <Item>
            <UserMenuItem href="https://resources.visitorqueue.com/hc/en-us/articles/360016288011-Pre-Recorded-Demo" target="_blank" rel="noreferrer">
              <Video />
              {header('tutorial')}  
            </UserMenuItem>
          </Item>
          <Item onClick={handleSignOut}>
            {/* <UserMenuItem href={process.env.REACT_APP_API_PATH + 'users/sign_in'} rel="noreferrer"> */}
            <UserMenuItem>
              <Logout />
              {header('logout')} 
            </UserMenuItem>
          </Item>
        </List>
      </UserMenu>
    </>
  );
}

UserDropdown.propTypes = {
  user: object,
  handleOpenUserOff: func
};
  