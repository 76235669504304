import styled from "styled-components";

const TitlePage = styled.h1`
  font-size: 1.6rem;
  text-align: left;
  margin: 15px 0 30px;
  font-weight: 600;
  color: #000;

  @media (max-width: 768px) {
    margin: 30px 0 10px;
  }
`;

export default TitlePage;
