import { Block, Flex, TextBlackSmallThin, TitlePage } from "base/styled";
import { useTranslation } from "react-i18next";
import { ReactComponent as Step1 } from 'assets/images/step1.svg';
import { ReactComponent as Step2 } from 'assets/images/step3.svg';
import { ReactComponent as Step3 } from 'assets/images/step1.svg';
import { ReactComponent as Steps } from 'assets/images/steps.svg';
import styled from "styled-components";

const Main = styled(Block)``;

const Card = styled(Block)`
border-radius: 19px;
width: 180px
box-shadow: 4px 12px 32px 0px rgba(0, 0, 0, 0.06);
flex-direction: column;
padding: 20px;
margin: 10px 10px 10px 0;
text-align: center;
svg {
    height: 130px
}
`; 

const StepsImg = styled.div`
  text-align: center;
  padding-top: 20px;
`;

export default function TooltipContent() {

  const { t: websiteT } = useTranslation('website');

  return (
    <Main className="box">
      <TitlePage>
        {websiteT('howAdd')}
        <Flex>
          <Card>
            <Step1 />
            <TextBlackSmallThin>{websiteT('howAddStep1')}</TextBlackSmallThin>
          </Card>
          <Card>
            <Step2 />
            <TextBlackSmallThin>{websiteT('howAddStep2')}</TextBlackSmallThin>
          </Card>
          <Card>
            <Step3 />
            <TextBlackSmallThin>{websiteT('howAddStep3')}</TextBlackSmallThin>
          </Card>
        </Flex>
        <StepsImg>
          <Steps />
        </StepsImg>
      </TitlePage>
    </Main>
  );
}
