import network from './index';

export const getWebsites = ({page, query, per_page}) => {
  return network.get('/api/websites', {
    params: {
      q: query,
      page: page,
      per_page: per_page,
      sunsetted: false
    }
  });
};

export const getWebsitesSunsetted = ({page, query, per_page}) => {
  return network.get('/api/websites', {
    params: {
      q: query,
      page: page,
      per_page: per_page,
      sunsetted: true
    }
  });
};

export const getWebsitesDeleted = () => {
  return network.get(`/api/websites/deleted`);
};

export const putWebsites = (id, body) => {
  return network.put(`/api/websites/${id}`, body);
};

export const deleteWebsites = (id) => {
  return network.delete(`/api/websites/${id}`);
};

export const activateWebsites = (id) => {
  return network.put(`api/websites/${id}/recover`);
};

export const postWebsites = (body) => {
  return network.post('/api/websites', body);
};

