import React from 'react';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

function useDynamicMetaTags() {
  const location = useLocation();
  const path = location.pathname.substring(1) || "leads";

  React.useEffect(() => {
    const title = `${path.charAt(0).toUpperCase() + path.slice(1)} | Visitor Queue`;
    const description = path;

    const head = document.head;
    const existingTitle = head.querySelector('title');
    const existingMetaDescription = head.querySelector('meta[name="description"]');

    if (existingTitle) {
      existingTitle.textContent = title;
    } else {
      const newTitle = document.createElement('title');
      newTitle.textContent = title;
      head.insertBefore(newTitle, head.firstChild);
    }

    if (existingMetaDescription) {
      existingMetaDescription.content = description;
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = description;
      head.insertBefore(newMetaDescription, head.firstChild);
    }
  }, [path]);
}

function MetaTags() {
  useDynamicMetaTags();
  return null;
}

export default MetaTags;
