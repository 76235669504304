import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TextBlackSmallThin } from 'base/styled';
import { any, func } from 'prop-types';
import { ReactComponent as Close } from 'assets/images/close.svg';

const Content = styled.div`
  bottom: 10%;
  background-color: #ff464629;
  position: fixed;
  width: 87.5%;
  backdrop-filter: blur(3px);
  right: auto
  border-radius: 18px;
  padding: 10px 25px;
  p {
    max-width: 710px;
  }

  svg {
    position: absolute;
    right: 16px;
    top: 15px;
    cursor: pointer;
  }
  a {
    color: inherit;
    font-weight: 600;
  }
  @media (max-width:768px) {
    bottom: 10%;
    width: 87%;
    right: 6%;
    max-width: 93%;
  }
`;

export default function WasConnected({days, setWasConnected}) {
  const { t: homeT } = useTranslation('home');

  return (
    <Content>
      <TextBlackSmallThin>{homeT('was_connected')}
        {days?.was_connected_days_ago}
        {homeT('days_ago')}
        <a href={`/websites?q=${days?.website_id}`}>{homeT('was_connected_link')}</a>
      </TextBlackSmallThin>
      <Close onClick={()=> setWasConnected(false)}/>
    </Content>
  );
}

WasConnected.propTypes = {
  days: any,
  setWasConnected: func
};
