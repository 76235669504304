import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeAsOptions } from 'base/utils';
import { func, number, string } from 'prop-types';
import network from 'base/network';
import { fetchAccount } from 'store/slices/sugar';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import {
  AccentButton,
  Block,
  Check,
  OutlinedButton,
  StyledSelect,
  TextBlackBig,
  TextGreyBold,
  Flex,
  TextBlackSmall,
  TextLabel,
} from 'base/styled';
import { ReactComponent as Close } from 'assets/images/close.svg';
import { ReactComponent as Icon } from 'assets/images/saved.svg';
import ReactDatePicker from 'react-datepicker';

const Popup = styled(Block)`
  margin: 0 auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    margin: 0 auto;
  }
  button {
    height: 37px;
    margin-right: 5px;
  }
  .center {
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  form {
    width: 100%;
    text-align: left;
    position: relative;
    .center {
      text-align: center;
      font-size: .85rem;
      line-height: 1.3rem;
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 0;
    }
    .date {
      border: ${props => props.theme.borders.textInput};
      font-family: 'Montserrat', sans-serif;
      font-size: .75rem;
      border-radius: 8px;
      height: 38px;
      width: 100%;
      padding: 0 10px;
      color: ${props => props.theme.colors.lightGrey};
      transition: all 0.2s;
      position: relative;
      &:focus-visible {
        outline: none;
      }
      &::-webkit-input-placeholder  {
        color: ${props => props.theme.colors.lightGrey};
      }
     
    }
    p {
      padding: 20px 0;
    }
    .react-datepicker-popper {
      z-index: 90;
      left: -80%!important;
    }
    .react-datepicker {
      font-family: ${(props) => props.theme.fonts.main};
      font-size: 14px;
      border: ${(props) => props.theme.borders.textInput};
      border-radius: 8px;
      .react-datepicker__navigation {
        top: 34px;
      }
      .react-datepicker__navigation--previous {
        right: 54px;
        left: auto;
      }
      .react-datepicker__day--keyboard-selected:hover,
      .react-datepicker__month-text--keyboard-selected:hover,
      .react-datepicker__quarter-text--keyboard-selected:hover,
      .react-datepicker__year-text--keyboard-selected:hover,
      .react-datepicker__day--selected:hover,
      .react-datepicker__day--in-selecting-range:hover,
      .react-datepicker__day--in-range:hover,
      .react-datepicker__month-text--selected:hover,
      .react-datepicker__month-text--in-selecting-range:hover,
      .react-datepicker__month-text--in-range:hover,
      .react-datepicker__quarter-text--selected:hover,
      .react-datepicker__quarter-text--in-selecting-range:hover,
      .react-datepicker__quarter-text--in-range:hover,
      .react-datepicker__year-text--selected:hover,
      .react-datepicker__year-text--in-selecting-range:hover,
      .react-datepicker__year-text--in-range:hover,
      react-datepicker__day--selected,
      .react-datepicker__day--today,
      .react-datepicker__day--keyboard-selected {
        background-color: #f966525e;
        border-radius: 50%;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background-color: ${(props) => props.theme.colors.accentRed};
        border-radius: 50%;
      }
      .react-datepicker__navigation--next {
        right: 22px;
      }

      .react-datepicker__navigation-icon::before {
        width: 6px;
        height: 6px;
        border-color: #010101;
      }

      .react-datepicker__triangle {
        margin: 0;
        z-index: -9;
      }
      .react-datepicker__current-month {
        padding: 0 0 20px 5px;
      }
      .react-datepicker__header {
        text-align: left;
        background: inherit;
        padding: 35px 40px 0;
        font-size: inherit;
        border-bottom: 0;
      }
      .react-datepicker__day-names {
        font-weight: 600;
      }
      .react-datepicker__month {
        margin-bottom: 20px;
      }
  }
`;

const Buttons = styled.div`
  text-align: right;
  margin-top: 20px;
`;

const Relative = styled.div`
  position: relative;
  padding: 20px 0;
  span {
    width: 18px;
    height: 19px;
  }
`;

const Select = styled(StyledSelect)`
  margin-right: 0;
`;

const Title = styled(Flex)`
  p {
    padding: 8px 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  svg {
    margin-top: 18px;
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

export default function Opportunity({handleFormOff, id, leadName}) {
  const { t: homeT } = useTranslation('home');
  const [name, setName] = useState(leadName);
  const [accountName, setAccount] = useState();
  const [like, setLike] = useState('');
  const [best, setBest] = useState('');
  const [worst, setWorst] = useState('');
  const [chkbox, setChkbox] = useState(true);
  const [result, setResult] = useState(false);
  const [startDate, setStartDate] = useState(+new Date() + 864000000);
  const dispatch = useDispatch();

  const { account, status } = useSelector((state) => state.sugarReducer);
  
  useEffect(() => {
    dispatch(fetchAccount({ account, status }));
    setAccount(options[0]);
    /* eslint-disable */
  }, [dispatch]);
   /* eslint-enable */

  const options = makeAsOptions(account, 'id', 'name');

  function handleName(e) {
    setName(e.target.value);
  }

  function handleLike(e) {
    setLike(e.target.value);
  }

  function handleBest(e) {
    setBest(e.target.value);
  }

  function handleWorst(e) {
    setWorst(e.target.value);
  }
  
  const postOpportunity = async () => {
    //const date = startDate.toLocaleString().split('.').join('/').substring(0, 10)
    const d = startDate;
    const curr_date = d.getDate();
    let curr_month = d.getMonth();
    curr_month++;
    const curr_year = d.getFullYear();
  
    const date = curr_month + "/" + curr_date + "/" + curr_year;
 
    let res = await network
      .post(`/api/sugarcrm/opportunity`, { 
        lead_id: id, 
        name: name,
        account: accountName.value,
        revenue: chkbox,
        likely: like,
        best_case: best,
        worst_case: worst,
        close_date: date
      })
      .then(({ data }) => {
        console.log(data.message);
        setResult(data.message);
        return data;
      })
      .catch((error) => console.log(error));
    console.log(res);
  };

  function handleSubmit(e) {
    e.preventDefault();
    postOpportunity();
  }

  function handleAccount(item) {
    setAccount(item);
  }
  
  return (
    <Popup>
      {result ? (
        <>
          <Icon />
          <TextGreyBold className="center">{result}</TextGreyBold>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Title>
            <TextBlackBig>
              {homeT('createOpportunitySugar')}
            </TextBlackBig>
            <Close onClick={handleFormOff}/>
          </Title>
          <FormTextField
            type="text"
            label="Opportunity"
            value={name}
            onChange={handleName}
            required
          />
          <TextLabel>
            {homeT('closeDate')}
          </TextLabel>
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className='date'
          />
          <Relative>
            <Check
              defaultChecked={chkbox}
              onChange={() => setChkbox(!chkbox)}
            />
            <span></span>
            <label>{homeT('opportunityChkbox')}</label>
          </Relative>
          <Select
            value={accountName}
            //defaultValue={options[0]}
            options={options}
            onChange={handleAccount}
            styles={customStyles}
            menuPlacement="bottom"
          />
          <TextBlackSmall>Amounts:</TextBlackSmall>
          <FormTextField
            type="text"
            label="Likely"
            value={like}
            onChange={handleLike}
            required
          />
          <FormTextField
            type="text"
            label="Best case"
            value={best}
            onChange={handleBest}
          />
          <FormTextField
            type="test"
            label="Worst Case"
            value={worst}
            onChange={handleWorst}
          />
          <Buttons>
            <OutlinedButton onClick={handleFormOff}>
              {homeT('cancel')}
            </OutlinedButton>
            <AccentButton type="submit">
              {homeT('createOpportunity')}
            </AccentButton>
          </Buttons>
        </form>
      )}
    </Popup>
  );
}

Opportunity.propTypes = {
  handleFormOff: func,
  id: number,
  leadName: string
};
