import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { any, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FilledLink, TextAccentExtraSmall,
  TextBlackExtraSmall, TextLightExtraSmall, Block, GhostButton, Modal, Result } from 'base/styled';
import { ReactComponent as Adress } from 'assets/images/adress.svg';
import { ReactComponent as Name } from 'assets/images/company_name.svg';
import { ReactComponent as Employe } from 'assets/images/employe.svg';
import { ReactComponent as Found } from 'assets/images/found.svg';
import { ReactComponent as Website } from 'assets/images/website.svg';
import { ReactComponent as SuggestIcon } from 'assets/images/suggest.svg';
import { ReactComponent as Notes } from 'assets/images/notes.svg';
import { ReactComponent as Industries } from 'assets/images/industry.svg';
import Responsive from 'context/responsive';
import Suggest from '../../Manage/Suggest';

const FlexWrapper = styled.div`
  display: flex;
  padding: 18px 5px;
  svg {
    margin: 2px 10px 0 0;
    width: 20px;
  }
  ul {
      list-style: none;
      padding: 0 10px;
      margin: 0;
      .block {
        padding: 10px 0;
      }
      li {
          display: flex;
          padding: 0px 0;
          a {
            text-decoration:none;
          }
          p {
            margin: 3px 0 0;
            }
            &:nth-child(3) {
                svg {
                    width: 21px
                }
            }
        }
        &:first-child {
          width: 100%;
        }
        &:last-child {
          width: 100%;
          li {
           
          }
      }
    }  
`;

const Info = styled.div`
    display: flex;
    padding: 0 15px 10px;
    p {
    margin: 3px 0 0;
  }
`;

const Icon = styled.div`
  width: 28px;
  padding: 0 20px 0 0
`;

const Wrap = styled(Block)`
  margin: 30px;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  text-align: center;
  a {
    font-size: 14px;
    width: 140px;
    margin: 16px auto 0;
  }
`;

const Button = styled(GhostButton)`
  border: none;
  display: flex;
  justify-content: end;
  margin: 0 10px 10px 0;
  p {
    margin: 3px 0;
    font-size: 10px
  }
`;

const ModalOver = styled(Modal)`
  z-index: 35;
  background: rgba(0, 0, 0, 0.43);
  justify-content: center;
`;

function renderList(item, idx) {
  return (
    <li key={idx} className={item.icon ? 'block' : 'd-none'}>
      <Icon>
        {item.icon}
      </Icon>
      <div>
        <TextLightExtraSmall>{item.title}</TextLightExtraSmall>
        <TextBlackExtraSmall>{item.value}</TextBlackExtraSmall>
        <a target="_blank" rel="noreferrer" href={item.url}>
          <TextAccentExtraSmall>{item.accent}</TextAccentExtraSmall>
          {item.arr && item.arr.length > 0 &&
            item.arr.map((lead, ind) => (
              <TextBlackExtraSmall key={ind}>
                {lead.country} 
                {lead.city ? ', ' + lead.city : null}
                {lead.region ? ', ' + lead.region : null} {lead.postalCode && ', ' + lead.postalCode}
              </TextBlackExtraSmall>
            ))}
        </a>
      </div>
    </li> 
  );
}

export default function Company({companyInfo, leadToShow}) {
  const { t: homeT } = useTranslation('home');
 
  const arrLeft = [
    {
      icon: <Name />,
      title: 'Company name',
      value: companyInfo?.company_name
    },
    {
      icon: companyInfo?.company_info.website && <Website />,
      title: 'Website',
      accent: companyInfo?.company_info.website,
      url: companyInfo?.company_info.website
    },
    {
      icon: companyInfo?.company_info?.addresses.length > 0 && <Adress />,
      title: companyInfo?.company_info?.addresses.length > 0 && 'Headquarter addresses',
      arr: companyInfo?.company_info?.addresses
    },
  ];

  const arrRight = [
    {
      icon: companyInfo.company_info && companyInfo.company_info.employees && <Employe />,
      title: companyInfo.company_info && companyInfo.company_info.employees && 'Approximate employees',
      value: companyInfo.company_info && companyInfo.company_info.employees
    },
    {
      icon: companyInfo.company_info && companyInfo.company_info.founded_year && <Found />,
      title: companyInfo.company_info && companyInfo.company_info.founded_year && 'Founded',
      value: companyInfo.company_info && companyInfo.company_info.founded_year
    },
    {
      icon: companyInfo.company_info && companyInfo.company_info.naics_industries.length > 0 && <Industries />,
      title: companyInfo.company_info && companyInfo.company_info.naics_industries.length > 0 && 'NAICS Industry',
      value: companyInfo.company_info && companyInfo.company_info.naics_industries,
    },
    {
      icon: companyInfo.company_info && companyInfo.company_info.gic_industries.length > 0 && <Industries />,
      title: companyInfo.company_info && companyInfo.company_info.gic_industries.length > 0 && 'GIC Industry',
      value: companyInfo.company_info && companyInfo.company_info.gic_industries,
    },
  ];

  const ctx = useContext(Responsive);
  const [form, setForm] = useState();
  const [success, setSuccess] = useState(false);

  function handleForm() {
    setForm(true);
  }
  function handleFormOff() {
    setForm(false);
  }

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
    <>
      {ctx.isLiteFree ? (
        <Wrap>
          {homeT('upgradeCompany')}
          <FilledLink href='/company'>{homeT('upgradeNow')}</FilledLink>
        </Wrap>
      ) : (
        <>
          <FlexWrapper>
            <ul>{arrLeft.map(renderList)}</ul>
            <ul>{arrRight.map(renderList)}</ul>
          </FlexWrapper>
          {companyInfo.company_info.description && (
            <Info>
              <Icon>
                <Notes />
              </Icon>
              <div>
                <TextLightExtraSmall>Overview</TextLightExtraSmall>
                <TextBlackExtraSmall>
                  {companyInfo.company_info.description}
                </TextBlackExtraSmall>
              </div>
            </Info>
          )}
          {!form && (
            <Button className='btn' onClick={handleForm}>
              <SuggestIcon />
              <p>{homeT('suggestEdit')}</p>
            </Button>
          )}
          {form && (
            <ModalOver>
              <Block>
                <Suggest
                  handleShowModalOff={handleFormOff}
                  id={leadToShow.id}
                  setSuccess={setSuccess}
                  suggest={'info'}
                />
              </Block> 
            </ModalOver>
          )}
        </>
      )}
      {success && <Result>{homeT('sent')}</Result>}
    </>
  );
}

Company.propTypes = {
  leadName: string,
  leadLocation: string,
  companyInfo: object,
  leadToShow: any
};

