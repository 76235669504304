import { useTranslation } from 'react-i18next';
import { bool, func, string, array} from 'prop-types';
import { ReactComponent as Filters } from 'assets/images/filters.svg';
import FilterList from 'base/components/Leads/Filters/FilterList';
import { TextBlackThin } from 'base/styled';
import styled from 'styled-components';

const Text = styled(TextBlackThin)`
&.notification {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: -7px;
    top: -12px;
    width: 12px;
    height: 12px;
    background: #FF8947;
    border-radius: 50%;
    @media (max-width: 768px) {
      top: 0px
    }
  }
}
`;

export default function Filter({setOpen, open, filter, setFilter, 
  contactDisabled, setAddFilter, addFilter, gic, naics, indicator}) {
  const { t: homeT } = useTranslation('home');

  function handleFilter() {
    setFilter(!filter);
    setOpen(!open);
  }

  return (
    <div className="with-modal">
      <a onClick={handleFilter}>
        <Filters />
        <Text className={indicator ? 'notification' : ''}> {homeT('filters')} </Text>
      </a>
      <div className={filter ? 'filtered' : 'd-none'}>
        <FilterList 
          handleFilter={handleFilter} 
          contactDisabled={contactDisabled} 
          setAddFilter={setAddFilter} 
          addFilter={addFilter}
          gic={gic}
          naics={naics}
        />
      </div>
    </div>
  );
}

Filter.propTypes = {
  setOpen: func, 
  open: bool.isRequired,
  filter: bool,
  setFilter: func,
  contactDisabled: string,
  setAddFilter: func,
  addFilter: bool,
  gic: array,
  naics: array,
  indicator: bool
};