import styled from 'styled-components';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  &.center {
    margin: 0 auto;
  }
`;

export default FlexColumn;
