import styled from 'styled-components';

const TextLabel = styled.label`
  margin-bottom: 6px;
  color: ${props => props.theme.colors.lightGrey};
  text-align: left;
  font-size: .75rem;
  line-height: 23px;
  position: relative;
  display: flex
`;

export default TextLabel;
