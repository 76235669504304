import network from './index';

export const getAllCustom = ({page, query, per_page}) => {
  return network.get('/api/notifications/custom', {
    params: {
      q: query,
      page: page,
      per_page: per_page
    }
  });
};

export const getCustomById = (id) => {
  return network.get(`/api/notifications/custom/${id}`);
};

export const getAllBenchmark = ({page, query, per_page}) => {
  return network.get('/api/notifications/benchmark', {
    params: {
      q: query,
      page: page,
      per_page: per_page
    }
  });
};

export const getAllSummary = ({page, query, per_page}) => {
  return network.get('/api/notifications/summary', {
    params: {
      q: query,
      page: page,
      per_page: per_page
    }
  });
};

export const getSummaryById = (id) => {
  return network.get(`/api/notifications/summary/${id}`);
};

export const putCustomById = (id) => {
  return network.put(`/api/notifications/custom/${id}`);
};

export const putSummaryById = (id) => {
  return network.put(`/api/notifications/summary/${id}`);
};

export const putBenchmarkById = (id) => {
  return network.put(`/api/notifications/benchmark/${id}`);
};
