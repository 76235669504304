import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeAsOptions } from 'base/utils';
import { func, number, string } from 'prop-types';
import network from 'base/network';
import { fetchPipeline, fetchOwner } from 'store/slices/hubspot';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import {
  AccentButton,
  Block,
  StyledSelect,
  TextBlackBig,
  TextGreyBold,
  Flex,
  TextBlackSmall,
  TextLabel,
  TextLightSmall,
  TextAccentSmall,
  SecondaryBtnHigh,
} from 'base/styled';
import { ReactComponent as Close } from 'assets/images/close.svg';
import { ReactComponent as Icon } from 'assets/images/saved.svg';
import ReactDatePicker from 'react-datepicker';

const Popup = styled(Block)`
  margin: 0 auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translate(0, 16%);
  svg {
    margin: 0 auto;
  }
  button {
    height: 37px;
    margin-right: 5px;
  }
  .center {
    margin: 0 auto;
    padding-top: 20px;
  }
  @media (max-width: 480px) {
    width: 96%;
    .success {
      margin: 0 auto;
    }
  }
  form {
    width: 100%;
    text-align: left;
    position: relative;
    .center {
      text-align: center;
      font-size: .85rem;
      line-height: 1.3rem;
      width: 80%;
      margin: 0 auto;
      padding: 20px 0 0;
    }
    .date {
      border: ${props => props.theme.borders.textInput};
      font-family: 'Montserrat', sans-serif;
      font-size: .75rem;
      border-radius: 8px;
      height: 38px;
      width: 100%;
      padding: 0 10px;
      color: ${props => props.theme.colors.lightGrey};
      transition: all 0.2s;
      position: relative;
      &:focus-visible {
        outline: none;
      }
      &::-webkit-input-placeholder  {
        color: ${props => props.theme.colors.lightGrey};
      }
     
    }
    p {
      padding: 0px 0;
    }
    .react-datepicker-popper {
      z-index: 90;
      left: -80%!important;
    }
    .react-datepicker {
      font-family: ${(props) => props.theme.fonts.main};
      font-size: 14px;
      border: ${(props) => props.theme.borders.textInput};
      border-radius: 8px;
      .react-datepicker__navigation {
        top: 34px;
      }
      .react-datepicker__navigation--previous {
        right: 54px;
        left: auto;
      }
      .react-datepicker__day--keyboard-selected:hover,
      .react-datepicker__month-text--keyboard-selected:hover,
      .react-datepicker__quarter-text--keyboard-selected:hover,
      .react-datepicker__year-text--keyboard-selected:hover,
      .react-datepicker__day--selected:hover,
      .react-datepicker__day--in-selecting-range:hover,
      .react-datepicker__day--in-range:hover,
      .react-datepicker__month-text--selected:hover,
      .react-datepicker__month-text--in-selecting-range:hover,
      .react-datepicker__month-text--in-range:hover,
      .react-datepicker__quarter-text--selected:hover,
      .react-datepicker__quarter-text--in-selecting-range:hover,
      .react-datepicker__quarter-text--in-range:hover,
      .react-datepicker__year-text--selected:hover,
      .react-datepicker__year-text--in-selecting-range:hover,
      .react-datepicker__year-text--in-range:hover,
      react-datepicker__day--selected,
      .react-datepicker__day--today,
      .react-datepicker__day--keyboard-selected {
        background-color: #f966525e;
        border-radius: 50%;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background-color: ${(props) => props.theme.colors.accentRed};
        border-radius: 50%;
      }
      .react-datepicker__navigation--next {
        right: 22px;
      }

      .react-datepicker__navigation-icon::before {
        width: 6px;
        height: 6px;
        border-color: #010101;
      }

      .react-datepicker__triangle {
        margin: 0;
        z-index: -9;
      }
      .react-datepicker__current-month {
        padding: 0 0 20px 5px;
      }
      .react-datepicker__header {
        text-align: left;
        background: inherit;
        padding: 35px 40px 0;
        font-size: inherit;
        border-bottom: 0;
      }
      .react-datepicker__day-names {
        font-weight: 600;
      }
      .react-datepicker__month {
        margin-bottom: 20px;
      }
  }
  .bottom {
    margin-bottom: 35px;
  }
`;

const Buttons = styled.div`
  text-align: right;
  margin-top: 20px;
`;

const Select = styled(StyledSelect)`
  margin-right: 0;
`;

const Title = styled(Flex)`
  justify-content: space-between;
  p {
    padding: 8px 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  svg {
    margin: 0;
    margin-top: 10px;
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '14px',
    background: state.isSelected ? '#FFF9F9' : 'inherit',
    padding: 10,
  }),
};

export default function Deal({handleFormOff, id, leadName}) {
  const { t: homeT } = useTranslation('home');
  const [name, setName] = useState(leadName);
  const [owner, setOwner] = useState('');
  const [amount, setAmount] = useState('');
  const [result, setResult] = useState(false);
  const [pipe, setPipe] = useState(null);
  const [choosed, setChoosed] = useState(false);
  const [company, setCompany] = useState(null);
  const [startDate, setStartDate] = useState();
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const { owners, pipeline, status } = useSelector((state) => state.hubspotReducer);
  
  useEffect(() => {
    dispatch(fetchPipeline({pipeline, status})); 
    dispatch(fetchOwner({ owners, status }));
    /* eslint-disable */
  }, [dispatch]);
   /* eslint-enable */
  const optionsOwner = makeAsOptions(owners, 'id', 'email');
  const [optionsStage, setOptionsStage] = useState([]);
  
  const [optionsCompany, setOptionsCompany] = useState([]);
  const [optionsPipeline, setOptionsPipeline] = useState([]);
  const [stage, setStage] = useState(null);
  const [companyValue, setCompanyValue] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleCompanyChange = (newInputValue) => {
    setCompanyValue(newInputValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      getCompanies(newInputValue);
    }, 400); 

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    if (pipeline) { 
      setOptionsPipeline(makeAsOptions( pipeline, 'pipeline_id', 'label'));
      setOptionsStage( makeAsOptions( choosed.stages, 'stageId', 'label'));
    }
  }, [choosed, pipeline]);

  const options = [
    { name: 'newbusiness', label: 'New Business' },
    { name: 'existingbusiness', label: 'Existing Business' }
  ];
  const [type, setType] = useState(options[0]);

  const getCompanies = async (inputValue) => {
    await network
      .get(`/api/hubspot/search`, { params: {id: id, object: 'Company', query: inputValue} })
      .then(({ data }) => {
        setOptionsCompany(makeAsOptions( data.results, 'id', 'name'));
        return data;
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCompanies();
    /* eslint-disable */
  }, []);
    /* eslint-enable */

  function handleName(e) {
    setName(e.target.value);
  }

  function handleType(e) {
    setType(e);
  }

  function handleStage(e) {
    setStage(e);
  }

  function handleOwner(e) {
    setOwner(e);
  }

  function handleAmount(e) {
    setAmount(e.target.value);
  }

  function handlePipe(e) {
    setPipe(e);
    setChoosed(pipeline.find(el => el.label === e.label));
  }

  function handleCompany(e) {
    setCompany(e);
  }
  
  const postDeal = async () => {
    //const date = startDate.toLocaleString().split('.').join('/').substring(0, 10)
    const d = startDate;

    const curr_date = d.getDate();
    let curr_month = d.getMonth();
    curr_month++;
    const curr_year = d.getFullYear();
  
    const date = curr_month + "/" + curr_date + "/" + curr_year;
 
    await network
      .post(`/api/hubspot/deal`, { 
        deal_name: name,
        pipeline_id: pipe.value,
        stage_id: stage.value,
        company_id: company?.value,
        user_id: owner?.value,
        amount: amount || '',
        deal_type: type.name,
        close_date: date
      })
      .then(({ data }) => {
        setResult(data.message || data.error);
        if(data.error) {
          setErrorMessage(true);
        }
        return data;
      })
      .catch((error) => {
        setResult(res.error);
        console.log(error);
      });
  };

  function handleSubmit(e) {
    e.preventDefault();
     
    if(pipe == null) {
      setError('choose the pipeline');     
    } else if (stage == null){
      setError('choose the deal stage');
    } else if (startDate == null){
      setError('choose the date');
    } else if (company == null){
      setError('choose the assosiation');
    } else {
      postDeal();
    }
  }
  
  return (
    <Popup>
      {result ? (
        <>
          {errorMessage != true && <Icon />}
          <TextGreyBold className="center">{result}</TextGreyBold>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Title>
            <TextBlackBig>
              {homeT('CreateDealHubspot')}
            </TextBlackBig>
            <Close onClick={handleFormOff}/>
          </Title>
          <FormTextField
            type="text"
            label="Opportunity"
            value={name}
            onChange={handleName}
            required
          />
          <TextLightSmall>{homeT('pipeline')}</TextLightSmall>  
          <Select
            value={pipe}
            //defaultValue={options[0]}
            options={optionsPipeline}
            onChange={handlePipe}
            // onChange={(e) => console.log(e.target)}
            styles={customStyles}
            menuPlacement="bottom"
            className='bottom'
            required={true}
          />
          <TextLightSmall>{homeT('dealType')}</TextLightSmall>     
          <Select
            value={type}
            options={options}
            onChange={handleType}
            styles={customStyles}
            menuPlacement="bottom"
          />
          <TextLightSmall>{homeT('dealStage')}</TextLightSmall>     
          <Select
            value={stage}
            options={optionsStage}
            onChange={handleStage}
            styles={customStyles}
            menuPlacement="bottom"
          />
          <TextLightSmall>{homeT('dealOwner')}</TextLightSmall>     
          <Select
            value={owner}
            options={optionsOwner}
            onChange={handleOwner}
            styles={customStyles}
            menuPlacement="bottom"
            className='bottom'
          />
          <TextLabel>
            {homeT('closeDate')}
          </TextLabel>
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="mm/dd/yyyy"
            className='date'
          />
          <FormTextField
            type="number"
            label={homeT('amount')}
            value={amount}
            onChange={handleAmount}
          />
         
          <TextBlackSmall>{homeT('assosiation')}</TextBlackSmall>
        
          <Select
            value={company}
            options={optionsCompany}
            onChange={handleCompany}
            onInputChange={handleCompanyChange}
            inputValue={companyValue}
            styles={customStyles}
            menuPlacement="bottom"
          />
          <Buttons>
            <TextAccentSmall>{error}</TextAccentSmall>
            <SecondaryBtnHigh onClick={handleFormOff}>
              {homeT('cancel')}
            </SecondaryBtnHigh>
            <AccentButton type="submit">
              {homeT('createDeal')}
            </AccentButton>
          </Buttons>
        </form>
      )}
    </Popup>
  );
}

Deal.propTypes = {
  handleFormOff: func,
  id: number,
  leadName: string
};
