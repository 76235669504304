import network from './index';

export const getAllPersonalizations = ({ 
  q = null,
  conversion_campaign_id = null,
  min_date = null,
  max_date = null,
  page = 1,
  per_page = null,
  ...rest
}) => {
  return network.get('/api/personalization_campaigns', {
    params: {
      q,
      conversion_campaign_id: conversion_campaign_id,
      min_date,
      max_date,
      page,
      per_page,
      ...rest
    }
  });
};

export const getStatistic = ({ 
  google_analytics_view_id = null,
  min_date = null,
  max_date = null,
  page = 1,
  per_page = null,
  ...rest
}) => {
  return network.get('/api/personalization_campaigns/statistic_summary', {
    params: {
      google_analytics_view_id: google_analytics_view_id,
      min_date,
      max_date,
      page,
      per_page,
      ...rest
    }
  });
};

export const getPersonalizationById = (id) => {
  return network.get(`/api/personalization_campaigns/${id}`);
};

export const postPersonalizations = (body) => {
  return network.post('/api/personalization_campaigns', body);
};

export const putPersonalizationStatus = (id, status) => {
  return network.put(`/api/personalization_campaigns/${id}/set_status`, status);
};

export const putPersonalizations = (id, body) => {
  return network.put(`/api/personalization_campaigns/${id}`, body);
};

export const deletePersonalizations = (id) => {
  return network.delete(`/api/personalization_campaigns/${id}`);
};

