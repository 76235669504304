import styled from 'styled-components';

const Overflow = styled.div`
width: 100%;
height: 100%;
background: #00000026;
position: absolute;
`;

export default Overflow;
