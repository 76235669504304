import styled from "styled-components";

const Button = styled.button`
  font-family: Montserrat;
  font-weight: 500;
  font-weight: 600;
  font-size: 13px;
  color: #333;
  padding: 10px 5px;
  margin: 0 5px;
  height: 33px;
  min-width: 33px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background-color: #fff;
  &.disabled {
    color: #ccc;
    cursor: not-allowed;
    pointer-events: none
  }
`;

const Wrapper = styled.div`
  margin: 40px auto;
`;

export default function Pagination(total_pages, pageNumber, handlePageNext, handlePagePrev, setPageNumber) {
    
  const getPageNumbers = () => {
    const pages = [];
    const siblingCount = 1; 
    const totalPageNumbers = siblingCount * 2 + 1;

    if (total_pages <= totalPageNumbers) {
      for (let i = 1; i <= total_pages; i++) {
        pages.push(i);
      }
    } else {
      const leftSiblingIndex = Math.max(pageNumber - siblingCount, 1);
      const rightSiblingIndex = Math.min(pageNumber + siblingCount, total_pages);

      const shouldShowLeftEllipsis = leftSiblingIndex > 2;
      const shouldShowRightEllipsis = rightSiblingIndex < total_pages - 2;

      if (!shouldShowLeftEllipsis) {
        for (let i = 1; i < 2 + siblingCount * 2; i++) {
          pages.push(i);
        }
        if (total_pages > rightSiblingIndex + 1) {
          pages.push('...');
        }
        pages.push(total_pages);
      } else if (!shouldShowRightEllipsis) {
        pages.push(1, '...');
        for (let i = total_pages - (1 + siblingCount * 2); i <= total_pages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1, '...');
        for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
          pages.push(i);
        }
        pages.push('...', total_pages);
      }
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return  (
    <Wrapper>
      { total_pages ? (
        <>
          {<Button className={pageNumber > 1 ? '' : 'disabled'} onClick={ handlePagePrev }>Prev</Button>} 
          {pageNumbers.map((number, index) =>
            <Button
              key={index}
              onClick={() => setPageNumber(number)}
              style={{ backgroundColor: number === pageNumber ? '#f94a32' : 'white',
                color: number === pageNumber ? 'white' : '#333' }}
            >
              {number}
            </Button>
            
          )}
          <Button className={pageNumber < total_pages ? '' : 'disabled'} onClick={ handlePageNext }>Next</Button>
        </>) : null }
    </Wrapper>);
}